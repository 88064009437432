import React, { useState } from 'react';
import { Modal, Row, Col, Form, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { post_prospect_product } from '../../apis';
import SelectProduct from '../../Selects/Product';
import { mapErrorToFields } from '../../Functions';

interface Props {
    visible: boolean;
    prospectId: string;
    onClose: (success: boolean) => void;
}

const NewProspectProduct = ({ visible, prospectId, onClose }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        setLoading(true);
        const clientProductValues = {
            client_id: prospectId,
            products: JSON.stringify([
                {
                    product_id: values.product_id,
                },
            ]),
        };
        createProduct(clientProductValues);
    };

    const createProduct = (values) => {
        authenticatedRequest()
            .post(post_prospect_product, values)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const cancel = () => {
        form.resetFields();
        onClose(false);
    };

    return (
        <Modal
            visible={visible}
            title="New Product"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FloatingLabel label="Product" form={form} formItemName="product_id">
                            <SelectProduct
                                name="product_id"
                                isFormList={false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a product',
                                    },
                                ]}
                            />
                        </FloatingLabel>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => cancel()} />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Save Product" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewProspectProduct;
