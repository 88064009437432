import React from 'react';
import { Row, Col } from 'antd';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import ChangePassword from './components/ChangePassword';
import UserDetails from './components/UserDetails';

const Profile = () => {
    return (
        <AuthenticatedLayout title="Profile">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <UserDetails />
                </Col>

                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <ChangePassword />
                </Col>
            </Row>
        </AuthenticatedLayout>
    );
};

export default Profile;
