import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SuccessModal from '../../../components/modals/success';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { authenticatedRequest } from '../../../api';
import { get_single_role, post_role, update_role } from '../../../components/apis';
import SelectPermission from '../../../components/Selects/Permission';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { mapErrorToFields } from '../../../components/Functions';

const CreateRole = () => {
    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.ROLES);
    };

    const onFinish = (values) => {
        setLoading(true);
        if (id) {
            updateRole(values);
        } else {
            createRole(values);
        }
    };

    const createRole = (values) => {
        authenticatedRequest()
            .post(post_role, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const updateRole = (values) => {
        authenticatedRequest()
            .post(update_role(id), values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchRole = () => {
        authenticatedRequest()
            .get(get_single_role(id))
            .then((res) => {
                const role = res?.data?.role;
                form.setFieldsValue({
                    name: role.name,
                    permission: res.data.role_permissions.map((permission) => permission.name),
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        if (id) {
            fetchRole();
        }
    }, []);

    return (
        <AuthenticatedLayout title="Role" titleTwo="New Role">
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={`${id ? 'Role Updated' : 'New Role Added'}`}
                            description={`User ${form.getFieldValue('name')} was ${
                                id ? 'updated' : 'added'
                            } successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Role Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Name"
                                        form={form}
                                        formItemName="name"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input role name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Permissions"
                                        form={form}
                                        formItemName="permission"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <SelectPermission
                                            isFormList={false}
                                            name="permission"
                                            mode="multiple"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a permission',
                                                },
                                            ]}
                                        />
                                    </FloatingLabel>
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={`${id ? 'Update Role' : 'Save Role'}`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['ROLE_CREATE'],
})(CreateRole);
