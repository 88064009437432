import { USER_SIGN_IN_SUCCESS, USER_LOG_OUT } from '../types';

const onUserSignInSuccess = (data) => ({
    type: USER_SIGN_IN_SUCCESS,
    data,
});

const onUserLogout = () => ({
    type: USER_LOG_OUT,
});

const userSignIn = (data) => {
    return (dispatch) => {
        dispatch(onUserSignInSuccess(data));
    };
};

const userLogout = () => {
    return (dispatch) => {
        return dispatch(onUserLogout());
    };
};

export const signActions = {
    userSignIn,
    userLogout,
    onUserSignInSuccess,
};
