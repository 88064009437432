import { css } from 'styled-components';

const style = ({ borderColor = null, buttonColor = null, color = null }) => css`
    .superior-homes-button {
        background: ${buttonColor !== null ? buttonColor : 'transparent'};
        color: ${color};
        border-radius: 5px;
        height: 50px;
        border-color: ${borderColor};
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export default style;
