import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, Input, DatePicker, notification, Select } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { post_client_product, update_client_product } from '../../apis';
import SelectProduct from '../../Selects/Product';
import { mapErrorToFields } from '../../Functions';
import moment from 'moment';
import SelectResourceType from '../../Selects/ResourceType';
import SelectPaymentType from '../../Selects/PaymentType';
import FormUploads from '../../FormUploads';

interface Props {
    visible: boolean;
    clientId: string;
    onClose: (success: boolean) => void;
    product?: {
        id: string;
        productId?: string;
        houseNumber?: string;
        dateOfPurchase?: string;
        initialProductPrice?: number;
        paymentAmount?: string;
    };
}

const NewProduct = ({ visible, clientId, onClose, product }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [file, setFile] = useState('');
    const [isPaying, setIsPaying] = useState<any>();

    const onFinish = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('client_id', clientId);
        formData.append('product_id', values.product_id);
        formData.append('house_number', values.house_number);
        formData.append('date_of_purchase', values.date_of_purchase);
        formData.append('initial_product_price', values?.initial_product_price);
        formData.append('amount_paid', values?.amount_paid);
        formData.append('is_paying', values?.is_paying);
        formData.append('payment_type_id', values?.payment_type_id);
        formData.append('resource_type_id', values?.resource_type_id);
        formData.append('resource_file', file);
        formData.append('resource_title', 'Test');
        if (isEditing) {
            updateProduct(formData);
        } else {
            createProduct(formData);
        }
    };

    const createProduct = (values) => {
        authenticatedRequest()
            .post(post_client_product, values)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const updateProduct = (values) => {
        authenticatedRequest()
            .post(update_client_product(product?.id), values)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };
    const cancel = () => {
        form.resetFields();
        onClose(false);
    };

    useEffect(() => {
        if (product && Object.keys(product).length) {
            setIsEditing(true);
            form.setFieldsValue({
                product_id: product.productId,
                house_number: product.houseNumber,
                initial_product_price: product.initialProductPrice,
                payment_amount: product.paymentAmount,
                date_of_purchase: moment(product.dateOfPurchase),
            });
            return;
        }
        setIsEditing(false);
        form.resetFields();
    }, [product]);

    return (
        <Modal
            visible={visible}
            title={isEditing ? 'Update Product' : 'New Product'}
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Product"
                            form={form}
                            formItemName="product_id"
                            classLabel={isEditing ? 'label label-float' : 'label'}
                        >
                            <SelectProduct
                                name="product_id"
                                isFormList={false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a product',
                                    },
                                ]}
                            />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="House No."
                            form={form}
                            isFormList={false}
                            formItemName="house_number"
                            classLabel={isEditing ? 'label label-float' : 'label'}
                        >
                            <Form.Item
                                name={'house_number'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please house number',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Purchase Date"
                            form={form}
                            formItemName="date_of_purchase"
                            isFormList={false}
                            classLabel={isEditing ? 'label label-float' : 'label'}
                        >
                            <Form.Item
                                name={'date_of_purchase'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select purchase date',
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="" />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Total Price"
                            form={form}
                            formItemName="initial_product_price"
                            isFormList={false}
                            classLabel={isEditing ? 'label label-float' : 'label'}
                        >
                            <Form.Item
                                name={'initial_product_price'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the total price',
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Amount Paid"
                            form={form}
                            formItemName="amount_paid"
                            isFormList={false}
                            classLabel={isEditing ? 'label label-float' : 'label'}
                        >
                            <Form.Item
                                name={'amount_paid'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the amount paid',
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Is Paying" form={form} formItemName="is_paying">
                            <Form.Item
                                name={'is_paying'}
                                fieldKey={'is_paying'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the amount paid',
                                    },
                                ]}
                            >
                                <Select onSelect={(value) => setIsPaying(value)}>
                                    <Select.Option value={1}>Yes</Select.Option>
                                    <Select.Option value={0}>No</Select.Option>
                                </Select>
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    {isPaying ? (
                        <>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <FloatingLabel
                                    label="Resource Type"
                                    form={form}
                                    formItemName="resource_type_id"
                                    classLabel="label"
                                >
                                    <SelectResourceType formItemName={'resource_type_id'} />
                                </FloatingLabel>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <FloatingLabel
                                    label="Payment Type"
                                    form={form}
                                    classLabel="label"
                                    formItemName="payment_type_id"
                                >
                                    <SelectPaymentType />
                                </FloatingLabel>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <FormUploads
                                    formItemName={'resource_file'}
                                    label="File"
                                    manualUpload={true}
                                    setFile={setFile}
                                />
                            </Col>
                        </>
                    ) : null}
                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => cancel()} />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Save Product" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewProduct;
