import React from 'react';
import { Modal } from 'antd';
import { RiErrorWarningFill } from 'react-icons/ri';
import styled from 'styled-components';
import style from './styles';
import SuperiorHomesDivider from '../../Divider';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';

const ConfirmationWrapper = styled.div`
    ${style}
`;

interface Props {
    visible: boolean;
    title: string | undefined;
    description: string | undefined;
    onClose: () => void;
    onComplete: () => void;
    onCompleteLoading: boolean;
    completeButton?: string;
    showCancelButton?: boolean;
}

const WarningModal = ({
    visible,
    onClose,
    title,
    description,
    onComplete,
    onCompleteLoading,
    completeButton,
    showCancelButton = false,
}: Props) => {
    return (
        <Modal visible={visible} onCancel={onClose} width="30%" footer={null} closeIcon={<div />}>
            <ConfirmationWrapper>
                <div className="icon-container">
                    <RiErrorWarningFill color="#fa8c16" size="50" />
                </div>
                <div className="content-container">
                    <p className="title">{title}</p>
                    <SuperiorHomesDivider />
                    <p className="description">{description}</p>
                </div>
                <div className="action-container">
                    {showCancelButton ? (
                        <SuperiorHomesCancelButton buttonName="Cancel" style={{ marginRight: 10 }} onClick={onClose} />
                    ) : null}
                    <SuperiorHomesBlueButton
                        buttonName={completeButton ?? 'Complete'}
                        onClick={onComplete}
                        loading={onCompleteLoading}
                    />
                </div>
            </ConfirmationWrapper>
        </Modal>
    );
};

export default WarningModal;
