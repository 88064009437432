import React from 'react';
import { useSelector } from 'react-redux';

import Permissions from './Permissions';
import { checkRoutePermission } from './checkRoutePermission';
import Disabled from './disabled';

export type RolesType = keyof typeof Permissions;

interface Props {
    allowedPermissions?: any;
    renderNoAccess?: () => React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
}

type AuthorizationWrapperType = React.FunctionComponent<Props> & {
    Roles: { [key in RolesType]: string };
};

const defaultProps: Props = {
    allowedPermissions: [],
};

export function checkPermittedRoles(
    userPermissions: RolesType | RolesType[],
    allowedPermissions: RolesType[],
): boolean {
    if (allowedPermissions.length === 0) return true;
    const _userRoles = Array.isArray(userPermissions) ? userPermissions : [userPermissions];
    return _userRoles.some((role) => allowedPermissions.includes(role));
}

export function checkRestrictedRoles(userRoles: RolesType | RolesType[], restrictedRoles: RolesType[]): boolean {
    if (restrictedRoles.length === 0) return false;
    const _userRoles = Array.isArray(userRoles) ? userRoles : [userRoles];
    return _userRoles.some((role) => restrictedRoles.includes(role));
}

const AuthorizationWrapper: AuthorizationWrapperType = (props: Props): any => {
    const { allowedPermissions, renderNoAccess, children, disabled } = props;
    if (!allowedPermissions) return children;
    const user: any = useSelector<any>((state) => state.auth.user);

    const canAccess = checkRoutePermission(user, allowedPermissions);

    if (canAccess) return children;

    if (disabled) return <Disabled>{children}</Disabled>;

    return renderNoAccess ? renderNoAccess() : null;
};

AuthorizationWrapper.Roles = Permissions;

AuthorizationWrapper.defaultProps = defaultProps;

export default AuthorizationWrapper;
