import moment from 'moment';
import { unAuthenticatedRequest } from '../../api';
import { refresh_token } from '../apis';

// inMemoryJwt.js
const InMemoryJWTManager = () => {
    let logoutEventName = 'ra-logout';
    let isRefreshing;
    let inMemoryJWT = null;
    let refreshTimeOutId;

    // This listener allows to disconnect another session of react-admin started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    const waitForRefreshToken = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }

        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    };
    // This countdown feature is used to renew the JWT in a way that is transparent to the user.
    // before it's no longer valid
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(getRefreshedToken, delay - 5000); // Validity period of the token in seconds, minus 5 seconds
    };

    const abortRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const getRefreshToken = window.localStorage.getItem('refresh-token');

    // The method makes a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will return a fresh jwt.
    const getRefreshedToken = () => {
        const data = {
            refresh_token: getRefreshToken,
        };
        isRefreshing = unAuthenticatedRequest()
            .post(refresh_token, data)
            .then((res) => {
                setToken(res.data.data.meta.access_token, parseInt(res.data.data.meta.expires_in) * 1000);
                setRefreshToken(res.data.data.meta.refresh_token);
                return true;
            })
            .catch(() => {
                sessionExpired();
                return false;
            });

        return isRefreshing;
    };

    const sessionExpired = () => {
        eraseToken();
        window.localStorage.setItem('gs-session-expired', moment().format('DD-M-YYYY HH:mm'));
        window.dispatchEvent(new Event('sessionExpired'));
    };

    const getToken = () => inMemoryJWT;

    const setToken = (token, delay) => {
        inMemoryJWT = token;
        refreshToken(delay);
        clearStorage();
        return true;
    };

    const setRefreshToken = (token) => {
        window.localStorage.setItem('refresh-token', token);
    };

    const eraseToken = () => {
        inMemoryJWT = null;
        abortRefreshToken();
        window.localStorage.setItem('ra-logout', moment().format('DD-M-YYYY HH:mm'));
        return true;
    };

    const setLogoutEventName = (name) => (logoutEventName = name);

    const checkForLogout = () => {
        if (window.localStorage.getItem(logoutEventName)) {
            return true;
        }
        return false;
    };

    const clearStorage = () => {
        window.localStorage.removeItem(logoutEventName);
    };

    return {
        eraseToken,
        setLogoutEventName,
        waitForRefreshToken,
        getRefreshedToken,
        setRefreshToken,
        getToken,
        setToken,
        sessionExpired,
        checkForLogout,
    };
};

export default InMemoryJWTManager();
