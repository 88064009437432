import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Row, Col, Form, Input, DatePicker, notification, Select } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { post_convert_prospect_to_client } from '../../apis';
import SelectProduct from '../../Selects/Product';
import { disableFutureDate, mapErrorToFields } from '../../Functions';
import FormUploads from '../../FormUploads';
import SelectResourceType from '../../Selects/ResourceType';
import SelectPaymentType from '../../Selects/PaymentType';

interface Props {
    visible: boolean;
    clientId: string;
    onClose: (success: boolean) => void;
}

const ConvertProspectToClient = ({ visible, clientId, onClose }: Props) => {
    const [form] = Form.useForm();
    const [file, setFile] = useState('');
    const [isPaying, setIsPaying] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('product_id', values.product_id);
        formData.append('house_number', values.house_number);
        formData.append('payment_date', values.payment_date);
        formData.append('price', values.price);
        formData.append('payment_amount', values.payment_amount);
        formData.append('resource_file', file);
        formData.append('resource_type_id', values?.resource_type_id);
        formData.append('is_paying', values?.is_paying);
        formData.append('payment_type_id', values?.payment_type_id);
        formData.append('resource_title', 'Test');
        formData.append('client_id', clientId);
        values['client_id'] = clientId;

        authenticatedRequest()
            .post(post_convert_prospect_to_client, formData)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const cancel = () => {
        form.resetFields();
        onClose(false);
    };

    return (
        <Modal
            visible={visible}
            title="Convert Prospect"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Product" form={form} formItemName="product_id">
                            <SelectProduct
                                isFormList={false}
                                name="product_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a product',
                                    },
                                ]}
                            />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="House No" form={form} formItemName="house_number">
                            <Form.Item
                                name="house_number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input a house number',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Payment Date" form={form} formItemName="payment_date">
                            <Form.Item
                                name="payment_date"
                                rules={[{ required: true, type: 'date', message: 'Please select payment date' }]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={disableFutureDate}
                                    placeholder={''}
                                />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Total Price" form={form} formItemName="price">
                            <Form.Item name="price" rules={[{ required: true, message: 'Please input total price' }]}>
                                <Input type="number" />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Payment Amount" form={form} formItemName="payment_amount">
                            <Form.Item
                                name="payment_amount"
                                rules={[{ required: true, message: 'Please input payment amount' }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Is Paying" form={form} formItemName="is_paying">
                            <Form.Item
                                name={'is_paying'}
                                fieldKey={'is_paying'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select if the client is paying',
                                    },
                                ]}
                            >
                                <Select onSelect={(value) => setIsPaying(value)}>
                                    <Select.Option value={1}>Yes</Select.Option>
                                    <Select.Option value={0}>No</Select.Option>
                                </Select>
                            </Form.Item>
                        </FloatingLabel>
                    </Col>
                    {isPaying ? (
                        <>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <FloatingLabel
                                    label="Resource Type"
                                    form={form}
                                    formItemName="resource_type_id"
                                    classLabel="label"
                                >
                                    <SelectResourceType formItemName={'resource_type_id'} />
                                </FloatingLabel>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <FloatingLabel
                                    label="Payment Type"
                                    form={form}
                                    classLabel="label"
                                    formItemName="payment_type_id"
                                >
                                    <SelectPaymentType />
                                </FloatingLabel>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <FormUploads
                                    formItemName={'resource_file'}
                                    label="File"
                                    manualUpload={true}
                                    setFile={setFile}
                                />
                            </Col>
                        </>
                    ) : null}

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => cancel()} />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton
                                htmlType="submit"
                                buttonName="Convert Prospect"
                                loading={loading}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ConvertProspectToClient;
