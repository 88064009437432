import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SuccessModal from '../../../components/modals/success';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import SelectDepartment from '../../../components/Selects/Department';
import PhoneNumber from '../../../components/PhoneNumber';
import { authenticatedRequest } from '../../../api';
import { admin_update_user, get_user, post_user } from '../../../components/apis';
import SelectRole from '../../../components/Selects/Role';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { mapErrorToFields } from '../../../components/Functions';

const CreateUser = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<any>();
    const [visible, setVisible] = useState<boolean>(false);
    const { id } = useParams();
    const [form] = Form.useForm();

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.USERS);
    };

    const fetchUser = () => {
        authenticatedRequest()
            .get(get_user(id))
            .then((res) => {
                const user = res?.data?.user?.[0];
                const role = res?.data?.role?.[0];
                const department = res?.data?.department;
                form.setFieldsValue({
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email: user.email,
                    department_id: department.id,
                    work_phone: user.work_phone,
                    personal_phone: user.personal_phone,
                    role_id: role?.id,
                    position: user?.position,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onFinish = (values) => {
        setLoading(true);
        if (id) {
            updateUser(values);
        } else {
            createUser(values);
        }
    };

    const createUser = (values) => {
        authenticatedRequest()
            .post(post_user, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const updateUser = (values) => {
        authenticatedRequest()
            .post(admin_update_user(id), values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        if (id) {
            fetchUser();
        }
    }, []);

    return (
        <AuthenticatedLayout title="Users" titleTwo={id ? 'Edit User' : 'New User'}>
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={id ? 'User updated' : 'New User Added'}
                            description={`User ${form.getFieldValue('first_name')} ${form.getFieldValue(
                                'last_name',
                            )} was ${id ? 'updated' : 'added'} successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="User Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="First Name"
                                        form={form}
                                        formItemName="first_name"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="first_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input users first name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Last Name"
                                        form={form}
                                        formItemName="last_name"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="last_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input users last name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Email"
                                        form={form}
                                        formItemName="email"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                    message: 'Please input users email',
                                                },
                                            ]}
                                        >
                                            <Input readOnly={!!id} />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Department"
                                        form={form}
                                        formItemName="department_id"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <SelectDepartment />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <PhoneNumber
                                        formLabelName="Work Phone Number"
                                        form={form}
                                        required={true}
                                        message="Please input users work phone number"
                                        formItemName="work_phone"
                                    />
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <PhoneNumber
                                        formLabelName="Personal Phone Number"
                                        form={form}
                                        required={true}
                                        message="'Please input users personal phone number'"
                                        formItemName="personal_phone"
                                    />
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Role"
                                        form={form}
                                        formItemName="role"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <SelectRole
                                            isFormList={false}
                                            name="role_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a role',
                                                },
                                            ]}
                                        />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Position"
                                        form={form}
                                        formItemName="position"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="position"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input users position',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={id ? `Update User` : `Save User`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['USER_CREATE'],
})(CreateUser);
