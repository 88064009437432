import { css } from 'styled-components';

const style = () => css`
    .recharts-legend-wrapper {
        right: 15% !important;
    }

    .legend-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
    }
    .label-top {
        font-size: 34px;
        font-weight: bold;
        color: #404040;
    }
    .label-bottom {
        transform: translateY(40px);
        text-transform: uppercase;
        font-size: 14px;
        color: #39426a;
    }
`;

export default style;
