import { css } from 'styled-components';

const style = () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0px 20px;
    .logo-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0px 10px 0px;
        img {
            width: 300px;
        }
    }
    .salutations-container {
        margin-bottom: 20px;
        .salutation {
            font-weight: 800;
            font-size: 34px;
            line-height: 53px;
            color: #404040;
            margin-bottom: 10px;
        }
        .description {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #404040;
            margin-bottom: 10px;
        }
        .line {
            width: 49px;
            height: 2px;
            top: 340px;
            background: #005a87;
            border-radius: 30px;
        }
    }
`;

export default style;
