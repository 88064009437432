import React, { useState, useCallback } from 'react';
import { Table, Select, Pagination, Input } from 'antd';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { LoadingOutlined } from '@ant-design/icons';
import { IoChevronDown } from 'react-icons/io5';
import { RiSearch2Line, RiFilter3Line } from 'react-icons/ri';
import style from './styles';

const TableWrapper = styled.div`
    ${style}
`;

const { Option } = Select;

const antIcon = <LoadingOutlined style={{ color: '#005A87' }} spin />;

const renderPagination = (_, type, originalElement) => {
    if (type === 'prev') {
        return <a className="table-prev">Previous</a>;
    }
    if (type === 'next') {
        return <a>Next</a>;
    }
    return originalElement;
};

interface FooterProps {
    pageSize: number;
    currentPage: number;
    setPageSize: (value) => void;
    onPageChange: (page) => void;
    total: number;
}
const Footer = ({ pageSize, currentPage, setPageSize, onPageChange, total }: FooterProps) => {
    const start = pageSize * (currentPage - 1) + 1;
    const end = pageSize * currentPage;
    return (
        <div className="footer">
            <div className="select-wrapper">
                <span>Show&nbsp;</span>
                <Select
                    defaultValue="10"
                    suffixIcon={<IoChevronDown color="#8D8D8D" size="16" />}
                    onChange={(value) => setPageSize(parseInt(value))}
                >
                    <Option value="10">10</Option>
                    <Option value="20">20</Option>
                    <Option value="50">50</Option>
                    <Option value="100">100</Option>
                </Select>
                <span>&nbsp;Entries</span>
            </div>
            <div className="pagination-wrapper">
                <span className="footer-span">
                    Showing {Math.min(start, total)} to {Math.min(total, end)} of {total} entries
                </span>

                <Pagination
                    total={total}
                    pageSize={pageSize}
                    current={currentPage}
                    onChange={(page) => onPageChange(page)}
                    itemRender={(current, type, originalElement) => renderPagination(current, type, originalElement)}
                />
            </div>
        </div>
    );
};

interface SearchProps {
    placeholder: string;
    onChange: (value) => void;
    onSearch: (value) => void;
    onFilter?: () => void;
}

const TableSearch = ({ placeholder, onChange, onFilter }: SearchProps) => {
    const changeHandler = (event) => {
        onChange(event.target.value);
    };
    const debouncedChangeHandler = useCallback(debounce(changeHandler, 600), []);

    return (
        <Input
            className="search-input"
            prefix={<RiSearch2Line color="#707070" size="16" />}
            style={{ maxWidth: 300 }}
            placeholder={placeholder}
            suffix={
                <RiFilter3Line color="#707070" size="16" style={{ cursor: 'pointer' }} onClick={() => onFilter?.()} />
            }
            onChange={debouncedChangeHandler}
        />
    );
};

interface TableProps {
    columns: any;
    data: any;
    total?: number;
    withPagination?: boolean;
    loading?: boolean;
    onRecordSelected?: (selectedRecords) => void;
    onPageChange?: (page, pageSize) => void;
    onPageSizeChange?: (page, pageSize) => void;
    onRowClick?: (record) => void;
    scrollable?: boolean;
}

const SuperiorHomesTable = ({
    columns,
    data,
    withPagination = false,
    loading = false,
    onRecordSelected,
    onRowClick,
    onPageChange,
    onPageSizeChange,
    total = 10,
    scrollable = false,
}: TableProps) => {
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setPageSize] = useState(10);
    const onSelectChange = (selectedKeys) => {
        setSelectedKeys(selectedKeys);
        if (onRecordSelected) {
            onRecordSelected(selectedKeys);
        }
    };

    const rowSelection = {
        selectedKeys,
        onChange: onSelectChange,
    };

    return (
        <TableWrapper>
            {withPagination ? (
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    loading={{
                        spinning: loading,
                        indicator: antIcon,
                    }}
                    onRow={(record: any) => ({
                        onClick: () => (onRowClick ? onRowClick(record) : null),
                    })}
                    rowSelection={rowSelection}
                    footer={() => (
                        <Footer
                            onPageChange={(page) => {
                                setCurrentPage(page);
                                onPageChange ? onPageChange(page, currentPageSize) : null;
                            }}
                            setPageSize={(pageSize) => {
                                setPageSize(pageSize);
                                onPageSizeChange ? onPageSizeChange(currentPage, pageSize) : null;
                            }}
                            currentPage={currentPage}
                            total={total}
                            pageSize={currentPageSize}
                        />
                    )}
                    scroll={scrollable ? { x: 100 } : { x: true }}
                />
            ) : (
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={{
                        spinning: loading,
                        indicator: antIcon,
                    }}
                    onRow={(record: any) => ({
                        onClick: () => (onRowClick ? onRowClick(record) : null),
                    })}
                    pagination={false}
                    scroll={scrollable ? { x: 1500 } : { x: true }}
                />
            )}
        </TableWrapper>
    );
};

export { SuperiorHomesTable, TableSearch };
