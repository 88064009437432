import React, { useState } from 'react';
import { Modal, Row, Col, Form, Input, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { create_client_resource } from '../../apis';
import SectionDivider from '../../SectionDivider';
import SelectResourceType from '../../Selects/ResourceType';
import FormUploads from '../../FormUploads';
import { mapErrorToFields } from '../../Functions';

interface Props {
    visible: boolean;
    clientId: string;
    onClose: (success: boolean) => void;
}

const NewClientDocument = ({ visible, clientId, onClose }: Props) => {
    const [form] = Form.useForm();
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('resource_file', file);
        formData.append('resource_type_id', values.resource_type_id);
        formData.append('resource_title', values.resource_title);
        formData.append('client_id', clientId);
        setLoading(true);
        createResource(formData);
    };

    const createResource = (values) => {
        authenticatedRequest()
            .post(create_client_resource, values)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'An issue occurred in the form. Please correct it and submit again!',
                });
            });
    };

    return (
        <Modal
            visible={visible}
            title="New Document"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <SectionDivider title="Resource Details:" />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FloatingLabel label="Name" form={form} formItemName="resource_title">
                            <Form.Item
                                name="resource_title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input resource name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FloatingLabel label="Resource Type" form={form} formItemName="resource_type_id">
                            <SelectResourceType formItemName="resource_type_id" />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FormUploads formItemName="resource_file" label="File" manualUpload={true} setFile={setFile} />
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => onClose(false)} />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Save" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewClientDocument;
