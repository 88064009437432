import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_house_types } from '../../apis';

const SelectHouseType = () => {
    const [houseTypes, setHouseTypes] = useState<any>([]);

    const fetchHouseTypes = () => {
        authenticatedRequest()
            .get(list_house_types(1, 100))
            .then((res) => {
                const houseTypes = res.data.house_types?.data.map((houseType) => {
                    return {
                        value: houseType.id.toString(),
                        label: houseType.house_type_name,
                    };
                });
                setHouseTypes(houseTypes);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchHouseTypes();
    }, []);

    return (
        <Form.Item
            name="house_type_id"
            rules={[
                {
                    required: true,
                    message: 'Please select a house type',
                },
            ]}
        >
            <Select allowClear options={houseTypes} showSearch optionFilterProp="label" />
        </Form.Item>
    );
};

export default SelectHouseType;
