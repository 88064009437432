import { css } from 'styled-components';
import { DEVICE } from '../../../config/theme';

interface Props {
    searchFocus: boolean;
}

const style = ({ searchFocus }: Props) => css`
    .top-bar-container {
        background-color: transparent;
        height: 100px;
        display: flex;
        .title-container {
            flex: 1;
            display: flex;
            align-items: center;
            .title {
                font-weight: normal;
                font-size: 18px;
                line-height: 28px;
                color: #404040;
            }
            .title-two {
                width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: normal;
                font-size: 18px;
                line-height: 28px;
                color: #2f80ed;
            }
            @media ${DEVICE.laptop} {
                .title-two {
                    //width: 100%;
                }
            }
        }
        .action-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .search-container {
            display: flex;
            margin-right: 20px;
            .search-input {
                width: ${searchFocus ? '350px' : '200px'};
                background: #ffffff;
                border: 1px solid #ebeded;
                box-sizing: border-box;
                border-radius: 5px;
                height: 45px;
                min-width: 70px;
            }
            .search-button {
                background: rgba(0, 90, 135, 0.2);
                border-radius: 3px;
                border: none;
                height: 30px;
                width: 30px;
                padding: 4px;
            }
        }
        .user-container {
            display: flex;
            .dropdown {
                //background: #ffffff;
                //border: 1px solid #ebeded;
                box-sizing: border-box;
                border-radius: 5px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px 20px;
                cursor: pointer;
            }
        }
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 45px;
    }
`;

export default style;
