import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Table,
    InputNumber,
    Popconfirm,
    Typography,
    DatePicker,
    Divider,
    notification,
} from 'antd';
import SectionDivider from '../../../components/SectionDivider';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import style from '../../../components/Table/styles';
import styled from 'styled-components';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import {
    get_single_client_payment_plan,
    post_payment_plan,
    update_payment_plan_detail,
    post_payment_plan_detail,
} from '../../../components/apis';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Card } from '../../../components/Cards';
import SuccessModal from '../../../components/modals/success';

const TableWrapper = styled.div`
    ${style}
`;

interface Item {
    key: string | number;
    payment_amount: string | number;
    payment_date: any;
    description: string;
    id?: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'date';
    record: Item;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: EditableCellProps) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : inputType === 'date' ? <DatePicker /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const CreatePaymentPlan = () => {
    const history = useHistory();
    const { clientProductId, paymentPlanId } = useParams();
    const [form] = Form.useForm();
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<Item[]>([]);
    const [editingKey, setEditingKey] = useState<string | number>('');
    const isEditing = (record: Item) => record.key === editingKey;

    const onAdd = () => {
        const newData = [...data];
        const item = {
            key: newData.length + 1,
            description: 'Description',
            payment_amount: '1',
            payment_date: moment(),
        };
        newData.push(item);
        setData(newData);
        edit(item);
    };

    const edit = (record: Partial<Item> & { key: React.Key }) => {
        form.setFieldsValue({ description: '', payment_amount: '', payment_date: '', ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key: React.Key, id?: string) => {
        try {
            const row = (await form.validateFields()) as Item;
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                if (paymentPlanId) {
                    let url = post_payment_plan_detail;
                    let data = {
                        payment_plan_id: paymentPlanId,
                        payment_plan_details: JSON.stringify([
                            {
                                payment_amount: row.payment_amount.toString(),
                                payment_date: row.payment_date.format('YYYY-MM-DD'),
                                description: row.description,
                            },
                        ]),
                    } as any;

                    if (id) {
                        url = update_payment_plan_detail(id);
                        data = {
                            payment_amount: row.payment_amount.toString(),
                            payment_date: row.payment_date.format('YYYY-MM-DD'),
                            payment_description: row.description,
                        };
                    }
                    authenticatedRequest()
                        .post(url, data)
                        .then(() => {
                            setData(newData);
                            setEditingKey('');
                            notification.success({
                                message: 'Success',
                                description: 'Payment plan detail updated successfully',
                            });
                        })
                        .catch(() => {
                            notification.error({
                                message: 'Error',
                                description: 'Payment plan detail was not updated',
                            });
                        });
                } else {
                    setData(newData);
                    setEditingKey('');
                }
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const onDelete = (key: React.Key) => {
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.key);
        if (index > -1) {
            newData.splice(index, 1);
            setData(newData);
            setEditingKey('');
        }
    };

    const onFinish = (values) => {
        setLoading(true);

        if (!data.length) {
            setLoading(false);
            notification.error({
                message: 'Error',
                description: 'Please fill in at least one payment plan row',
            });
            return;
        }

        const paymentsPlanDetails = data.map((item) => {
            return {
                payment_amount: item.payment_amount.toString(),
                payment_date: item.payment_date,
                description: item.description,
            };
        });

        values['client_product_id'] = clientProductId;
        values['payment_plan_details'] = JSON.stringify(paymentsPlanDetails);

        authenticatedRequest()
            .post(post_payment_plan, values)
            .then(() => {
                setVisible(true);
                setLoading(false);
                form.resetFields();
            })
            .catch(() => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.goBack();
    };

    const fetchPaymentPlan = () => {
        authenticatedRequest()
            .get(get_single_client_payment_plan(paymentPlanId))
            .then((res) => {
                form.setFieldsValue({
                    payment_plan_name: res?.data?.payment_plan?.[0]?.payment_plan_name,
                });
                const newData = res?.data?.payment_plan_details.map((detail) => {
                    return {
                        id: detail.id,
                        description: detail.payment_description,
                        payment_amount: detail.payment_amount,
                        payment_date: moment(detail.payment_date),
                    };
                });
                setData(newData);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        if (paymentPlanId) {
            fetchPaymentPlan();
            if (history.location?.pathname?.includes('duplicate')) {
                setIsDuplicate(true);
            }
        }
    }, []);

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            editable: true,
        },
        {
            title: 'Amount',
            dataIndex: 'payment_amount',
            editable: true,
        },
        {
            title: 'Date',
            dataIndex: 'payment_date',
            editable: true,
            render: (val) => moment(val).format('DD MMM YYYY'),
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            // eslint-disable-next-line react/display-name
            render: (_: any, record: Item) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a href="javascript:;" onClick={() => save(record.key, record.id)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a>Cancel</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                        <Divider type="vertical" />
                        <Typography.Link disabled={editingKey !== ''} onClick={() => onDelete(record?.key)}>
                            Delete
                        </Typography.Link>
                    </>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Item) => ({
                record,
                inputType:
                    col.dataIndex === 'payment_amount' ? 'number' : col.dataIndex === 'payment_date' ? 'date' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <AuthenticatedLayout title="Create Payment Plan">
            <Col xs={24} sm={24} md={14} lg={24} xl={14}>
                <SuccessModal
                    visible={visible}
                    onClose={onComplete}
                    onComplete={onComplete}
                    title={'New Payment Plan Added'}
                    description={`Payment Plan  ${form.getFieldValue('payment_plan_name')} was added successfully`}
                />
                <Card>
                    <div style={{ padding: 20 }}>
                        <Form
                            onFinish={onFinish}
                            form={form}
                            layout="vertical"
                            style={{ width: '100%' }}
                            scrollToFirstError={true}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Payment Plan Details:" />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="payment_plan_name"
                                        label="Name"
                                        rules={[{ required: true, message: 'Please input payment plans name' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <SuperiorHomesBlueButton buttonName="Add" onClick={onAdd} />
                                    <TableWrapper>
                                        <Table
                                            components={{
                                                body: {
                                                    cell: EditableCell,
                                                },
                                            }}
                                            columns={mergedColumns}
                                            dataSource={data}
                                            pagination={false}
                                        />
                                    </TableWrapper>
                                </Col>

                                <Divider type="horizontal" />

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => history.back()} />
                                    </Form.Item>
                                    {paymentPlanId ? null : (
                                        <Form.Item>
                                            <SuperiorHomesBlueButton
                                                htmlType="submit"
                                                buttonName="Save"
                                                disabled={editingKey != ''}
                                                loading={loading}
                                            />
                                        </Form.Item>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default CreatePaymentPlan;
