import { css } from 'styled-components';

const style = () => css`
    .divider-container {
        width: 100%;
        display: flex;
        .title {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: #333333;
        }
    }
    .divider-container::after {
        border-bottom: 1px solid #f2f5f6;
        border-top: 1px solid transparent;
        border-top: 0;
        content: '';
        position: relative;
        transform: translateY(-20%);
        width: 100%;
        margin-left: 10px;
        display: flex;
        flex: 1;
    }
`;

export default style;
