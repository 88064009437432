import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import { get_single_resource, post_resource, update_resource } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import SelectResourceType from '../../../components/Selects/ResourceType';
import FormUploads from '../../../components/FormUploads';
import { mapErrorToFields } from '../../../components/Functions';

const CreateResource = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [file, setFile] = useState('');
    const [uploadedFile, setUploadedFile] = useState<any>({});
    const [form] = Form.useForm();
    const { id } = useParams();

    const onFinish = (values) => {
        const formData = new FormData();
        if (file !== '') {
            formData.append('resource_file', file);
        }
        formData.append('resource_type_id', values.resource_type_id);
        formData.append('resource_title', values.resource_title);
        setLoading(true);
        if (id) {
            updateResource(formData);
        } else {
            createResource(formData);
        }
    };

    const updateResource = (values) => {
        authenticatedRequest()
            .post(update_resource(id), values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const createResource = (values) => {
        authenticatedRequest()
            .post(post_resource, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchResource = () => {
        authenticatedRequest()
            .get(get_single_resource(id))
            .then((res) => {
                const resource = res?.data?.resource?.[0];
                form.setFieldsValue({
                    resource_title: resource.resource_title,
                    resource_type_id: resource.resource_type_id,
                });
                setUploadedFile({
                    title: resource.resource_title,
                    id: resource.id,
                    extension: resource.resource_extension_type,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.RESOURCES);
    };

    useEffect(() => {
        if (id) {
            fetchResource();
        }
    }, []);

    return (
        <AuthenticatedLayout title="Resource" titleTwo="New Resource">
            <Col xs={24} sm={24} md={14} lg={24} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={id ? 'Resource updated' : 'New Resource Added'}
                            description={`Resource Type ${form.getFieldValue('resource_title')} was ${
                                id ? 'updated' : 'added'
                            } successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Resource Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Name"
                                        form={form}
                                        formItemName="resource_title"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="resource_title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input resource name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Resource Type"
                                        form={form}
                                        formItemName="resource_type_id"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <SelectResourceType formItemName="resource_type_id" />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FormUploads
                                        uploadedFile={uploadedFile}
                                        formItemName="resource_file"
                                        label="File"
                                        manualUpload={true}
                                        setFile={setFile}
                                    />
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={id ? `Update Resource` : `Save Resource`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['RESOURCE_CREATE'],
})(CreateResource);
