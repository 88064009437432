import React, { useState } from 'react';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import PhoneNumber from '../../../components/PhoneNumber';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import SelectSource from '../../../components/Selects/Source';
import SelectProspectType from '../../../components/Selects/ProspectType';
import { authenticatedRequest } from '../../../api';
import { post_prospect, request_client_reassign } from '../../../components/apis';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import SelectProduct from '../../../components/Selects/Product';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { mapErrorToFields } from '../../../components/Functions';
import SelectHasSiteVisit from '../../../components/Selects/SiteVisit';
import WarningModal from '../../../components/modals/success/WarningModal';

const CreateProspect = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const auth: any = useSelector((state: RootState) => state.auth);
    const [requestingReassign, setRequestingReassign] = useState<boolean>(false);
    const [showReassignWarning, setShowReassignWarning] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [selectedSource, setSelectedSource] = useState<any>({});
    const [email, setEmail] = useState<string | null>(null);

    const onFinish = (values) => {
        values['products'] = values.product_id.map((product) => {
            return {
                product_id: product,
            };
        });
        delete values.product_id;
        setEmail(values.email);
        values['relationship_manager_id'] = auth?.user?.id;
        values.products = JSON.stringify(values.products);
        setLoading(true);
        authenticatedRequest()
            .post(post_prospect, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.errors);
                if (formErrors) {
                    form.setFields(formErrors);
                }
                if (formErrors.findIndex((error) => error.name === 'email' || error.name === 'phone') !== -1) {
                    setShowReassignWarning(true);
                }
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const onRequestReassign = () => {
        setRequestingReassign(true);
        authenticatedRequest()
            .post(request_client_reassign, {
                email,
            })
            .then(() => {
                setRequestingReassign(false);
                setShowReassignWarning(false);
                notification.success({
                    message: 'Success',
                    description: 'Successfully asked for the client to be reassigned to you.',
                });
                history.push(ROUTES.CLIENTS);
            })
            .catch(() => {
                setRequestingReassign(false);
                setShowReassignWarning(false);
                notification.error({
                    message: 'Error',
                    description: 'Enable to complete request',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.PROSPECTS);
    };

    return (
        <AuthenticatedLayout title="Prospect" titleTwo="New Prospect">
            <WarningModal
                visible={showReassignWarning}
                title="Prospect already exists"
                description="Prospect already exists. However, you can put in a request to the admin for the prospect to reassigned to you"
                onClose={() => {
                    setShowReassignWarning(false);
                }}
                onComplete={() => {
                    onRequestReassign();
                }}
                onCompleteLoading={requestingReassign}
                completeButton="Reassign"
                showCancelButton={true}
            />
            <SuccessModal
                visible={visible}
                title="Prospect Added"
                description={`Prospect ${form.getFieldValue('name')} was added successfully`}
                onClose={onComplete}
                onComplete={onComplete}
            />
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <Form
                            onFinish={onFinish}
                            form={form}
                            layout="horizontal"
                            style={{ width: '100%' }}
                            initialValues={{ products: [''] }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Prospect Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Prospect Name" form={form} formItemName="name">
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input prospect name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Email" form={form} formItemName="email">
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                    message: 'Please input prospect email',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <PhoneNumber
                                        form={form}
                                        required={true}
                                        message="Please input prospect phone number"
                                        formItemName="phone"
                                    />
                                </Col>

                                <Col span={24}>
                                    <SectionDivider title="Source & Product Details:" />
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Source" form={form} formItemName="source_id">
                                        <SelectSource onChange={(source) => setSelectedSource(source)} />
                                    </FloatingLabel>
                                </Col>

                                {selectedSource.hasSiteVisit ? (
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FloatingLabel
                                            label="Was a site visit"
                                            form={form}
                                            formItemName="was_site_visit"
                                        >
                                            <SelectHasSiteVisit formItemName="was_site_visit" />
                                        </FloatingLabel>
                                    </Col>
                                ) : null}

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Status" form={form} formItemName="prospect_type_id">
                                        <SelectProspectType />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Products" form={form} formItemName="product_id">
                                        <SelectProduct
                                            isFormList={false}
                                            name="product_id"
                                            mode="multiple"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a product',
                                                },
                                            ]}
                                        />
                                    </FloatingLabel>
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName="Save Prospect"
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PROSPECT_CREATE'],
})(CreateProspect);
