import React, { useEffect, useState } from 'react';
import { Button, Form, notification, Upload } from 'antd';
import upload from '../../assets/images/cloud-upload.svg';
import style from './styles';
import styled from 'styled-components';
import DownloadFile from '../DownloadFile';
import { download_resource, post_resource } from '../apis';
import InMemoryJWTManager from '../InMemoryJWT';

const UploadWrapper = styled.div`
    ${style}
`;

interface Props {
    formItemName: string | (number | string)[];
    label: string;
    uploadedFile?: {
        id: string;
        title: string;
        extension: string;
    };
    isFormEnabled?: boolean;
    resourceTitle?: string;
    clientId?: string;
    resourceTypeId?: string;
    manualUpload?: boolean;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    readOnly?: boolean;
    disabled?: boolean;
    setFile?: (fileList) => void;
    getFileStatus?: (status: boolean) => void;
    err?: string;
    height?: any;
}

const FormUploads = ({
    formItemName,
    label,
    uploadedFile = {} as any,
    isFormEnabled = true,
    resourceTitle,
    clientId,
    resourceTypeId,
    manualUpload = false,
    initialValue,
    validateStatus,
    readOnly,
    disabled,
    setFile,
    getFileStatus,
    err,
    height,
}: Props) => {
    const [fileList, setFileList] = useState<any[]>([]);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [changeUploadedFile, setUploadedFile] = useState<boolean>(false);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);

    const addFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const uploadFile = {
        name: 'resource_file',
        action: `${process.env.REACT_APP_API_BASE_URL}${post_resource}`,
    };

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(fileList);

        if (fileList[0]?.response) {
            setFileUploading(false);
            setFileUploaded(true);
            setUploadedFile(false);
        } else {
            setFileUploaded(false);
            setUploadedFile(true);
        }
    };

    useEffect(() => {
        getFileStatus?.(isFileUploading);
    }, [isFileUploading]);

    useEffect(() => {
        if (uploadedFile && Object.keys(uploadedFile).length) {
            setFileUploaded(true);
        }
    }, [uploadedFile]);

    const beforeUpload = (file) => {
        setFileUploading(true);
        const isLt25M = file.size / 1024 / 1024 < 25;
        if (!isLt25M) {
            setFileUploading(false);
            notification.error({
                message: 'Error',
                description: 'Document must smaller than 25 MB!',
            });
            return false;
        }
        if (manualUpload) {
            setFile?.(file);
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (initialValue !== undefined) {
            setFileUploaded(true);
        } else {
            setFileUploaded(false);
        }
    }, [initialValue]);

    return (
        <UploadWrapper>
            {fileUploaded && !changeUploadedFile ? (
                <>
                    <label className="form-label">{label}</label>
                    <DownloadFile
                        url={download_resource(uploadedFile?.id)}
                        fileName={`${uploadedFile?.title}.${uploadedFile?.extension}`}
                        validateStatus={validateStatus}
                        err={err}
                        height={height}
                        onClick={() => setUploadedFile(true)}
                        readOnly={readOnly}
                    />
                    <Form.Item name={formItemName} hidden />
                </>
            ) : (
                <>
                    {isFormEnabled ? (
                        <Form.Item label={label}>
                            <Form.Item
                                name={formItemName}
                                label={label}
                                valuePropName="file_list"
                                getValueFromEvent={addFile}
                                rules={[{ required: true, message: 'Please select file!' }]}
                                noStyle
                            >
                                <Upload.Dragger
                                    {...uploadFile}
                                    fileList={fileList}
                                    multiple={false}
                                    headers={{ Authorization: `Bearer ${InMemoryJWTManager.getToken()}` }}
                                    data={{
                                        client_id: clientId,
                                        resource_type_id: resourceTypeId,
                                        resource_title: resourceTitle,
                                    }}
                                    onChange={handleChange}
                                    beforeUpload={beforeUpload}
                                    disabled={disabled}
                                    style={{
                                        minHeight: height,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={upload} alt="upload" width={25} style={{ marginRight: 10 }} />
                                    <span className="drag-text">
                                        Drag your file here or <span className="drag-link">browse</span> to upload
                                    </span>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    ) : (
                        <>
                            <label className="form-label">{label}</label>
                            <Upload.Dragger
                                {...uploadFile}
                                fileList={fileList}
                                multiple={false}
                                headers={{ Authorization: `Bearer ${InMemoryJWTManager.getToken()}` }}
                                data={{
                                    client_id: clientId,
                                    resource_type_id: resourceTypeId,
                                    resource_title: resourceTitle,
                                }}
                                onChange={handleChange}
                                beforeUpload={beforeUpload}
                                disabled={disabled}
                                style={{
                                    minHeight: height,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={upload} alt="upload" width={25} style={{ marginRight: 10 }} />
                                <span className="drag-text">
                                    Drag your file here or <span className="drag-link">browse</span> to upload
                                </span>
                            </Upload.Dragger>
                        </>
                    )}

                    {initialValue || fileList[0]?.response?.downloadURl ? (
                        <Button type="link" style={{ paddingLeft: 0 }} onClick={() => setUploadedFile(false)}>
                            Cancel
                        </Button>
                    ) : null}
                </>
            )}
        </UploadWrapper>
    );
};

export default FormUploads;
