import { PURGE } from 'redux-persist';
import { USER_SIGN_IN_SUCCESS, USER_LOG_OUT } from '../types';

const initialState = {
    user: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_SIGN_IN_SUCCESS:
            return {
                user: action.data,
            };
        case USER_LOG_OUT:
            return {
                ...initialState,
            };
        case PURGE:
            return {};
        default:
            return state;
    }
};
