export const sign_in = `api/login`;
export const log_out = `/api/logout`;
export const refresh_token = `/api/userRefreshToken`;
export const list_departments = (page, perPage = 10, search = '') =>
    `/api/department/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_department = `/api/department/create`;
export const get_single_department = (id) => `/api/department/detail/${id}`;
export const update_department = (id) => `/api/department/update/${id}`;
export const list_sources = (page, perPage = 10, search = '') =>
    `/api/source/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_source = `/api/source/create`;
export const update_source = (id) => `/api/source/update/${id}`;
export const get_single_source = (id) => `/api/source/detail/${id}`;
export const list_users = (page, perPage = 10, search = '') =>
    `/api/user/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_user = `/api/user/create`;
export const admin_update_user = (id) => `/api/user/update/${id}`;
export const resend_activation_link = (id) => `/api/reset-password/${id}`;
export const deactivate_user = (id) => `/api/user/deactivate/${id}`;
export const resend_activation_email = (id) => `/api/resend-activate-account/${id}`;
export const get_activate_account = (id) => `/api/activate-account/${id}`;
export const post_activate_account = `/api/activate-account`;
export const post_forgot_password = `/api/forgot-password`;
export const post_reset_password = `/api/forgot-password-account`;
export const get_user = (id) => `/api/user/detail/${id}`;
export const update_user = `/api/user/update-profile`;
export const update_password = `/api/user/updatePassword`;
export const list_prospects = (id, page, perPage = 10, search) =>
    `/api/prospect/list/${id}?per_page=${perPage}&page=${page}&search=${search}`;
export const admin_list_prospects = (id, page, perPage = 10) =>
    `/api/prospect/list/${id}?per_page=${perPage}&page=${page}`;
export const post_prospect = `/api/prospect/create`;
export const update_prospect = (id) => `/api/prospect/update/${id}`;
export const post_convert_prospect_to_client = `/api/prospect-client/`;
export const get_single_prospect = (id) => `/api/prospect/detail/${id}`;
export const list_prospect_types = `/api/prospect-type/list`;
export const post_clients = `/api/client/create`;
export const request_client_reassign = `/api/client/reassignment-approval`;
export const update_client = (id) => `/api/client/update/${id}`;
export const list_clients = (id, page, perPage = 10, search) =>
    `/api/client/list/${id}?per_page=${perPage}&page=${page}&search=${search}`;
export const admin_list_clients = (id, page, perPage = 10, search) =>
    `/api/client/list/${id}?per_page=${perPage}&page=${page}`;
export const search_client_prospects = (search) => `/api/client/client-prospect-search?search=${search}`;
export const get_single_client = (id) => `/api/client/detail/${id}`;
export const get_client_products = (id) => `/api/client-product/detail/${id}`;
export const post_client_product = `/api/client-product/create`;
export const delete_client_product = (id) => `/api/client-product/delete/${id}`;
export const complete_client_product = (id) => `/api/client-product/mark-completed/${id}`;
export const post_prospect_product = `/api/client-product/prospect-product`;
export const update_client_product = (id) => `/api/client-product/update/${id}`;
export const dashboard = `/api/dashboard/list`;
export const get_user_dashboard = (id) => `/api/dashboard/list/${id}`;
export const list_interaction_types = `/api/interaction-type/list`;
export const post_interaction = `/api/interaction/create`;
export const list_projects = (page, perPage = 10, search = '') =>
    `/api/development/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_project = `/api/development/create`;
export const update_project = (id) => `/api/development/update/${id}`;
export const get_single_project = (id) => `/api/development/detail/${id}`;
export const list_products = (page, perPage = 10, search = '') =>
    `/api/product/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_product = `/api/product/create`;
export const update_product = (id) => `/api/product/update/${id}`;
export const get_single_product = (id) => `/api/product/detail/${id}`;
export const list_house_types = (page, perPage, search = '') =>
    `/api/house-type/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_house_type = `/api/house-type/create`;
export const get_single_house_type = (id) => `/api/house-type/detail/${id}`;
export const update_house_type = (id) => `/api/house-type/update/${id}`;
export const get_clients_conversion = (frequency, startDate, endDate) =>
    `/api/dashboard/clients-conversion/${frequency}/${startDate}/${endDate}`;
export const get_user_clients_conversion = (frequency, startDate, endDate, id) =>
    `/api/dashboard/clients-conversion/${frequency}/${startDate}/${endDate}/${id}`;
export const get_clients_per_source = `/api/dashboard/client-per-source`;
export const get_user_clients_per_source = (id) => `/api/dashboard/client-per-source/${id}`;
export const post_schedule_interaction = `/api/interaction-schedule/create`;
export const list_scheduled_interactions = (id, page) => `/api/interaction-schedule/list/${id}?page=${page}`;
export const list_roles = `/api/role/list`;
export const get_single_role = (id) => `/api/role/detail/${id}`;
export const post_role = `/api/role/create`;
export const update_role = (id) => `/api/role/update/${id}`;
export const list_permissions = `/api/permission/list`;
export const list_email_templates = (page, perPage = 10, search = '') =>
    `/api/email-template/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_email_template = `/api/email-template/create`;
export const update_email_template = (id) => `/api/email-template/update/${id}`;
export const get_single_email_template = (id) => `/api/email-template/detail/${id}`;
export const send_email = `/api/send-email/create`;
export const activity_report = `/api/reports/activity/:startDate/:endDate?per_page=:perPage&page=:page`;
export const activity_report_export = `api/reports/activityExport/:startDate/:endDate`;
export const sales_report = `/api/reports/sales/:startDate/:endDate?per_page=:perPage&page=:page`;
export const sales_report_export = `/api/reports/salesExport/:startDate/:endDate`;
export const lead_conversion_report = `/api/reports/lead-conversion/:startDate/:endDate?per_page=:perPage&page=:page`;
export const lead_conversion_report_export = `/api/reports/lead-conversionExport/:startDate/:endDate`;
export const lead_per_source_report = `/api/reports/lead-per-source/:startDate/:endDate?per_page=:perPage&page=:page`;
export const lead_per_source_report_export = `/api/reports/lead-per-sourceExport/:startDate/:endDate`;
export const conversion_cycle_report = `/api/reports/conversion-cycle/:startDate/:endDate`;
export const conversion_cycle_report_export = `/api/reports/conversion-cycleExport/:startDate/:endDate`;
export const site_vs_booking_report = `/api/reports/site-booking/:startDate/:endDate`;
export const site_vs_booking_report_export = `/api/reports/site-bookingExport/:startDate/:endDate`;
export const post_resource = `/api/resource/create`;
export const create_client_resource = `/api/resource/create-client-resource`;
export const list_resources = (page, perPage = 10, search) =>
    `/api/resource/list?page=${page}&per_page=${perPage}&search=${search}`;
export const get_single_resource = (id) => `/api/resource/detail/${id}`;
export const delete_resource = (id) => `/api/resource/delete/${id}`;
export const update_resource = (id) => `/api/resource/update/${id}`;
export const list_resource_types = (page, perPage = 10, search = '') =>
    `/api/resource-type/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_resource_type = `/api/resource-type/create`;
export const update_resource_type = (id) => `/api/resource-type/update/${id}`;
export const get_single_resource_type = (id) => `/api/resource-type/detail/${id}`;
export const get_client_resources = (id) => `/api/resource/list?client_id=${id}`;
export const download_resource = (id) => `/api/resource/download-resource/${id}`;
export const reassign = `/api/client/reassign`;
export const list_client_payments = `/api/payment/list`;
export const create_client_payment = `/api/payment/create`;
export const deactivate_client_or_prospect = (id) => `/api/client/deactivate/${id}`;
export const list_crisp_emails = (page, perPage = 10, search) =>
    `/api/crisp/list-emails?per_page=${perPage}&page=${page}&search=${search}`;
export const post_crisp_email = `/api/crisp/create-emails`;
export const get_single_crisp_email = (id) => `/api/crisp/crisp-email-detail/${id}`;
export const update_crisp_email = (id) => `/api/crisp/update-emails/${id}`;
export const deactivate_crisp_email = (id) => `/api/crisp/deactivate/${id}`;
export const get_client_payment_plans = (id, perPage, page) =>
    `/api/payment-plan/list/${id}?per_page=${perPage}&page=${page}`;
export const post_payment_plan = `/api/payment-plan/create/`;
export const update_payment_plan_detail = (id) => `/api/payment-plan-details/update/${id}`;
export const post_payment_plan_detail = `/api/payment-plan-details/create/`;
export const send_payment_plan_for_approval = (id) => `/api/payment-plan/seek-approval/${id}`;
export const list_payment_plans = (page, perPage) =>
    `/api/payment-plan/list-all-payment-plans?per_page=${perPage}&page=${page}`;
export const get_single_client_payment_plan = (id) => `/api/payment-plan/detail/${id}`;
export const list_payment_plan_statuses = `/api/payment-plan/payment-plan-approval-status`;
export const approve_or_reject_payment_plan = (id) => `/api/payment-plan/payment-plan-approval/${id}`;
export const list_payment_types = (page, perPage = 10, search = '') =>
    `/api/payment-type/list?per_page=${perPage}&page=${page}&search=${search}`;
export const post_payment_type = `/api/payment-type/create`;
export const get_single_payment_type = (id) => `/api/payment-type/detail/${id}`;
export const update_payment_type = (id) => `/api/payment-type/update/${id}`;
export const get_client_payments_per_product = (id) => `/api/payment/list/${id}`;
export const post_payment = `/api/payment/create`;
export const list_all_payments = (page, perPage = 10, search = '') =>
    `/api/payment/awaiting-approval-payments-list?per_page=${perPage}&page=${page}&search=${search}`;
export const get_single_payment = (id) => `/api/payment/detail/${id}`;
export const confirm_payment = (id) => `/api/payment/confirm-payment/${id}`;
export const reject_payment = (id) => `/api/payment/reject/${id}`;
export const delete_payment = (id) => `/api/payment/delete/${id}`;
export const download_leads_sample_excel = `/api/prospect/excel-download`;
export const upload_leads = `/api/prospect/temp-prospect-upload`;
export const list_leads = (page, perPage = 10, search = '') =>
    `/api/prospect/temp-prospect-list?per_page=${perPage}&page=${page}&search=${search}`;
export const get_transaction_types = `api/transaction-type/list`;
export const get_upcoming_payments = (page, perPage = 10) =>
    `/api/payment/get-upcoming?approval_status=0&per_page=${perPage}&page=${page}`;
export const approve_upcoming_payments = (id) => `/api/payment/approve-upcoming/${id}`;
export const generate_statements = `/api/payment/create-upcoming`;
export const list_statements = (page, perPage = 10, search = '') =>
    `/api/payment/get-monthly-statement?per_page=${perPage}&page=${page}&search=${search}`;
export const get_single_statement = (clientProductId, upcomingId) =>
    `/api/payment/get-monthly-statement/${clientProductId}/${upcomingId}`;
export const send_statement_to_client = (clientProductId) => `/api/payment/send-monthly-statement/${clientProductId}`;
export const send_monthly_statement_for_approval = (id) => `/api/payment/send-statement-for-approval/${id}`;
export const approve_monthly_statement = (id) => `/api/payment/approve-upcoming/${id}`;
export const reject_monthly_statement = (id) => `/api/payment/reject-statement/${id}`;

export const list_reassign_requests = (page, perPage = 10) =>
    `/api/client/reassignment-list?per_page=${perPage}&page=${page}`;

export const update_reassignment = (id: string) => `api/client/${id}/reassignment-update`;
