import { css } from 'styled-components';
import { DEVICE } from '../../../config/theme';

const style = ({ image }) => css`
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100vh;

    .image-container {
        display: none;
        background-image: url(${image});
        background-position: center;
        background-size: cover;
    }
    @media ${DEVICE.tablet} {
        .image-container {
            display: flex;
            flex: 1;
        }
    }

    @media ${DEVICE.laptopL} {
        .image-container {
            display: flex;
            flex: 2;
        }
    }
    .content-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export default style;
