import React, { useState } from 'react';
import { Modal, Row, Col, Form, Input, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SelectEmailTemplate from '../../Selects/EmailTemplate';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { send_email } from '../../apis';

interface Props {
    visible: boolean;
    client: {
        id: string;
        email: string;
    };
    userEmail: string;
    onClose: (success: boolean) => void;
}

const EmailClient = ({ visible, onClose, client, userEmail }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<any>();
    const [ckeditorData, setCkeditorData] = useState<any>();
    const [template, setTemplate] = useState<any>();

    const onFinish = (values) => {
        delete values.email_template_body;
        values['client_id'] = client.id.toString();
        values['email_to'] = client.email;
        values['email_from'] = userEmail;
        values['email_body'] = ckeditorData;
        setLoading(true);
        authenticatedRequest()
            .post(send_email, values)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onEmailTemplateChanged = (template) => {
        setTemplate(template);
        setCkeditorData(template.body);
        form.setFieldsValue({
            email_subject: template.subject,
        });
    };

    return (
        <Modal
            visible={visible}
            title="Email Prospect"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FloatingLabel label="Email Template" form={form} formItemName="email_template_id">
                            <SelectEmailTemplate onChange={(template) => onEmailTemplateChanged(template)} />
                        </FloatingLabel>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FloatingLabel
                            label="Subject"
                            form={form}
                            formItemName="email_subject"
                            classLabel="label label-float"
                        >
                            <Form.Item
                                name="email_subject"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input email subject',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                            name="email_template_body"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input email body',
                                },
                            ]}
                        >
                            <CKEditor
                                editor={ClassicEditor}
                                data={template?.body}
                                onBlur={(event, editor) => {
                                    setCkeditorData(editor.getData());
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Send" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EmailClient;
