import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { ROUTES } from '../../../Router/urls';
import { RiDeleteBin5Line, RiEyeLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { delete_payment, list_all_payments } from '../../../components/apis';
import { Divider, notification, Tag } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import moment from 'moment';
import { replaceUrlParams } from '../../../components/Functions';
import WarningModal from '../../../components/modals/success/WarningModal';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Amount Paid',
        dataIndex: 'payment_amount',
        render: (value) => numeral(value).format('0,0.00'),
    },
    {
        title: 'Payment Date',
        dataIndex: 'payment_date',
        render: (value) => moment(value).format('DD MMM YYYY'),
    },
    {
        title: 'Payment Type',
        dataIndex: 'payment_type_name',
    },
    {
        title: 'Status',
        dataIndex: 'is_approved',
        // eslint-disable-next-line react/display-name
        render: (value, record) => {
            if (value === 1) {
                return <Tag color="success">Approved</Tag>;
            }

            if (record?.is_rejected) {
                return <Tag color="error">Rejected</Tag>;
            }

            return <Tag color="warning">Awaiting Approval</Tag>;
        },
    },
    {
        title: 'Approved By',
        dataIndex: 'is_approved_by',
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Created On',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
];

const ListPayments = () => {
    const history = useHistory();
    const [payments, setPayments] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [showDeletePayment, setShowDeletePayment] = useState<boolean>(false);
    const [isDeletingPayment, setIsDeletingPayment] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<{ id: string } | null>(null);

    const goToCreateProduct = () => {
        history.push(ROUTES.CREATE_PRODUCT);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchPayments(1, 10, value);
    };

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <>
                        <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />
                        <Divider type="vertical" />
                        <RiDeleteBin5Line
                            color="red"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowDeletePayment(true);
                                setSelectedPayment(record);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const onDeletePayment = () => {
        setIsDeletingPayment(true);
        authenticatedRequest()
            .get(delete_payment(selectedPayment?.id))
            .then(() => {
                fetchPayments();
                setShowDeletePayment(false);
                setIsDeletingPayment(false);
            })
            .catch((error) => {
                setShowDeletePayment(false);
                setIsDeletingPayment(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };
    const viewPayment = (record) => {
        history.push(replaceUrlParams(ROUTES.VIEW_PAYMENT, [{ param: ':id', value: record?.id }]));
    };

    const fetchPayments = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_all_payments(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setPayments(res.data.payments?.data);
                setTotal(res.data.payments?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchPayments();
    }, []);

    return (
        <AuthenticatedLayout title="Payments">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }} />
            <WarningModal
                visible={showDeletePayment}
                title="Delete Payment"
                description={`Are you sure you want to delete this payment`}
                onClose={() => setShowDeletePayment(false)}
                onComplete={onDeletePayment}
                onCompleteLoading={isDeletingPayment}
            />
            <Card
                title={`Payments (${total})`}
                action={
                    <TableSearch
                        placeholder="Search payments"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchPayments(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchPayments(page, pageSize)}
                    total={total}
                    columns={newColumns}
                    data={payments}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => viewPayment(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_LIST'],
})(ListPayments);
