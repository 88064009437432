import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import {
    get_single_payment_type,
    get_transaction_types,
    post_payment_type,
    update_payment_type,
} from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';

const CreatePaymentType = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [transactionTypes, setTransactionTypes] = useState<[]>([]);
    const [form] = Form.useForm();
    const { id } = useParams();

    const onFinish = (values) => {
        setLoading(true);
        if (id) {
            updatePaymentType(values);
        } else {
            createPaymentType(values);
        }
    };

    const updatePaymentType = (values) => {
        authenticatedRequest()
            .post(update_payment_type(id), values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const createPaymentType = (values) => {
        authenticatedRequest()
            .post(post_payment_type, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchPaymentType = () => {
        authenticatedRequest()
            .get(get_single_payment_type(id))
            .then((res) => {
                const paymentType = res?.data?.payment_type?.[0];
                form.setFieldsValue({
                    payment_type_name: paymentType.payment_type_name,
                    transaction_type_id: paymentType.transaction_type_id,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchTransactionTypes = () => {
        authenticatedRequest()
            .get(get_transaction_types)
            .then((res) => {
                const types = res?.data?.transaction_type?.map((transactionType) => {
                    return {
                        label: transactionType.transaction_type_name,
                        value: transactionType.id,
                    };
                });
                console.log(types);
                setTransactionTypes(types);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.PAYMENT_TYPES);
    };

    useEffect(() => {
        if (id) {
            fetchPaymentType();
        }
        fetchTransactionTypes();
    }, []);

    return (
        <AuthenticatedLayout title="Payment Type" titleTwo="New Payment Type">
            <Col xs={24} sm={24} md={14} lg={24} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={id ? 'Payment Type updated' : 'New Payment Type Added'}
                            description={`Payment Type ${form.getFieldValue('payment_type_name')} was ${
                                id ? 'updated' : 'added'
                            } successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Payment Type Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Name"
                                        form={form}
                                        formItemName="payment_type_name"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="payment_type_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input payment type name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Transaction Type"
                                        form={form}
                                        classLabel={id ? 'label label-float' : 'label'}
                                        formItemName="transaction_type_id"
                                    >
                                        <Form.Item
                                            name="transaction_type_id"
                                            rules={[{ required: true, message: 'Please select a transaction type' }]}
                                        >
                                            <Select style={{ width: '100%' }} options={transactionTypes} />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={id ? `Update Payment Type` : `Save Payment Type`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_TYPE_CREATE'],
})(CreatePaymentType);
