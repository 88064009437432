import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_payment_types } from '../../apis';

const SelectPaymentType = () => {
    const [paymentTypes, setPaymentTypes] = useState<any>([]);

    const fetchResourceTypes = () => {
        authenticatedRequest()
            .get(list_payment_types(1, 100))
            .then((res) => {
                const paymentTypes = res.data.payment_types?.data.map((paymentType) => {
                    return {
                        value: paymentType.id,
                        label: paymentType.payment_type_name,
                    };
                });
                setPaymentTypes(paymentTypes);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchResourceTypes();
    }, []);

    return (
        <Form.Item
            name="payment_type_id"
            rules={[
                {
                    required: true,
                    message: 'Please select a payment type',
                },
            ]}
        >
            <Select allowClear showSearch optionFilterProp="label" options={paymentTypes} />
        </Form.Item>
    );
};

export default SelectPaymentType;
