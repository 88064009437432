import { css } from 'styled-components';

const style = ({ collapsed }) => css`
    .superior-homes-sider {
        flex: 0 0 ${collapsed ? '80px' : '210px'} !important;
        max-width: ${collapsed ? '80px' : '210px'} !important;
        min-width: ${collapsed ? '80px' : '210px'} !important;
        width: ${collapsed ? '80px' : '210px'} !important;
        background-color: #ffffff;
        .menu-container {
            padding: ${collapsed ? '0px' : '0 20px'};
            transition: ease 0.3s;
        }
        .logo-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            margin: 10px 0px 10px 0px;
        }
    }

    .ant-layout-sider-trigger {
        width: ${collapsed ? '80px' : '210px'} !important;
        background-color: #005a87;
    }
    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: none;
    }

    .ant-menu-submenu-title {
        height: 40px;
        display: flex;
        align-items: center;
        color: #404040;
    }

    .ant-menu-item {
        height: 40px;
        display: flex;
        align-items: center;
        color: #404040;
    }

    .ant-menu-sub.ant-menu-inline {
        background-color: transparent;
    }

    .ant-menu-item:hover {
        color: #005a87;
    }

    .ant-menu-item-selected {
        background: rgba(0, 90, 135, 0.2) !important;
        border-radius: 5px !important;
        color: #005a87 !important;
        font-weight: bold;
        line-height: 20px;
    }

    .ant-menu-item::after {
        border-right: none;
    }
`;

export default style;
