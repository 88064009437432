import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, DatePicker, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import PhoneNumber from '../../../components/PhoneNumber';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import { post_clients, request_client_reassign } from '../../../components/apis';
import { ROUTES } from '../../../Router/urls';
import SuccessModal from '../../../components/modals/success';
import SelectSource from '../../../components/Selects/Source';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import SelectProduct from '../../../components/Selects/Product';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { disableFutureDate, mapErrorToFields } from '../../../components/Functions';
import SelectHasSiteVisit from '../../../components/Selects/SiteVisit';
import FormUploads from '../../../components/FormUploads';
import SelectResourceType from '../../../components/Selects/ResourceType';
import SelectPaymentType from '../../../components/Selects/PaymentType';
import WarningModal from '../../../components/modals/success/WarningModal';

const CreateClient = () => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const [form] = Form.useForm();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [showReassignWarning, setShowReassignWarning] = useState<boolean>(false);
    const [file, setFile] = useState('');
    const [selectedSource, setSelectedSource] = useState<any>({});
    const [email, setEmail] = useState<string | null>(null);
    const [requestingReassign, setRequestingReassign] = useState<boolean>(false);

    const onRequestReassign = () => {
        setRequestingReassign(true);
        authenticatedRequest()
            .post(request_client_reassign, {
                email,
            })
            .then(() => {
                setRequestingReassign(false);
                setShowReassignWarning(false);
                notification.success({
                    message: 'Success',
                    description: 'Successfully asked for the client to be reassigned to you.',
                });
                history.push(ROUTES.CLIENTS);
            })
            .catch(() => {
                setRequestingReassign(false);
                setShowReassignWarning(false);
                notification.error({
                    message: 'Error',
                    description: 'Enable to complete request',
                });
            });
    };
    const onFinish = (values) => {
        setLoading(true);
        setEmail(values.email);
        const formData = new FormData();
        formData.append('relationship_manager_id', auth?.user?.id);
        formData.append('name', values.name);
        formData.append('email', values.email);
        formData.append('phone', values.phone);
        formData.append('alternate_email', values?.alternate_email);
        formData.append('alternate_name', values?.alternate_name);
        formData.append('alternate_phone', values?.alternate_phone);
        formData.append('has_site_visit', values?.has_site_visit);
        formData.append('source_id', values?.source_id);
        formData.append('product_id', values?.product_id);
        formData.append('house_number', values?.house_number);
        formData.append('payment_date', values?.payment_date);
        formData.append('price', values?.price);
        formData.append('payment_amount', values?.payment_amount);
        formData.append('is_paying', '1');
        formData.append('payment_type_id', values?.payment_type_id);
        formData.append('resource_type_id', values?.resource_type_id);
        formData.append('resource_file', file);
        formData.append('resource_title', 'Test');

        authenticatedRequest()
            .post(post_clients, formData)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.errors);
                if (formErrors) {
                    form.setFields(formErrors);
                }

                if (formErrors.findIndex((error) => error.name === 'email' || error.name === 'phone') !== -1) {
                    setShowReassignWarning(true);
                }

                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.CLIENTS);
    };

    return (
        <AuthenticatedLayout title="Clients" titleTwo="New Client">
            <SuccessModal
                visible={visible}
                title="Client Added"
                description={`Client ${form.getFieldValue('name')} was added successfully`}
                onClose={onComplete}
                onComplete={onComplete}
            />
            <WarningModal
                visible={showReassignWarning}
                title="Client already exists"
                description="Client already exists. However, you can put in a request to the admin for the client to reassigned to you"
                onClose={() => {
                    setShowReassignWarning(false);
                }}
                onComplete={() => {
                    onRequestReassign();
                }}
                onCompleteLoading={requestingReassign}
                completeButton="Reassign"
                showCancelButton={true}
            />
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <Form
                            onFinish={onFinish}
                            form={form}
                            layout="horizontal"
                            style={{ width: '100%' }}
                            scrollToFirstError={true}
                            initialValues={{ products: [''] }}
                            preserve
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Client Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Client Name" form={form} formItemName="name">
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input clients name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Email" form={form} formItemName="email">
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email',
                                                    message: 'Please input clients email',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <PhoneNumber
                                        form={form}
                                        required={true}
                                        message="Please input clients phone number"
                                        formItemName="phone"
                                    />
                                </Col>

                                <Col span={24}>
                                    <SectionDivider title="Second Purchaser Details:" />
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Name" form={form} formItemName="alternate_name">
                                        <Form.Item
                                            name="alternate_name"
                                            dependencies={['alternate_email']}
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Please input alternate name',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            value &&
                                                            !getFieldValue('alternate_email') &&
                                                            !getFieldValue('alternate_phone')
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Please input alternate email or alternate phone',
                                                                ),
                                                            );
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Email" form={form} formItemName="alternate_email">
                                        <Form.Item
                                            name="alternate_email"
                                            dependencies={['alternate_name']}
                                            rules={[
                                                {
                                                    required: false,
                                                    type: 'email',
                                                    message: 'Input should be an email',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (value && !getFieldValue('alternate_name')) {
                                                            return Promise.reject(
                                                                new Error('Please input alternate name'),
                                                            );
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <PhoneNumber
                                        form={form}
                                        required={false}
                                        message="Please input next of alternate contact's phone number"
                                        formItemName="alternate_phone"
                                    />
                                </Col>

                                <Col span={24}>
                                    <SectionDivider title="Source & Product Details:" />
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Source" form={form} formItemName="source_id">
                                        <SelectSource onChange={(source) => setSelectedSource(source)} />
                                    </FloatingLabel>
                                </Col>

                                {selectedSource.hasSiteVisit ? (
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <FloatingLabel
                                            label="Was a site visit"
                                            form={form}
                                            formItemName="has_site_visit"
                                        >
                                            <SelectHasSiteVisit formItemName="has_site_visit" />
                                        </FloatingLabel>
                                    </Col>
                                ) : null}

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Product" form={form} formItemName="product_id">
                                        <SelectProduct
                                            isFormList={true}
                                            name={'product_id'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select a product',
                                                },
                                            ]}
                                        />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="House No." form={form} formItemName="house_number">
                                        <Form.Item
                                            name={'house_number'}
                                            fieldKey={'house_number'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please house number',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Purchase Date" form={form} formItemName="payment_date">
                                        <Form.Item
                                            name={'payment_date'}
                                            fieldKey={'payment_date'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select purchase date',
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                disabledDate={disableFutureDate}
                                                placeholder=""
                                            />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Total Price" form={form} formItemName="price">
                                        <Form.Item
                                            name={'price'}
                                            fieldKey={'price'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input the total price',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            parseInt(value) < parseInt(getFieldValue(`payment_amount`))
                                                        ) {
                                                            return Promise.reject(
                                                                new Error(
                                                                    'Total price can not be less than amount paid',
                                                                ),
                                                            );
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input type="number" min={1} />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel label="Amount Paid" form={form} formItemName="payment_amount">
                                        <Form.Item
                                            name={'payment_amount'}
                                            fieldKey={'payment_amount'}
                                            dependencies={['products', 'price']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input the amount paid',
                                                },
                                            ]}
                                        >
                                            <Input type="number" min={1} />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                {/*<Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*    <FloatingLabel label="Is Paying" form={form} formItemName="is_paying">*/}
                                {/*        <Form.Item*/}
                                {/*            name={'is_paying'}*/}
                                {/*            fieldKey={'is_paying'}*/}
                                {/*            rules={[*/}
                                {/*                {*/}
                                {/*                    required: true,*/}
                                {/*                    message: 'Please input the amount paid',*/}
                                {/*                },*/}
                                {/*            ]}*/}
                                {/*        >*/}
                                {/*            <Select onSelect={(value) => setIsPaying(value)}>*/}
                                {/*                <Select.Option value={1}>Yes</Select.Option>*/}
                                {/*                <Select.Option value={0}>No</Select.Option>*/}
                                {/*            </Select>*/}
                                {/*        </Form.Item>*/}
                                {/*    </FloatingLabel>*/}
                                {/*</Col>*/}

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Resource Type"
                                        form={form}
                                        formItemName="resource_type_id"
                                        classLabel="label"
                                    >
                                        <SelectResourceType formItemName={'resource_type_id'} />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Payment Type"
                                        form={form}
                                        classLabel="label"
                                        formItemName="payment_type_id"
                                    >
                                        <SelectPaymentType />
                                    </FloatingLabel>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FormUploads
                                        formItemName={'resource_file'}
                                        label="File"
                                        manualUpload={true}
                                        setFile={setFile}
                                    />
                                </Col>
                                {/*<Form.List*/}
                                {/*    name="products"*/}
                                {/*    rules={[*/}
                                {/*        {*/}
                                {/*            validator: async (_, names) => {*/}
                                {/*                if (!names || names.length < 1) {*/}
                                {/*                    return Promise.reject(new Error('Add at least 1 product'));*/}
                                {/*                }*/}
                                {/*            },*/}
                                {/*        },*/}
                                {/*    ]}*/}
                                {/*>*/}
                                {/*    {(fields, { add, remove }) => (*/}
                                {/*        <>*/}
                                {/*            {fields.map((field, index) => (*/}
                                {/*                <>*/}
                                {/*                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*                        <FloatingLabel*/}
                                {/*                            label="Product"*/}
                                {/*                            form={form}*/}
                                {/*                            formItemName="product_id"*/}
                                {/*                            formListName="products"*/}
                                {/*                            formFieldIndex={index}*/}
                                {/*                            isFormList={true}*/}
                                {/*                        >*/}
                                {/*                            <SelectProduct*/}
                                {/*                                isFormList={true}*/}
                                {/*                                field={field}*/}
                                {/*                                name={[field.name, 'product_id']}*/}
                                {/*                                fieldKey={[field.fieldKey, 'product_id']}*/}
                                {/*                                rules={[*/}
                                {/*                                    {*/}
                                {/*                                        required: true,*/}
                                {/*                                        message: 'Please select a product',*/}
                                {/*                                    },*/}
                                {/*                                ]}*/}
                                {/*                            />*/}
                                {/*                        </FloatingLabel>*/}
                                {/*                    </Col>*/}

                                {/*                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*                        <FloatingLabel*/}
                                {/*                            label="House No."*/}
                                {/*                            form={form}*/}
                                {/*                            formListName="products"*/}
                                {/*                            formFieldIndex={index}*/}
                                {/*                            isFormList={true}*/}
                                {/*                            formItemName="house_number"*/}
                                {/*                        >*/}
                                {/*                            <Form.Item*/}
                                {/*                                {...field}*/}
                                {/*                                name={[field.name, 'house_number']}*/}
                                {/*                                fieldKey={[field.fieldKey, 'house_number']}*/}
                                {/*                                rules={[*/}
                                {/*                                    {*/}
                                {/*                                        required: true,*/}
                                {/*                                        message: 'Please house number',*/}
                                {/*                                    },*/}
                                {/*                                ]}*/}
                                {/*                            >*/}
                                {/*                                <Input />*/}
                                {/*                            </Form.Item>*/}
                                {/*                        </FloatingLabel>*/}
                                {/*                    </Col>*/}

                                {/*                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*                        <FloatingLabel*/}
                                {/*                            label="Purchase Date"*/}
                                {/*                            form={form}*/}
                                {/*                            formItemName="payment_date"*/}
                                {/*                            isFormList={true}*/}
                                {/*                            formListName="products"*/}
                                {/*                            formFieldIndex={index}*/}
                                {/*                        >*/}
                                {/*                            <Form.Item*/}
                                {/*                                {...field}*/}
                                {/*                                name={[field.name, 'payment_date']}*/}
                                {/*                                fieldKey={[field.fieldKey, 'payment_date']}*/}
                                {/*                                rules={[*/}
                                {/*                                    {*/}
                                {/*                                        required: true,*/}
                                {/*                                        message: 'Please select purchase date',*/}
                                {/*                                    },*/}
                                {/*                                ]}*/}
                                {/*                            >*/}
                                {/*                                <DatePicker*/}
                                {/*                                    style={{ width: '100%' }}*/}
                                {/*                                    disabledDate={disableFutureDate}*/}
                                {/*                                    placeholder=""*/}
                                {/*                                />*/}
                                {/*                            </Form.Item>*/}
                                {/*                        </FloatingLabel>*/}
                                {/*                    </Col>*/}

                                {/*                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*                        <FloatingLabel*/}
                                {/*                            label="Total Price"*/}
                                {/*                            form={form}*/}
                                {/*                            formItemName="price"*/}
                                {/*                            isFormList={true}*/}
                                {/*                            formListName="products"*/}
                                {/*                            formFieldIndex={index}*/}
                                {/*                        >*/}
                                {/*                            <Form.Item*/}
                                {/*                                {...field}*/}
                                {/*                                name={[field.name, 'price']}*/}
                                {/*                                fieldKey={[field.fieldKey, 'price']}*/}
                                {/*                                dependencies={['products', field.key, 'payment_amount']}*/}
                                {/*                                rules={[*/}
                                {/*                                    {*/}
                                {/*                                        required: true,*/}
                                {/*                                        message: 'Please input the total price',*/}
                                {/*                                    },*/}
                                {/*                                    ({ getFieldValue }) => ({*/}
                                {/*                                        validator(_, value) {*/}
                                {/*                                            if (*/}
                                {/*                                                parseInt(value) <*/}
                                {/*                                                parseInt(*/}
                                {/*                                                    getFieldValue(*/}
                                {/*                                                        `products_${field.key}_payment_amount`,*/}
                                {/*                                                    ),*/}
                                {/*                                                )*/}
                                {/*                                            ) {*/}
                                {/*                                                return Promise.reject(*/}
                                {/*                                                    new Error(*/}
                                {/*                                                        'Total price can not be less than amount paid',*/}
                                {/*                                                    ),*/}
                                {/*                                                );*/}
                                {/*                                            }*/}

                                {/*                                            return Promise.resolve();*/}
                                {/*                                        },*/}
                                {/*                                    }),*/}
                                {/*                                ]}*/}
                                {/*                            >*/}
                                {/*                                <Input type="number" min={1} />*/}
                                {/*                            </Form.Item>*/}
                                {/*                        </FloatingLabel>*/}
                                {/*                    </Col>*/}

                                {/*                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*                        <FloatingLabel*/}
                                {/*                            label="Amount Paid"*/}
                                {/*                            form={form}*/}
                                {/*                            formItemName="payment_amount"*/}
                                {/*                            isFormList={true}*/}
                                {/*                            formListName="products"*/}
                                {/*                            formFieldIndex={index}*/}
                                {/*                        >*/}
                                {/*                            <Form.Item*/}
                                {/*                                {...field}*/}
                                {/*                                name={[field.name, 'payment_amount']}*/}
                                {/*                                fieldKey={[field.fieldKey, 'payment_amount']}*/}
                                {/*                                dependencies={['products', field.key, 'price']}*/}
                                {/*                                rules={[*/}
                                {/*                                    {*/}
                                {/*                                        required: true,*/}
                                {/*                                        message: 'Please input the amount paid',*/}
                                {/*                                    },*/}
                                {/*                                ]}*/}
                                {/*                            >*/}
                                {/*                                <Input type="number" min={1} />*/}
                                {/*                            </Form.Item>*/}
                                {/*                        </FloatingLabel>*/}
                                {/*                    </Col>*/}

                                {/*                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>*/}
                                {/*                        <FloatingLabel*/}
                                {/*                            label="Is Paying"*/}
                                {/*                            form={form}*/}
                                {/*                            formItemName="is_paying"*/}
                                {/*                            isFormList={true}*/}
                                {/*                            formListName="products"*/}
                                {/*                            formFieldIndex={index}*/}
                                {/*                        >*/}
                                {/*                            <Form.Item*/}
                                {/*                                {...field}*/}
                                {/*                                name={[field.name, 'is_paying']}*/}
                                {/*                                fieldKey={[field.fieldKey, 'is_paying']}*/}
                                {/*                                rules={[*/}
                                {/*                                    {*/}
                                {/*                                        required: true,*/}
                                {/*                                        message: 'Please input the amount paid',*/}
                                {/*                                    },*/}
                                {/*                                ]}*/}
                                {/*                            >*/}
                                {/*                                <Select onSelect={(value) => setIsPaying(value)}>*/}
                                {/*                                    <Select.Option value={1}>Yes</Select.Option>*/}
                                {/*                                    <Select.Option value={0}>No</Select.Option>*/}
                                {/*                                </Select>*/}
                                {/*                            </Form.Item>*/}
                                {/*                        </FloatingLabel>*/}
                                {/*                    </Col>*/}

                                {/*                    {isPaying ? (*/}
                                {/*                        <>*/}
                                {/*                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>*/}
                                {/*                                <FloatingLabel*/}
                                {/*                                    label="Resource Type"*/}
                                {/*                                    form={form}*/}
                                {/*                                    formItemName="resource_type_id"*/}
                                {/*                                    classLabel="label"*/}
                                {/*                                >*/}
                                {/*                                    <SelectResourceType*/}
                                {/*                                        formItemName={[index, 'resource_type_id']}*/}
                                {/*                                        {...field}*/}
                                {/*                                    />*/}
                                {/*                                </FloatingLabel>*/}
                                {/*                            </Col>*/}
                                {/*                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                {/*                                <FormUploads*/}
                                {/*                                    {...field}*/}
                                {/*                                    formItemName={[index, 'resource_file']}*/}
                                {/*                                    label="File"*/}
                                {/*                                    manualUpload={true}*/}
                                {/*                                    isFormEnabled*/}
                                {/*                                />*/}
                                {/*                            </Col>*/}
                                {/*                        </>*/}
                                {/*                    ) : null}*/}

                                {/*                    {fields.length > 1 ? (*/}
                                {/*                        <div style={{ position: 'absolute' }}>*/}
                                {/*                            <Button*/}
                                {/*                                type="link"*/}
                                {/*                                onClick={() => remove(field.name)}*/}
                                {/*                                style={{*/}
                                {/*                                    display: 'flex',*/}
                                {/*                                    alignItems: 'center',*/}
                                {/*                                    color: 'red',*/}
                                {/*                                }}*/}
                                {/*                                icon={<RiDeleteBin6Fill />}*/}
                                {/*                            >*/}
                                {/*                                &nbsp;&nbsp;Delete*/}
                                {/*                            </Button>*/}
                                {/*                        </div>*/}
                                {/*                    ) : null}*/}
                                {/*                    <Divider*/}
                                {/*                        type="horizontal"*/}
                                {/*                        style={{ marginBottom: 10, marginTop: -10 }}*/}
                                {/*                    />*/}
                                {/*                </>*/}
                                {/*            ))}*/}
                                {/*            <Form.Item>*/}
                                {/*                <Button*/}
                                {/*                    type="link"*/}
                                {/*                    style={{*/}
                                {/*                        display: 'flex',*/}
                                {/*                        alignItems: 'center',*/}
                                {/*                    }}*/}
                                {/*                    onClick={() => add()}*/}
                                {/*                    icon={<RiAddFill />}*/}
                                {/*                >*/}
                                {/*                    &nbsp;&nbsp;Add Product*/}
                                {/*                </Button>*/}
                                {/*            </Form.Item>*/}
                                {/*        </>*/}
                                {/*    )}*/}
                                {/*</Form.List>*/}

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName="Save Client"
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['CLIENT_CREATE'],
})(CreateClient);
