import React from 'react';
import { Input, Form, FormInstance } from 'antd';
import styled from 'styled-components';
import style from './styles';
import FloatingLabel from '../FloatingLabel';
const PhoneNumberWrapper = styled.div`
    ${style}
`;

interface Props {
    required: boolean;
    message: string;
    formItemName: string;
    formLabelName?: string;
    form: FormInstance;
}
const PhoneNumber = ({ required, formItemName, formLabelName = 'Phone Number', message, form }: Props) => {
    return (
        <PhoneNumberWrapper>
            <FloatingLabel form={form} label={formLabelName} formItemName={formItemName}>
                <Form.Item
                    name={formItemName}
                    rules={[
                        {
                            required: required,
                            message: message,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </FloatingLabel>
        </PhoneNumberWrapper>
    );
};

export default PhoneNumber;
