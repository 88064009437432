import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiMailCheckLine, RiPencilLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification } from 'antd';
import { list_email_templates } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';

const columns = [
    {
        title: 'Name',
        dataIndex: 'email_template_name',
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm A'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const ListEmailTemplates = () => {
    const history = useHistory();
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const goToCreateEmailTemplate = () => {
        history.push(ROUTES.CREATE_EMAIL_TEMPLATE);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
        fetchEmailTemplates(1, 10, value);
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_EMAIL_TEMPLATE.split(':').shift()}${record.id}`);
    };

    const fetchEmailTemplates = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_email_templates(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setEmailTemplates(res.data.email_templates?.data);
                setTotal(res.data.email_templates?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchEmailTemplates();
    }, []);

    return (
        <AuthenticatedLayout title="Email Templates">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['DEPARTMENT_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Email Template"
                        icon={<RiMailCheckLine color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateEmailTemplate}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Email Templates (${emailTemplates.length})`}
                action={
                    <TableSearch
                        placeholder="Search email templates"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchEmailTemplates(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchEmailTemplates(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={emailTemplates}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => onRowClick(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['DEPARTMENT_LIST'],
})(ListEmailTemplates);
