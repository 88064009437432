import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RiCloseLine } from 'react-icons/ri';
import { Collapse, DatePicker, Divider, Form, Modal, Select, InputNumber, notification } from 'antd';
import { SuperiorHomesCancelButton, SuperiorHomesOrangeButton } from '../../Buttons';
import style from './styles';
import { Plus, X } from 'react-feather';
import { authenticatedRequest } from '../../../api';
import moment from 'moment';

const FilterWrapper = styled.div`
    ${style}
`;

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props {
    visible: boolean;
    filterOptions: any;
    onClose: () => void;
    onApply: (filterData) => void;
}

function resolve(path, obj = self, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

const convertDate = (date: string) => {
    return moment(date).format('YYYY-MM-DD');
};

const AutoCompleteWrapper = (props: any) => {
    const [options, setOptions] = useState<any[]>([]);

    const fetchOptions = (value) => {
        authenticatedRequest()
            .get(props?.api?.(value))
            .then((res) => res.data)
            .then((res) => {
                const dataArr = props?.searchResultItem ? resolve(props?.searchResultItem, res) : res;
                if (Array.isArray(dataArr) && dataArr.length > 0) {
                    setOptions(
                        [...dataArr].map((val) => {
                            return {
                                label: props?.valueProp ? resolve(props?.valueProp, val) : val?.value,
                                value: props?.idProp ? resolve(props?.idProp, val) : val?.id,
                            };
                        }),
                    );
                } else {
                    setOptions([]);
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.message || 'Oops! An unexpected error occured',
                });
            });
    };
    useEffect(() => {
        fetchOptions('');
    }, []);

    return (
        <Form.Item name={`${props?.type}:${props.dataIndex}`} noStyle>
            <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                optionFilterProp={props?.optionFilterProp}
                onSearch={(value) => fetchOptions(value)}
            >
                {options.map((option: { label: string; value: string }) => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

const Filter = ({ visible, onClose, filterOptions, onApply }: Props) => {
    const [form] = Form.useForm();
    const selectRef = useRef<any>(null);

    const onClear = () => {
        form.resetFields();
        onApply({});
        onClose();
    };

    const onFinish = (values) => {
        const filterByData = {};
        Object.keys(values).forEach((formValue) => {
            // Step 1: Split the value by :
            const splitValues = formValue.split(':');
            // Step 2: Get the first item in the array which is the type
            const type = splitValues[0];
            // Step 3: If type is multiple, add the value to the object without any processing
            if (type === 'multiple' && Array.isArray(values[formValue])) {
                const returnType = splitValues[2] ?? undefined;
                if (values[formValue].join(',') != '' && returnType == 'array') {
                    filterByData[splitValues[1]] = values[formValue];
                } else if (values[formValue].join(',') != '') {
                    filterByData[splitValues[1]] = values[formValue].join(',');
                }
            }
            // Step 4: If type is numberRange, get the last value in the split array, concatenate it with the value and colon then add it to the object
            if (type === 'numberRange' && values[formValue]) {
                filterByData[splitValues[1]] = values[formValue];
            }
            if (type === 'single' && values[formValue]) {
                filterByData[splitValues[1]] = values[formValue];
            }
            if (type == 'autocomplete' && values[formValue]) {
                filterByData[splitValues[1]] = values[formValue];
            }
            if (type == 'dateRange' && values[formValue]) {
                filterByData['start_date'] = convertDate(values[formValue][0]);
                filterByData['end_date'] = convertDate(values[formValue][1]);
            }
        });

        onApply(filterByData);
        onClose();
    };

    return (
        <Modal
            visible={visible}
            title="Filter"
            onCancel={() => onClose()}
            width="30%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <FilterWrapper>
                <Form form={form} onFinish={onFinish}>
                    <Collapse
                        accordion
                        ghost
                        className="accordion"
                        defaultActiveKey={[0]}
                        expandIconPosition={'right'}
                        destroyInactivePanel
                        expandIcon={({ isActive }) =>
                            !isActive ? <Plus size={28} rotate={90} className="plus" /> : <X size={28} rotate={90} />
                        }
                    >
                        {filterOptions.map((filterOption, index) => (
                            <Panel key={index} header={filterOption.name} forceRender>
                                <div ref={selectRef} className="option-container">
                                    {filterOption.type === 'multiple' ? (
                                        <Form.Item style={{ minWidth: 300, marginBottom: 0 }}>
                                            <Form.Item
                                                name={`${filterOption.type}:${filterOption.dataIndex}${
                                                    filterOption.returnType ? `:${filterOption.returnType}` : ''
                                                }`}
                                                noStyle
                                            >
                                                <Select
                                                    allowClear
                                                    options={filterOption.options}
                                                    showArrow={false}
                                                    dropdownStyle={{ minWidth: 200 }}
                                                    className={`select`}
                                                    mode="multiple"
                                                />
                                            </Form.Item>
                                        </Form.Item>
                                    ) : filterOption.type === 'single' ? (
                                        <Form.Item name={`${filterOption.type}:${filterOption.dataIndex}`} noStyle>
                                            <Select
                                                allowClear
                                                options={filterOption.options}
                                                showArrow={false}
                                                style={{ width: '100%' }}
                                                dropdownStyle={{ minWidth: 200 }}
                                                className={`select single`}
                                                data-testid="single_type"
                                            />
                                        </Form.Item>
                                    ) : filterOption.type === 'numberRange' ? (
                                        <>
                                            <Form.Item
                                                style={{ flex: 1, marginBottom: 0 }}
                                                name={`${filterOption.type}:${filterOption.dataIndex}From`}
                                            >
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                    className="gapstack-input"
                                                    placeholder="From"
                                                />
                                            </Form.Item>
                                            <span style={{ margin: '0px 10px' }}>To</span>
                                            <Form.Item
                                                style={{ flex: 1, marginBottom: 0 }}
                                                name={`${filterOption.type}:${filterOption.dataIndex}To`}
                                            >
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                    className="gapstack-input"
                                                    placeholder="To"
                                                />
                                            </Form.Item>
                                        </>
                                    ) : filterOption.type === 'autocomplete' ? (
                                        <AutoCompleteWrapper {...filterOption} />
                                    ) : filterOption.type === 'dateRange' ? (
                                        <>
                                            <Form.Item
                                                style={{ flex: 1, marginBottom: 0 }}
                                                name={`${filterOption.type}:${filterOption.dataIndex}`}
                                            >
                                                <RangePicker
                                                    style={{ width: '100%' }}
                                                    format="YYYY-MM-DD"
                                                    className="gapstack-input date-range-container"
                                                    data-testid="date-range"
                                                />
                                            </Form.Item>
                                        </>
                                    ) : null}
                                </div>
                            </Panel>
                        ))}
                    </Collapse>
                    <Divider type="vertical" />
                    <div className="button-container">
                        <SuperiorHomesCancelButton
                            buttonName="Clear"
                            onClick={() => onClear()}
                            style={{ marginRight: 10 }}
                        />
                        <SuperiorHomesOrangeButton buttonName="Filter" htmlType="submit" />
                    </div>
                </Form>
            </FilterWrapper>
        </Modal>
    );
};

export default Filter;
