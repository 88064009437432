import React, { useState } from 'react';
import { Modal, Row, Col, Form, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { reassign } from '../../apis';
import SelectUser from '../../Selects/User';

interface Props {
    visible: boolean;
    type: 'client' | 'prospect';
    currentUser: any;
    clients: Array<string>;
    onClose: (success: boolean) => void;
}

const ReassignClientsOrProspects = ({ visible, clients, currentUser, type, onClose }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        const x = clients.map((client) => {
            return {
                client_id: client,
                relationship_manager_id: values.relationship_manager_id,
            };
        });

        setLoading(true);
        values['client_manager'] = JSON.stringify(x);
        delete values.relationship_manager_id;
        authenticatedRequest()
            .post(reassign, values)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const cancel = () => {
        form.resetFields();
        onClose(false);
    };

    return (
        <Modal
            visible={visible}
            title="Reassign"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                    <div style={{ width: '100%' }}>
                        <p>
                            Reassign {clients.length} {type}(s) from {currentUser?.first_name} {currentUser?.last_name}
                            &nbsp;to
                        </p>
                    </div>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Users" form={form} formItemName="relationship_manager_id">
                            <SelectUser usersToDisable={[currentUser?.id]} />
                        </FloatingLabel>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => cancel()} />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Reassign" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ReassignClientsOrProspects;
