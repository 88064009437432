import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiUser2Fill, RiPencilLine, RiDownloadCloud2Fill } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { Divider, notification } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';
import { download_resource, list_resources } from '../../../components/apis';
import fileDownload from 'js-file-download';

const columns = [
    {
        title: 'Name',
        dataIndex: 'resource_title',
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
    {
        title: 'Resource Type',
        dataIndex: 'resource_type_name',
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
];

const ListResources = () => {
    const history = useHistory();
    const [resources, setResources] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const newDocumentColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <>
                        <RiDownloadCloud2Fill
                            color="#2F80ED"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                downloadClientDocument(record);
                            }}
                        />
                        <Divider type="vertical" />
                        <RiPencilLine
                            color="#2F80ED"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowClick(record);
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const goToCreateResource = () => {
        history.push(ROUTES.CREATE_RESOURCE);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
        fetchResources(1, 10, value);
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_RESOURCE.split(':').shift()}${record.id}`);
    };

    const fetchResources = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_resources(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setResources(res.data.resources?.data);
                setTotal(res.data.resources?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const downloadClientDocument = (record) => {
        authenticatedRequest()
            .get(download_resource(record?.id), {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, `${record.resource_title}.${record?.resource_extension_type}`);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchResources();
    }, []);

    return (
        <AuthenticatedLayout title="Resources">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['SOURCE_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Resource"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateResource}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Resource (${resources.length})`}
                action={
                    <TableSearch
                        placeholder="Search resource"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    total={total}
                    onPageChange={(page, pageSize) => fetchResources(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchResources(page, pageSize)}
                    columns={newDocumentColumns}
                    data={resources}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['RESOURCE_LIST'],
})(ListResources);
