import React from 'react';
import { Select, Form } from 'antd';

interface Props {
    formItemName: string;
    required?: boolean;
    message?: string;
}
const SelectHasSiteVisit = ({
    formItemName,
    required = true,
    message = 'Please select if the source has site visit',
}: Props) => {
    return (
        <Form.Item
            name={formItemName}
            rules={[
                {
                    required,
                    message,
                },
            ]}
        >
            <Select
                allowClear
                options={[
                    { label: 'Yes', value: 1 },
                    { label: 'No', value: 0 },
                ]}
            />
        </Form.Item>
    );
};

export default SelectHasSiteVisit;
