import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiPencilLine, RiUser2Fill } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification, Tag } from 'antd';
import { list_roles } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';

const columns = [
    {
        title: 'Name',
        dataIndex: ['role', 'name'],
    },
    {
        title: 'Permissions',
        dataIndex: 'permission_count',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <Tag color="success">Active</Tag>;
        },
    },
    {
        title: 'Date Created',
        dataIndex: ['role', 'date'],
        render: (val) => moment(val).format('DD MMMM YYYY HH:mm'),
    },
    {
        title: 'Created By',
        dataIndex: ['role', 'created_by_name'],
    },
];

const ListRoles = () => {
    const history = useHistory();
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: () => {
                return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
            },
        },
    ];

    const goToCreateRole = () => {
        history.push(ROUTES.CREATE_ROLE);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
    };

    const fetchRoles = () => {
        setLoading(true);
        authenticatedRequest()
            .get(list_roles)
            .then((res) => {
                setLoading(false);
                setRoles(res.data.roles);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_ROLE.split(':').shift()}${record.role.id}`);
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    return (
        <AuthenticatedLayout title="Roles">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['ROLE_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Role"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateRole}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Roles (${roles.length})`}
                action={
                    <TableSearch
                        placeholder="Search roles"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    columns={newColumns}
                    data={roles}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['ROLE_LIST'],
})(ListRoles);
