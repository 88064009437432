import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Input, notification, Spin } from 'antd';
import { RiEyeCloseLine, RiEyeLine, RiLockUnlockLine, RiMailOpenLine } from 'react-icons/ri';
import style from './styles';
import logo from '../../assets/images/logo.svg';
import { SuperiorHomesBlueButton } from '../../components/Buttons';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import { unAuthenticatedRequest } from '../../api';
import { get_activate_account, post_activate_account } from '../../components/apis';
import { ROUTES } from '../../Router/urls';
import { LoadingOutlined } from '@ant-design/icons';

const ActivateAccountWrapper = styled.div`
    ${style}
`;

const antIcon = <LoadingOutlined style={{ fontSize: 14, color: '#005A87' }} spin />;

const ActivateAccount = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [isActivatingAccount, setIsActivatingAccount] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [form] = Form.useForm();
    const { id } = useParams();

    const onFinish = (values) => {
        setIsActivatingAccount(true);
        values['registration_token'] = id;
        unAuthenticatedRequest()
            .post(post_activate_account, values)
            .then(() => {
                setIsActivatingAccount(false);
                notification.success({
                    message: 'Success',
                    description: 'Account activated successfully. Redirecting you to sign in',
                });

                history.push(ROUTES.SIGN_IN);
            })
            .catch((error) => {
                setIsActivatingAccount(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const getActivateAccount = () => {
        setLoading(true);
        unAuthenticatedRequest()
            .get(get_activate_account(id))
            .then((res) => {
                setLoading(false);
                setEmail(res.data?.email?.[0]?.email);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        getActivateAccount();
    }, []);

    return (
        <UnauthenticatedLayout>
            <ActivateAccountWrapper>
                <div className="logo-container">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="salutations-container">
                    <p className="salutation">Activate Account!</p>
                    <p className="description">Please fill in your password and confirm it to activate your account</p>
                    <div className="line" />
                </div>
                <Form onFinish={onFinish} form={form} style={{ width: '60%' }} layout="vertical" requiredMark={false}>
                    <Row>
                        <Col span={24} style={{ marginBottom: 10 }}>
                            <Input readOnly value={email} prefix={<RiMailOpenLine color="#C4C4C4" />} />
                            <Spin indicator={antIcon} spinning={loading} />
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Enter Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('The two passwords that you entered do not match!'),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Enter Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <SuperiorHomesBlueButton
                                buttonName="Submit"
                                htmlType="submit"
                                block
                                loading={isActivatingAccount}
                            />
                        </Col>
                    </Row>
                </Form>
            </ActivateAccountWrapper>
        </UnauthenticatedLayout>
    );
};

export default ActivateAccount;
