import { css } from 'styled-components';

const style = ({ width, validateStatus }) => {
    let backgroundColor = 'rgba(30, 135, 240, 0.2)';
    let color = '#0B95FA';
    let borderColor = '#1E87F0';
    let cloudBackgroundColor = 'rgba(30, 135, 240, 0.6)';
    switch (validateStatus) {
        case 'error':
            color = 'rgba(231, 76, 60, 0.6)';
            backgroundColor = 'rgba(231, 76, 60, 0.1)';
            borderColor = '#E74C3C';
            cloudBackgroundColor = 'rgba(231, 76, 60, 0.6)';
            break;
        default:
            backgroundColor = 'rgba(30, 135, 240, 0.2)';
            color = '#0B95FA';
            borderColor = '#1E87F0';
            cloudBackgroundColor = 'rgba(30, 135, 240, 0.6)';
    }

    return css`
        .download-documents {
            position: relative;
            background: ${backgroundColor};
            border: 1px dashed ${borderColor};
            box-sizing: border-box;
            border-radius: 5px;
            padding: 12px;
            margin: 10px 0px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${color};
            width: ${width || '365px'};
            max-width: 100%;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 85%;
                &.anticon {
                    margin-left: auto;
                    margin-right: 8px;
                    width: unset;
                }
            }
        }

        .download-documents:hover {
            cursor: pointer;
        }

        .upload-heading {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #39426a;
            margin-left: 8px;
            margin-top: 20px;
        }

        .cloud {
            position: absolute;
            right: 0px;
            padding: 12px;
            top: -1px;
            background: ${cloudBackgroundColor};
            height: 46px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-left: auto;
        }
        .name-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 5px;
            span {
                color: ${borderColor};
            }
            .download-icon {
                width: 18px;
                color: #39426a;
                margin-left: auto;
                cursor: pointer;
            }
            .vertical-line {
                width: 2px;
                height: 15px;
                background: #e5e5e5;
                border-radius: 2px;
                margin: 0px 5px;
            }
            .del-icon {
                width: 18px;
                color: #e74c3c;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        .err-messsage {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #e74c3c;
        }
    `;
};

export default style;
