import React, { ReactNode } from 'react';
import styled from 'styled-components';
import style from './styles';
import SuperiorHomes from '../../assets/images/superior-homes.jpg';

const UnauthenticatedLayoutWrapper = styled.div`
    ${style}
`;

interface UnauthenticatedLayoutProps {
    children: ReactNode;
}
const UnauthenticatedLayout = ({ children }: UnauthenticatedLayoutProps) => {
    return (
        <UnauthenticatedLayoutWrapper image={SuperiorHomes}>
            <div className="image-container" />
            <div className="content-container">{children}</div>
        </UnauthenticatedLayoutWrapper>
    );
};

export default UnauthenticatedLayout;
