import React from 'react';
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import style from './styles';
const ChartWrapper = styled.div`
    ${style}
`;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderLegend = ({ payload }: any) => {
    return (
        <div>
            {payload.map((entry, index) => {
                return (
                    <div className="legend-item" key={`item-${index}`}>
                        <div style={{ height: 14, width: 14, borderRadius: 4, backgroundColor: `${entry.color}` }} />
                        <span style={{ marginLeft: 10 }}>{entry.value}</span>
                        <span style={{ marginLeft: 10 }}>{entry?.payload?.payload?.value}</span>
                    </div>
                );
            })}
        </div>
    );
};

interface Props {
    data: {
        total: number | string;
        data: Array<{
            name: string;
            value: number;
        }>;
    };
}
const ClientsPerSource = ({ data }: Props) => {
    return (
        <ChartWrapper>
            <div style={{ height: '450px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={800} height={400}>
                        <Pie
                            data={data.data}
                            innerRadius={120}
                            outerRadius={140}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="value"
                        >
                            {data.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label position="center" value={data.total} className="label-top" />
                            <Label position="center" value="Clients" className="label-bottom" />
                        </Pie>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="middle"
                            iconSize={24}
                            style={{ right: '15%' }}
                            content={renderLegend}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </ChartWrapper>
    );
};

export default ClientsPerSource;
