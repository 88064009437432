import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import { download_leads_sample_excel, post_product, upload_leads } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { mapErrorToFields } from '../../../components/Functions';
import DownloadFile from '../../../components/DownloadFile';
import FormUploads from '../../../components/FormUploads';

const CreateLeads = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [file, setFile] = useState('');
    const [form] = Form.useForm();

    const uploadLeads = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('prospect_excel', file);
        authenticatedRequest()
            .post(upload_leads, formData)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.LIST_LEADS);
    };

    return (
        <AuthenticatedLayout title="Leads" titleTwo="Upload Leads">
            <Form onFinish={uploadLeads} form={form} layout="horizontal" style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Card>
                        <div style={{ padding: 20 }}>
                            <SuccessModal
                                visible={visible}
                                onClose={onComplete}
                                onComplete={onComplete}
                                title="Uploaded Leads"
                                description="Uploaded leads successfully"
                            />
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Upload Leads Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <label className="form-label">Download sample file</label>
                                    <DownloadFile
                                        url={download_leads_sample_excel}
                                        fileName="leads_sample.xlsx"
                                        readOnly={true}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FormUploads
                                        formItemName="resource_file"
                                        label="File"
                                        manualUpload={true}
                                        setFile={setFile}
                                    />
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName="Upload"
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Form>
        </AuthenticatedLayout>
    );
};

export default CreateLeads;
