import React, { useState } from 'react';
import { Card } from '../../../../components/Cards';
import { Col, Form, Input, notification, Row } from 'antd';
import { RiEyeCloseLine, RiEyeLine, RiLockUnlockLine } from 'react-icons/ri';
import { SuperiorHomesBlueButton } from '../../../../components/Buttons';
import { authenticatedRequest } from '../../../../api';
import { update_password } from '../../../../components/apis';
import SuccessModal from '../../../../components/modals/success';

const ChangePassword = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState<boolean>(false);
    const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

    const onChangePassword = (values) => {
        setIsChangingPassword(true);
        authenticatedRequest()
            .post(update_password, values)
            .then(() => {
                setIsChangingPassword(false);
                setVisible(true);
            })
            .catch((error) => {
                setIsChangingPassword(false);
                const resp = error?.response;
                console.log(resp?.data?.error);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
    };

    return (
        <Card title="Change Password">
            <SuccessModal
                visible={visible}
                onClose={onComplete}
                onComplete={onComplete}
                title="Password Changed"
                description="Password changed successfully"
            />
            <Row gutter={[16, 16]} style={{ padding: 20 }}>
                <Form
                    layout="vertical"
                    onFinish={onChangePassword}
                    requiredMark={false}
                    form={form}
                    style={{ width: '100%' }}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Form.Item
                                label="Current Password"
                                name="current_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your current password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Current Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="New Password"
                                name="new_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your new password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="New Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Confirm New Password"
                                name="confirm_new_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your new password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Confirm Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Form.Item>
                                <SuperiorHomesBlueButton
                                    htmlType="submit"
                                    buttonName="Change Password"
                                    loading={isChangingPassword}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </Card>
    );
};

export default ChangePassword;
