import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { RiChat1Fill, RiMessage3Fill } from 'react-icons/ri';
import { SuperiorHomesTable } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesBlueButton, SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { Card } from '../../../components/Cards';
import { Row, Col, notification, Tag } from 'antd';
import { authenticatedRequest } from '../../../api';
import { get_client_products, get_single_prospect, list_scheduled_interactions } from '../../../components/apis';
import ViewInteraction from '../../../components/modals/ViewInteraction';
import NewInteraction from '../../../components/modals/NewInteraction';
import ConvertProspectToClient from '../../../components/modals/ConvertProspectToClient';
import { ROUTES } from '../../../Router/urls';
import SuccessModal from '../../../components/modals/success';
import EmailClient from '../../../components/modals/Email';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import NewProspectProduct from '../../../components/modals/NewProspectProduct';
import EditProspect from '../../../components/modals/EditProspect';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import ReassignClientsOrProspects from '../../../components/modals/ReassignClientsOrProspects';

const columns = [
    {
        title: 'Date',
        dataIndex: 'interaction_date',
        render: (value) => moment(value).format('DD MMM YYYY'),
    },
    {
        title: 'Type',
        dataIndex: 'interaction_type_name',
    },
    {
        title: 'Comments',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiChat1Fill color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const upcomingInteractionsColumns = [
    {
        title: 'Type',
        dataIndex: 'interactionTypeName',
    },
    {
        title: 'Date',
        dataIndex: 'interactionDate',
        render: (value) => moment(value).format('DD MMM YYYY'),
    },
    {
        title: 'Time',
        dataIndex: 'interactionTime',
        // render: (value) => moment(value).format('HH:mm'),
    },
];

const productColumns = [
    {
        title: 'Name',
        dataIndex: 'development_name',
    },
    {
        title: 'Product Name',
        dataIndex: 'product_name',
    },
    {
        title: 'House Type',
        dataIndex: 'house_type_name',
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
];

const ViewProspect = () => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const history = useHistory();
    const [prospect, setProspect] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [loadingClientProducts, setLoadingClientProducts] = useState<boolean>(false);
    const [clientReassignVisible, setClientReassignVisible] = useState<boolean>(false);
    const [clientReassignType, setClientReassignType] = useState<'client' | 'prospect'>('client');
    const [clientProducts, setClientProducts] = useState<Array<any>>([]);
    const [upcomingInteractions, setUpcomingInteractions] = useState([]);
    const [loadingUpcomingInteraction, setLoadingUpcomingInteractions] = useState<boolean>(false);
    const [viewInteraction, setViewInteraction] = useState<boolean>(false);
    const [viewNewInteraction, setViewNewInteraction] = useState<boolean>(false);
    const [viewConvertProspectToClient, setConvertProspectToClient] = useState<boolean>(false);
    const [viewConvertProspectToClientSuccess, setConvertProspectToClientSuccess] = useState<boolean>(false);
    const [viewEditProspect, setViewEditProspect] = useState<boolean>(false);
    const [viewNewProduct, setViewNewProduct] = useState<boolean>(false);
    const [viewEmailClient, setViewEmailClient] = useState<boolean>(false);
    const [interaction, setInteraction] = useState<any>({});
    const [interactions, setInteractions] = useState<Array<any>>([]);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const { id } = useParams();

    const fetchProspect = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_single_prospect(id))
            .then((res) => {
                setLoading(false);
                console.log(res.data.prospect[0]);
                setProspect(res.data.prospect[0]);
                setInteractions(res.data.interactions);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchUpcomingInteractions = (page = 1) => {
        setLoadingUpcomingInteractions(true);
        authenticatedRequest()
            .get(list_scheduled_interactions(id, page))
            .then((res) => {
                setLoadingUpcomingInteractions(false);
                const x = res.data.interaction_schedules?.data.map((interactionSchedule) => {
                    return {
                        interactionDate: interactionSchedule.interaction_schedule_date,
                        interactionTime: interactionSchedule.interaction_schedule_time,
                        interactionTypeName: interactionSchedule.interaction_type_name,
                    };
                });
                setTotal(res.data.interaction_schedules?.total);
                setUpcomingInteractions(x);
            })
            .catch((error) => {
                setLoadingUpcomingInteractions(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClientProducts = () => {
        setLoadingClientProducts(true);
        authenticatedRequest()
            .get(get_client_products(id))
            .then((res) => {
                setLoadingClientProducts(false);
                setClientProducts(res?.data?.client_products?.products);
            })
            .catch((error) => {
                setLoadingClientProducts(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        setInteraction(record);
        setViewInteraction(true);
    };

    const onCloseViewNewInteraction = (success) => {
        setViewNewInteraction(false);

        if (success) {
            fetchProspect();
            fetchUpcomingInteractions();
        }
    };

    const onCloseConvertProspectToClient = (success) => {
        setConvertProspectToClient(false);

        if (success) {
            setTitle('Prospect converted to client!');
            setDescription('Prospect was converted successfully');
            setConvertProspectToClientSuccess(true);
        }
    };

    const onConvertProspect = () => {
        setConvertProspectToClient(true);
    };

    const onClickEmailClient = () => {
        setViewEmailClient(true);
    };

    const onAddNewInteraction = () => {
        setViewNewInteraction(true);
    };

    const onCloseViewInteractions = () => {
        setViewInteraction(false);
    };

    const onAddNewProduct = () => {
        setViewNewProduct(true);
    };

    const onCloseNewProduct = (success) => {
        setViewNewProduct(false);
        if (success) {
            fetchClientProducts();
        }
    };

    const onEditProspect = () => {
        setViewEditProspect(true);
    };

    const onCloseEditProspect = (success) => {
        setViewEditProspect(false);

        if (success) {
            fetchProspect();
            setTitle('Prospect Updated');
            setDescription('Prospect Updated successfully');
        }
    };

    const onCloseEmailClient = (success) => {
        setViewEmailClient(false);

        if (success) {
            setTitle('Email sent!');
            setDescription('Email sent to prospect successfully');
            setConvertProspectToClientSuccess(true);
        }
    };

    const onComplete = () => {
        setConvertProspectToClientSuccess(false);
        history.push(ROUTES.CLIENTS);
    };

    const onReassign = (type) => {
        setClientReassignVisible(true);
        setClientReassignType(type);
    };

    const onCloseReassign = (success) => {
        setClientReassignVisible(false);
        if (success) {
            setTitle('Prospect Reassigned!');
            setDescription('Prospect updated successfully');
            setConvertProspectToClientSuccess(true);
            history.push(ROUTES.PROSPECTS);
        }
    };

    useEffect(() => {
        fetchProspect();
        fetchUpcomingInteractions();
        fetchClientProducts();
    }, []);

    useEffect(() => {
        fetchProspect();
        fetchUpcomingInteractions();
        fetchClientProducts();
    }, [history.location.pathname]);

    return (
        <AuthenticatedLayout title="Prospect" titleTwo={prospect?.name}>
            <ViewInteraction visible={viewInteraction} interaction={interaction} onClose={onCloseViewInteractions} />

            <NewProspectProduct
                visible={viewNewProduct}
                prospectId={prospect?.id}
                onClose={(success) => onCloseNewProduct(success)}
            />

            <EmailClient
                visible={viewEmailClient}
                client={{ id: prospect?.id, email: prospect?.email }}
                userEmail={auth?.user?.email}
                onClose={(success) => onCloseEmailClient(success)}
            />

            <NewInteraction
                visible={viewNewInteraction}
                clientId={prospect?.id}
                onClose={(success) => onCloseViewNewInteraction(success)}
            />

            <EditProspect
                visible={viewEditProspect}
                prospect={prospect}
                onClose={(success) => onCloseEditProspect(success)}
            />

            <ConvertProspectToClient
                visible={viewConvertProspectToClient}
                clientId={prospect?.id}
                onClose={(success) => onCloseConvertProspectToClient(success)}
            />

            <SuccessModal
                visible={viewConvertProspectToClientSuccess}
                title={title}
                description={description}
                onClose={onComplete}
                onComplete={onComplete}
            />

            <ReassignClientsOrProspects
                type={clientReassignType}
                currentUser={{
                    id: prospect?.relationship_manager_id,
                    first_name: prospect?.relationship_manager_first_name,
                    last_name: prospect?.relationship_manager_last_name,
                }}
                visible={clientReassignVisible}
                clients={[prospect?.id]}
                onClose={(success) => onCloseReassign(success)}
            />
            <Card
                title="Prospect Details:"
                action={
                    <>
                        <AuthorizationWrapper allowedPermissions={['USER_CREATE']} disabled={true}>
                            <SuperiorHomesOrangeButton
                                buttonName="Reassign"
                                style={{ marginRight: 10 }}
                                onClick={() => onReassign('prospect')}
                            />
                        </AuthorizationWrapper>
                        <SuperiorHomesBlueButton
                            buttonName="Edit"
                            style={{ marginRight: 10 }}
                            onClick={onEditProspect}
                        />
                        <SuperiorHomesBlueButton
                            buttonName="Convert Prospect"
                            style={{ marginRight: 10 }}
                            onClick={onConvertProspect}
                        />
                        <SuperiorHomesOrangeButton
                            icon={<RiMessage3Fill color="#ffffff" style={{ marginRight: 10 }} />}
                            buttonName="Message Prospect"
                            onClick={onClickEmailClient}
                        />
                    </>
                }
            >
                <Row gutter={16} style={{ padding: '20px 10px' }} className="row-with-gray-background">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flexDirection: 'row' }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{prospect?.name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Email:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{prospect?.email}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Phone Number:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{prospect?.phone}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Source:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{prospect?.source_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Site Visit:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{prospect?.was_site_visit === 1 ? 'Yes' : 'No'}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Status:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <Tag
                                    color={
                                        prospect?.prospect_type_name?.toLowerCase() === 'hot'
                                            ? 'error'
                                            : prospect?.prospect_type_name?.toLowerCase() === 'warm'
                                            ? 'warning'
                                            : 'processing'
                                    }
                                >
                                    {prospect?.prospect_type_name}
                                </Tag>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <div style={{ marginTop: 20 }}>
                <Card
                    title="Products"
                    action={
                        <SuperiorHomesBlueButton
                            buttonName="New Product"
                            style={{ marginRight: 10 }}
                            onClick={onAddNewProduct}
                        />
                    }
                >
                    <SuperiorHomesTable
                        columns={productColumns}
                        data={clientProducts}
                        withPagination={true}
                        loading={loadingClientProducts}
                    />
                </Card>
            </div>

            <Row gutter={16} style={{ marginTop: 20 }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card title="Upcoming Interactions">
                        <SuperiorHomesTable
                            onPageChange={(page) => fetchUpcomingInteractions(page)}
                            total={total}
                            columns={upcomingInteractionsColumns}
                            data={upcomingInteractions}
                            withPagination={true}
                            loading={loadingUpcomingInteraction}
                        />
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card
                        title="Interactions"
                        action={
                            <SuperiorHomesBlueButton
                                buttonName="New Interaction"
                                style={{ marginRight: 10 }}
                                onClick={onAddNewInteraction}
                            />
                        }
                    >
                        <SuperiorHomesTable
                            columns={columns}
                            data={interactions}
                            onRowClick={(record) => onRowClick(record)}
                            withPagination={true}
                            loading={loading}
                        />
                    </Card>
                </Col>
            </Row>
        </AuthenticatedLayout>
    );
};

export default ViewProspect;
