import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './store';
import './App.css';
import AppRouter from './Router';
import withClearCache from './utils/ClearCache';
const ClearCacheComponent = withClearCache(MainApp);

function MainApp() {
    return (
        <Provider store={store}>
            <Router>
                <PersistGate persistor={persistor}>
                    <AppRouter />
                </PersistGate>
            </Router>
        </Provider>
    );
}

function App() {
    return <ClearCacheComponent />;
}
export default App;
