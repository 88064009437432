import { css } from 'styled-components';

const style = () => css`
    .upload-container {
        padding: 10px 0;
    }

    .upload-item {
        margin: 10px 0 0;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .ant-form-item-control {
            width: 100%;
        }
    }

    .form-label {
        font-size: 13px !important;
        font-weight: 400;
        color: #777c93;
        margin-bottom: 0 !important;
        display: block;
        line-height: 22px;
    }

    .form-col {
        margin-top: 10px;
        padding: 0px 20px;
    }

    .drag-text {
        font-size: 13px;
        font-weight: 400;
        color: #777c93;
        margin-left: 10px;
    }

    .drag-link {
        font-size: 13px;
        font-weight: 400;
        color: #1e87f0;
        cursor: pointer;
    }
`;

export default style;
