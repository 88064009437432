import moment from 'moment';

export const formatPermissions = (permissions: [any], returnType: 'array' | 'arrayOfObjects' = 'array') => {
    return permissions.map((permission) => {
        const key = permission.name.split('-').join('_').toUpperCase();
        if (returnType == 'arrayOfObjects') {
            return {
                [key]: permission.name,
            };
        }
        return key;
    });
};

export const replaceUrlParams = (url: string, params: Array<{ param: string; value: string }>) => {
    params.forEach((param) => {
        url = url.replace(param.param, param.value);
    });

    return url;
};

export const mapErrorToFields = (errors: { any }) => {
    return Object.keys(errors).map((error) => {
        return {
            name: error,
            errors: errors[error],
        };
    });
};

export const disableFutureDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().add(1, 'day').startOf('day');
};
