import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_resource_types } from '../../apis';

interface Props {
    formItemName: string | (number | string)[];
}
const SelectResourceType = ({ formItemName }: Props) => {
    const [resourceTypes, setResourceTypes] = useState<any>([]);

    const fetchResourceTypes = () => {
        authenticatedRequest()
            .get(list_resource_types(1, 100))
            .then((res) => {
                const resourceTypes = res.data.resource_types?.data.map((resourceType) => {
                    return {
                        value: resourceType.id,
                        label: resourceType.resource_type_name,
                    };
                });
                setResourceTypes(resourceTypes);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchResourceTypes();
    }, []);

    return (
        <Form.Item
            name={formItemName}
            rules={[
                {
                    required: true,
                    message: 'Please select a resource type',
                },
            ]}
        >
            <Select allowClear showSearch optionFilterProp="label" options={resourceTypes} />
        </Form.Item>
    );
};

export default SelectResourceType;
