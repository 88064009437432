import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_prospect_types } from '../../apis';

const SelectProspectType = () => {
    const [prospectTypes, setProspectTypes] = useState<any>([]);

    const fetchProspectTypes = () => {
        authenticatedRequest()
            .get(list_prospect_types)
            .then((res) => {
                const types = res.data.prospect_types.map((prospectType) => {
                    return {
                        value: prospectType.id,
                        label: prospectType.prospect_type_name,
                    };
                });
                setProspectTypes(types);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchProspectTypes();
    }, []);

    return (
        <Form.Item
            name="prospect_type_id"
            rules={[
                {
                    required: true,
                    message: 'Please select a status',
                },
            ]}
        >
            <Select allowClear options={prospectTypes} />
        </Form.Item>
    );
};

export default SelectProspectType;
