import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiCommunityLine, RiPencilLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { list_products } from '../../../components/apis';
import { notification } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';

const columns = [
    {
        title: 'Name',
        dataIndex: 'product_name',
    },
    {
        title: 'Project',
        dataIndex: 'development_name',
    },
    {
        title: 'House Type',
        dataIndex: 'house_type_name',
    },
    {
        title: 'Price',
        dataIndex: 'product_price',
        render: (value) => numeral(value).format('0,0.00'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Created On',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const ListProducts = () => {
    const history = useHistory();
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const goToCreateProduct = () => {
        history.push(ROUTES.CREATE_PRODUCT);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchProducts(1, 10, value);
    };

    const fetchProducts = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_products(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setProducts(res.data.products?.data);
                setTotal(res.data.products?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_PRODUCT.split(':').shift()}${record.id}`);
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <AuthenticatedLayout title="Products">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['PRODUCT_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Product"
                        icon={<RiCommunityLine color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateProduct}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Products ${products.length}`}
                action={
                    <TableSearch
                        placeholder="Search products"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchProducts(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchProducts(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={products}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => onRowClick(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PRODUCT_LIST'],
})(ListProducts);
