import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import { get_single_product, post_product, update_product } from '../../../components/apis';
import SelectProject from '../../../components/Selects/Project';
import SelectHouseType from '../../../components/Selects/HouseType';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { mapErrorToFields } from '../../../components/Functions';

const { TextArea } = Input;

const CreateProduct = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { id } = useParams();

    const onFinish = (values) => {
        setLoading(true);
        if (id) {
            updateProduct(values);
        } else {
            createProduct(values);
        }
    };

    const createProduct = (values) => {
        authenticatedRequest()
            .post(post_product, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const updateProduct = (values) => {
        authenticatedRequest()
            .post(update_product(id), values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const fetchProduct = () => {
        authenticatedRequest()
            .get(get_single_product(id))
            .then((res) => {
                const product = res?.data?.product?.[0];
                form.setFieldsValue({
                    product_name: product.product_name,
                    product_price: product.product_price,
                    house_type_id: product.house_type_id?.toString(),
                    development_id: product.development_id?.toString(),
                    product_description: product.product_description,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.PRODUCTS);
    };

    useEffect(() => {
        if (id) {
            fetchProduct();
        }
    }, []);

    return (
        <AuthenticatedLayout title="Product" titleTwo="New Product">
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={id ? 'Updated Product' : 'New Product Added'}
                            description={`Product ${form.getFieldValue('product_name')} was ${
                                id ? 'updated' : 'added'
                            } successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Product Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Name"
                                        form={form}
                                        formItemName="product_name"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="product_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input product name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Price"
                                        form={form}
                                        formItemName="product_price"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="product_price"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input product price',
                                                },
                                            ]}
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Project"
                                        form={form}
                                        formItemName="development_id"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <SelectProject />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="House Type"
                                        form={form}
                                        formItemName="house_type_id"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <SelectHouseType />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Description"
                                        form={form}
                                        formItemName="product_description"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="product_description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input description',
                                                },
                                            ]}
                                        >
                                            <TextArea autoSize rows={5} />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={`${id ? 'Update' : 'Save'} Product`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PRODUCT_CREATE'],
})(CreateProduct);
