import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Input, notification } from 'antd';
import { RiMailOpenLine } from 'react-icons/ri';
import style from './styles';
import logo from '../../assets/images/logo.svg';
import { SuperiorHomesBlueButton } from '../../components/Buttons';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import { unAuthenticatedRequest } from '../../api';
import { post_forgot_password } from '../../components/apis';

const ForgotPasswordWrapper = styled.div`
    ${style}
`;
const ForgotPassword = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        setLoading(true);
        unAuthenticatedRequest()
            .post(post_forgot_password, values)
            .then(() => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: `An email has been sent to ${values.email} contains the instructions on how to reset your password`,
                });
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <UnauthenticatedLayout>
            <ForgotPasswordWrapper>
                <div className="logo-container">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="salutations-container">
                    <p className="salutation">Forgot Password!</p>
                    <p className="description">Please submit your email to begin the password reset process.</p>
                    <div className="line" />
                </div>
                <Form onFinish={onFinish} form={form} style={{ width: '60%' }} layout="vertical" requiredMark={false}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please input your email',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Email" prefix={<RiMailOpenLine color="#C4C4C4" />} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <SuperiorHomesBlueButton buttonName="Submit" htmlType="submit" block loading={loading} />
                        </Col>
                    </Row>
                </Form>
            </ForgotPasswordWrapper>
        </UnauthenticatedLayout>
    );
};

export default ForgotPassword;
