import {
    activity_report,
    activity_report_export,
    conversion_cycle_report,
    conversion_cycle_report_export,
    lead_conversion_report,
    lead_conversion_report_export,
    lead_per_source_report,
    lead_per_source_report_export,
    sales_report,
    sales_report_export,
    site_vs_booking_report,
    site_vs_booking_report_export,
} from '../../../components/apis';
import numeral from 'numeral';
import moment from 'moment';
import { Tag, Tooltip } from 'antd';
import React from 'react';

export const reports = [
    {
        name: 'activity-report',
        api: activity_report,
        exportApi: activity_report_export,
        objectName: 'activities',
        filterSources: false,
        columns: [
            {
                title: 'Name',
                width: 250,
                fixed: 'left',
                dataIndex: ['prospect', 'name'],
            },
            {
                title: 'Phone Number',
                width: 150,
                fixed: 'left',
                dataIndex: ['prospect', 'phone'],
            },
            {
                title: 'Email Address',
                width: 250,
                fixed: 'left',
                dataIndex: ['prospect', 'email'],
            },
            {
                title: 'Activity Type',
                width: 150,
                fixed: 'left',
                dataIndex: ['prospect', 'activity_name'],
            },
            {
                title: 'Interaction Type',
                width: 150,
                fixed: 'left',
                dataIndex: ['interactions', 'interaction_type_name'],
            },
            {
                title: 'RM',
                dataIndex: 'prospect',
                width: 150,
                fixed: 'left',
                render: (_, record) =>
                    `${record.prospect.relationship_manager_first_name} ${record.prospect.relationship_manager_last_name}`,
            },
            {
                title: 'Development',
                dataIndex: 'project',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                render: (_, record) =>
                    record.client_product.map((product, index) => (
                        <Tooltip key={index} placement="topLeft" title={product?.development_name}>
                            <Tag color="processing">{product?.development_name}</Tag>
                        </Tooltip>
                    )),
            },
            {
                title: 'Project',
                dataIndex: 'project',
                ellipsis: true,
                width: 200,
                render: (_, record) =>
                    record.client_product.map((product, index) => (
                        <Tag key={index} color="processing">
                            {product.product_name}
                        </Tag>
                    )),
            },
            {
                title: 'Source',
                width: 200,
                dataIndex: ['prospect', 'source_name'],
            },
            {
                title: 'Unit Type',
                width: 150,
                dataIndex: 'unitType',
            },
            {
                title: 'Category',
                width: 100,
                dataIndex: ['prospect', 'prospect_type_name'],
                // eslint-disable-next-line react/display-name
                render: (value) => {
                    return (
                        <Tag
                            color={
                                value?.toLowerCase() === 'hot'
                                    ? 'error'
                                    : value?.toLowerCase() === 'warm'
                                    ? 'warning'
                                    : 'processing'
                            }
                        >
                            {value}
                        </Tag>
                    );
                },
            },
            {
                title: 'Comments',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                dataIndex: ['interactions', 'interaction_comment'],
                // eslint-disable-next-line react/display-name
                render: (val) => (
                    <Tooltip placement="topLeft" title={val}>
                        {val}
                    </Tooltip>
                ),
            },
            {
                title: 'Last Contacted',
                dataIndex: ['interactions', 'interaction_date'],
                width: 200,
                render: (val) => (val ? moment(val).format('DD MMMM YYYY') : '-'),
            },
            {
                title: 'Next Contact Date',
                dataIndex: ['interactions_schedules', 'interaction_schedule_date'],
                width: 200,
                render: (val) => (val ? moment(val).format('DD MMMM YYYY') : '-'),
            },
        ],
    },
    {
        name: 'leads-per-source-report',
        api: lead_per_source_report,
        exportApi: lead_per_source_report_export,
        objectName: 'leads_per_sources',
        filterSources: true,
        columns: [
            {
                title: 'Name',
                width: 250,
                fixed: 'left',
                dataIndex: ['prospect', 'name'],
            },
            {
                title: 'Phone Number',
                width: 150,
                fixed: 'left',
                dataIndex: ['prospect', 'phone'],
            },
            {
                title: 'Email Address',
                width: 250,
                fixed: 'left',
                dataIndex: ['prospect', 'email'],
            },
            {
                title: 'RM',
                width: 150,
                fixed: 'left',
                dataIndex: ['prospect', 'relationship_manager_first_name'],
            },
            {
                title: 'Development',
                dataIndex: 'project',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                render: (_, record) =>
                    record.client_product.map((product, index) => (
                        <Tooltip key={index} placement="topLeft" title={product.development_name}>
                            <Tag color="processing">{product.development_name}</Tag>
                        </Tooltip>
                    )),
            },
            {
                title: 'Source',
                width: 200,
                dataIndex: ['prospect', 'source_name'],
            },
            {
                title: 'Unit Type',
                width: 100,
                dataIndex: 'unitType',
            },
            {
                title: 'Category',
                width: 100,
                dataIndex: ['prospect', 'prospect_type_name'],
                // eslint-disable-next-line react/display-name
                render: (value) => {
                    return (
                        <Tag
                            color={
                                value.toLowerCase() === 'hot'
                                    ? 'error'
                                    : value.toLowerCase() === 'warm'
                                    ? 'warning'
                                    : 'processing'
                            }
                        >
                            {value}
                        </Tag>
                    );
                },
            },
            {
                title: 'Comments',
                ellipsis: {
                    showTitle: false,
                },
                width: 200,
                dataIndex: ['interactions', 'interaction_comment'],
            },
            {
                title: 'Last Contacted',
                dataIndex: ['interactions', 'interaction_date'],
                width: 200,
                render: (val) => (val ? moment(val).format('DD MMMM YYYY') : '-'),
            },
            {
                title: 'Next Contact Date',
                dataIndex: ['interactions_schedules', 'interaction_schedule_date'],
                width: 200,
                render: (val) => (val ? moment(val).format('DD MMMM YYYY') : '-'),
            },
        ],
    },
    {
        name: 'sales-report',
        api: sales_report,
        exportApi: sales_report_export,
        objectName: 'sales_reports',
        filterSources: false,
        columns: [
            {
                title: 'Name',
                dataIndex: ['client', 'name'],
            },
            {
                title: 'Phone Number',
                dataIndex: ['client', 'phone'],
            },
            {
                title: 'Email Address',
                dataIndex: ['client', 'email'],
            },
            {
                title: 'Relationship Manager',
                dataIndex: 'relationshipManager',
                render: (_, record) =>
                    `${record.client.relationship_manager_first_name} ${record.client.relationship_manager_last_name}`,
            },
            {
                title: 'Project',
                dataIndex: 'project',
                render: (_, record) =>
                    record.client_product.map((product) => `${product.development_name}:${product.product_name}, `),
            },
            {
                title: 'Unit Type',
                dataIndex: 'unitType',
            },
            {
                title: 'Price',
                dataIndex: 'price',
                render: (_, record) =>
                    record.client_product.map(
                        (product) => `${numeral(product.initial_product_price).format('0,0.00')}, `,
                    ),
            },
            {
                title: 'Amount Paid',
                dataIndex: 'amountPaid',
                render: (_, record) => record.client_product.map((product) => product.amount_paid),
            },
            {
                title: 'Next Payment Date',
                dataIndex: 'nextPaymentDate',
            },
            {
                title: 'Next Payment Amount',
                dataIndex: 'nextContactAmount',
            },
        ],
    },
    {
        name: 'lead-conversion-report',
        api: lead_conversion_report,
        exportApi: lead_conversion_report_export,
        objectName: 'lead_conversion_reports',
        filterSources: false,
        columns: [
            {
                title: 'Source Name',
                dataIndex: ['source', 'source_name'],
            },
            {
                title: 'Total leads for a given period',
                dataIndex: 'total_leads',
            },
            {
                title: 'Hot leads from the source',
                dataIndex: 'hot_leads',
            },
            {
                title: 'Sales from the source',
                dataIndex: 'sales_from_source',
            },
        ],
    },
    {
        name: 'site-visit-vs-bookings-report',
        api: site_vs_booking_report,
        exportApi: site_vs_booking_report_export,
        objectName: 'siteVisitsBookings',
        filterSources: false,
        columns: [
            {
                title: 'Month',
                dataIndex: 'month',
                render: (val) => moment(val).format('YYYY-MMMM'),
            },
            {
                title: 'Number pf site visitors',
                dataIndex: 'site visitors',
            },
            {
                title: 'Number of Sales',
                dataIndex: 'Number of Sales',
            },
        ],
    },
    {
        name: 'conversion-cycle-report',
        api: conversion_cycle_report,
        exportApi: conversion_cycle_report_export,
        objectName: 'activities',
        filterSources: false,
        columns: [
            {
                title: 'Name',
                dataIndex: ['prospect', 'name'],
            },
            {
                title: 'Product',
                dataIndex: 'product',
                render: (_, record) =>
                    record.client_product.map((product) => `${product.development_name}:${product.product_name}`),
            },
            {
                title: 'Unit Type',
                dataIndex: 'unitType',
            },
            {
                title: 'First inquiry made on',
                dataIndex: 'created_at',
                render: (val) => moment(val).format('DD MMM YYYY'),
            },
            {
                title: 'Sales closed on',
                dataIndex: 'converted_at',
                render: (val) => moment(val).format('DD MMM YYYY'),
            },
            {
                title: 'Number of days taken to close',
                dataIndex: 'number_of_days_taken',
            },
        ],
    },
];
