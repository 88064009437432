import React, { ReactNode } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import style from './styles';
const ButtonWrapper = styled.div`
    ${style}
`;

interface Props {
    buttonName: string;
    htmlType?: 'submit' | 'button' | 'reset' | undefined;
    block?: boolean;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
    icon?: ReactNode;
    style?: any;
}

const SuperiorHomesBlueButton = ({
    buttonName,
    htmlType,
    loading = false,
    disabled = false,
    block = false,
    onClick,
    icon,
    style,
}: Props) => {
    return (
        <ButtonWrapper buttonColor="#005A87" borderColor="#005A87">
            <Button
                type="primary"
                htmlType={htmlType}
                className="superior-homes-button"
                loading={loading}
                block={block}
                disabled={disabled}
                onClick={onClick}
                icon={icon}
                style={{ ...style }}
            >
                {buttonName}
            </Button>
        </ButtonWrapper>
    );
};

const SuperiorHomesOrangeButton = ({
    buttonName,
    htmlType,
    disabled = false,
    loading = false,
    block = false,
    onClick,
    icon,
    style,
}: Props) => {
    return (
        <ButtonWrapper buttonColor="#EF7E23" borderColor="#EF7E23">
            <Button
                htmlType={htmlType}
                type="primary"
                className="superior-homes-button"
                loading={loading}
                block={block}
                disabled={disabled}
                onClick={onClick}
                icon={icon}
                style={{ ...style }}
            >
                {buttonName}
            </Button>
        </ButtonWrapper>
    );
};

const SuperiorHomesLinkButton = ({
    buttonName,
    htmlType,
    loading = false,
    disabled = false,
    block = false,
    onClick,
    icon,
    style,
}: Props) => {
    return (
        <ButtonWrapper color="#2F80ED">
            <Button
                htmlType={htmlType}
                type="link"
                className="superior-homes-button"
                loading={loading}
                block={block}
                disabled={disabled}
                onClick={onClick}
                icon={icon}
                style={{ ...style }}
            >
                {buttonName}
            </Button>
        </ButtonWrapper>
    );
};

const SuperiorHomesCancelButton = ({
    buttonName,
    htmlType,
    loading = false,
    disabled = false,
    block = false,
    onClick,
    icon,
    style,
}: Props) => {
    return (
        <ButtonWrapper borderColor="#EB5757" color="#EB5757">
            <Button
                htmlType={htmlType}
                type="ghost"
                className="superior-homes-button"
                loading={loading}
                block={block}
                disabled={disabled}
                onClick={onClick}
                icon={icon}
                style={{ ...style }}
            >
                {buttonName}
            </Button>
        </ButtonWrapper>
    );
};

export { SuperiorHomesBlueButton, SuperiorHomesOrangeButton, SuperiorHomesLinkButton, SuperiorHomesCancelButton };
