import React from 'react';
import { Button, Divider, notification } from 'antd';
import styled from 'styled-components';
import { style } from './styles';
import InMemoryJWTManager from '../../../InMemoryJWT';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../Router/urls';
import { authenticatedRequest } from '../../../../api';
import { log_out } from '../../../apis';

// import { actions } from '../../../../authentication/sign-in/store/actions';

const LogoutWrapper = styled.div`
    ${style}
`;

interface Props {
    showDropDown: boolean;
    user?: any;
}

const LogoutComponent = ({ showDropDown, user }: Props) => {
    const history = useHistory();

    const logout = () => {
        authenticatedRequest()
            .get(log_out)
            .then(() => {
                history.push(ROUTES.SIGN_IN);
                InMemoryJWTManager.eraseToken();
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const goToProfile = () => {
        history.push(ROUTES.PROFILE);
    };

    return (
        <LogoutWrapper showDropDown={showDropDown}>
            <div className="logout-container">
                <div className="drop-arrow-up" />
                <p className="user">{user?.firstName + ' ' + user?.lastName}</p>
                <p className="role">Administrator</p>
                <Divider style={{ margin: '-5px 0px 5px' }} />
                <Button type="link" className="profile" onClick={goToProfile}>
                    Profile
                </Button>
                <Button type="link" className="sign-out" onClick={logout}>
                    Sign Out
                </Button>
            </div>
        </LogoutWrapper>
    );
};

export default LogoutComponent;
