import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { RiEyeLine } from 'react-icons/ri';
import { ROUTES } from '../../../Router/urls';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const reports = [
    { name: 'Activity Report', description: 'Description comes here' },
    { name: 'Leads per Source Report', description: 'Description comes here' },
    { name: 'Sales Report', description: 'Description comes here' },
    { name: 'Lead Conversion Report', description: 'Description comes here' },
    { name: 'Site Visit Vs Bookings Report', description: 'Description comes here' },
    { name: 'Conversion Cycle Report', description: 'Description comes here' },
    // { name: 'SHK Products Report', description: 'Description comes here' },
    // { name: 'Sales Cycle Report', description: 'Description comes here' },
];

const ListReports = () => {
    const history = useHistory();
    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.VIEW_REPORT.split(':').shift()}${record.name.split(' ').join('-').toLowerCase()}`);
    };

    return (
        <AuthenticatedLayout title="Reports">
            <Card
                title={`Reports (${reports.length})`}
                action={
                    <TableSearch
                        placeholder="Search reports"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    columns={columns}
                    data={reports}
                    withPagination={true}
                    loading={false}
                    onRowClick={(record) => onRowClick(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default ListReports;
