import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiPencilLine, RiUser2Fill } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification } from 'antd';
import { list_departments } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';

const columns = [
    {
        title: 'Name',
        dataIndex: 'department_name',
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const ListDepartments = () => {
    const history = useHistory();
    const [departments, setDepartments] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const goToCreateDepartment = () => {
        history.push(ROUTES.CREATE_DEPARTMENT);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchDepartments(1, 10, value);
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_DEPARTMENT.split(':').shift()}${record.id}`);
    };

    const fetchDepartments = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_departments(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setDepartments(res.data.departments?.data);
                setTotal(res.data.departments?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    return (
        <AuthenticatedLayout title="Departments">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['DEPARTMENT_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Department"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateDepartment}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Departments (${departments.length})`}
                action={
                    <TableSearch
                        placeholder="Search departments"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchDepartments(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchDepartments(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={departments}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['DEPARTMENT_LIST'],
})(ListDepartments);
