import React, { ReactNode, useEffect, useState } from 'react';
import { Layout, Menu, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
const { Content, Sider } = Layout;
import {
    RiUser2Fill,
    RiDashboard3Fill,
    RiUser6Line,
    RiAdvertisementLine,
    RiCommunityLine,
    RiBarChart2Line,
    RiAdminLine,
    RiBuildingLine,
    RiTeamLine,
    RiMailCheckLine,
    RiFileTextFill,
    RiSecurePaymentLine,
    RiArrowLeftRightLine,
} from 'react-icons/ri';
import styled from 'styled-components';
import TopBar from '../../components/TopBar';
import style from './styles';
import logo from '../../assets/images/logo.svg';
import { ROUTES } from '../../Router/urls';
import { checkRoutePermission } from '../../components/Authorization/checkRoutePermission';
import { useSelector } from 'react-redux';

const { SubMenu } = Menu;
const AuthenticatedLayoutWrapper = styled.div`
    ${style}
`;

interface AuthenticatedLayoutProps {
    children: ReactNode;
    title: string;
    titleTwo?: string;
}

const AuthenticatedLayout = ({ children, title, titleTwo }: AuthenticatedLayoutProps) => {
    const user = useSelector<any>((state) => state.auth.user);
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);

    const onCollapse = (collapse) => {
        setCollapsed(collapse);
    };

    useEffect(() => {
        // This listener allows to disconnect another sessions
        window.addEventListener('storage', (event) => {
            if (event.key === 'gs-session-expired') {
                history.push(ROUTES.SIGN_IN);
            }
        });

        window.addEventListener(
            'sessionExpired',
            () => {
                history.push(ROUTES.SIGN_IN);
            },
            false,
        );
        // cleanup this component
        return () => {
            window.removeEventListener('keydown', () => {
                console.log('Here');
            });
        };
    }, []);

    return (
        <AuthenticatedLayoutWrapper collapsed={collapsed}>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    breakpoint="md"
                    // collapsedWidth="0"
                    className="superior-homes-sider"
                    collapsible
                    collapsed={collapsed}
                    onCollapse={onCollapse}
                >
                    <div className="menu-container">
                        <div className="logo-container">
                            <img src={logo} />
                        </div>
                        <Divider />
                        <Menu
                            activeKey={history.location.pathname}
                            mode="inline"
                            selectedKeys={[history.location.pathname]}
                        >
                            <Menu.Item key="/dashboard" icon={<RiDashboard3Fill />}>
                                Dashboard
                                <Link to={ROUTES.DASHBOARD} />
                            </Menu.Item>
                            <Menu.Item
                                key="/clients"
                                icon={<RiUser2Fill />}
                                disabled={!checkRoutePermission(user, ['CLIENT_LIST'])}
                            >
                                Clients
                                <Link to={ROUTES.CLIENTS} />
                            </Menu.Item>
                            <Menu.Item
                                key="/prospects"
                                icon={<RiUser6Line />}
                                disabled={!checkRoutePermission(user, ['PROSPECT_LIST'])}
                            >
                                Prospects
                                <Link to={ROUTES.PROSPECTS} />
                            </Menu.Item>
                            <Menu.Item
                                key="/resources"
                                icon={<RiFileTextFill />}
                                disabled={!checkRoutePermission(user, ['RESOURCE_LIST'])}
                            >
                                Resources
                                <Link to={ROUTES.RESOURCES} />
                            </Menu.Item>
                            <Menu.Item key="/leads" icon={<RiBarChart2Line />}>
                                Leads <Link to={ROUTES.LIST_LEADS} />
                            </Menu.Item>
                            <Menu.Item key="/reports" icon={<RiBarChart2Line />}>
                                Reports <Link to={ROUTES.REPORTS} />
                            </Menu.Item>
                            <Divider type="horizontal" />
                            <div style={{ marginBottom: 20, paddingLeft: 24 }}>
                                <span
                                    style={{
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        fontSize: 15,
                                        color: '#707070',
                                    }}
                                >
                                    ADMIN
                                </span>
                            </div>
                            <Menu.Item
                                key="/sources"
                                icon={<RiAdvertisementLine />}
                                disabled={!checkRoutePermission(user, ['SOURCE_LIST'])}
                            >
                                Sources
                                <Link to={ROUTES.SOURCES} />
                            </Menu.Item>
                            <Menu.Item
                                key="/departments"
                                icon={<RiBuildingLine />}
                                disabled={!checkRoutePermission(user, ['DEPARTMENT_LIST'])}
                            >
                                Departments
                                <Link to={ROUTES.DEPARTMENTS} />
                            </Menu.Item>
                            <SubMenu
                                key="/sub1"
                                icon={<RiCommunityLine style={{ marginRight: 10 }} />}
                                title="Projects"
                                disabled={
                                    !checkRoutePermission(user, ['DEVELOPMENT_LIST', 'HOUSE_TYPE_LIST', 'PRODUCT_LIST'])
                                }
                            >
                                <Menu.Item key="/projects" disabled={!checkRoutePermission(user, ['DEVELOPMENT_LIST'])}>
                                    Projects <Link to={ROUTES.PROJECTS} />
                                </Menu.Item>
                                <Menu.Item key="/products" disabled={!checkRoutePermission(user, ['PRODUCT_LIST'])}>
                                    Products <Link to={ROUTES.PRODUCTS} />
                                </Menu.Item>
                                <Menu.Item
                                    key="/house-types"
                                    disabled={!checkRoutePermission(user, ['HOUSE_TYPE_LIST'])}
                                >
                                    House Types <Link to={ROUTES.HOUSE_TYPES} />
                                </Menu.Item>
                            </SubMenu>
                            <SubMenu
                                key="/sub2"
                                icon={<RiSecurePaymentLine style={{ marginRight: 10 }} />}
                                title="Payments"
                                disabled={
                                    !checkRoutePermission(user, [
                                        'PAYMENT_PLAN_LIST',
                                        'PAYMENT_LIST',
                                        'PAYMENT_TYPE_LIST',
                                    ])
                                }
                            >
                                <Menu.Item
                                    key="/payment/types"
                                    disabled={!checkRoutePermission(user, ['PAYMENT_TYPE_LIST'])}
                                >
                                    Types <Link to={ROUTES.PAYMENT_TYPES} />
                                </Menu.Item>
                                <Menu.Item key="/payments" disabled={!checkRoutePermission(user, ['PAYMENT_LIST'])}>
                                    Payments <Link to={ROUTES.PAYMENTS} />
                                </Menu.Item>
                                {/*<Menu.Item*/}
                                {/*    key="/upcoming-payments"*/}
                                {/*    disabled={!checkRoutePermission(user, ['PAYMENT_LIST'])}*/}
                                {/*>*/}
                                {/*    Upcoming Payments <Link to={ROUTES.UPCOMING_PAYMENTS} />*/}
                                {/*</Menu.Item>*/}
                                <Menu.Item
                                    key="/payment/plans"
                                    disabled={!checkRoutePermission(user, ['PAYMENT_PLAN_LIST'])}
                                >
                                    Plans <Link to={ROUTES.PAYMENT_PLANS} />
                                </Menu.Item>
                                <Menu.Item
                                    key="/statements"
                                    disabled={!checkRoutePermission(user, ['PAYMENT_PLAN_LIST'])}
                                >
                                    Statements <Link to={ROUTES.LIST_STATEMENTS} />
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="/email-templates" icon={<RiMailCheckLine />}>
                                <Link to={ROUTES.EMAIL_TEMPLATES} />
                                Email Templates
                            </Menu.Item>
                            <Menu.Item key="/email-templates" icon={<RiMailCheckLine />}>
                                <Link to={ROUTES.CRISP_EMAILS} />
                                CRISP Emails
                            </Menu.Item>
                            <Menu.Item key="/resource-types" icon={<RiMailCheckLine />}>
                                <Link to={ROUTES.RESOURCE_TYPES} />
                                Resource Types
                            </Menu.Item>
                            <Menu.Item
                                key="/reassign-requests"
                                icon={<RiArrowLeftRightLine />}
                                disabled={!checkRoutePermission(user, ['USER_LIST'])}
                            >
                                Reassign Requests
                                <Link to={ROUTES.REASSIGN_REQUESTS} />
                            </Menu.Item>
                            <Menu.Item
                                key="/users"
                                icon={<RiTeamLine />}
                                disabled={!checkRoutePermission(user, ['USER_LIST'])}
                            >
                                <Link to={ROUTES.USERS} />
                                Users
                            </Menu.Item>
                            <Menu.Item
                                key="/roles"
                                icon={<RiAdminLine />}
                                disabled={!checkRoutePermission(user, ['ROLE_LIST'])}
                            >
                                <Link to={ROUTES.ROLES} />
                                Roles
                            </Menu.Item>
                        </Menu>
                    </div>
                </Sider>
                <Layout style={{ background: '#f5f6fa' }}>
                    <Content style={{ margin: '0 16px' }}>
                        <TopBar title={title} titleTwo={titleTwo} />
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </AuthenticatedLayoutWrapper>
    );
};

export default AuthenticatedLayout;
