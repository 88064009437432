import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import numeral from 'numeral';
import { Card } from '../../../components/Cards';
import { Col, notification, Row } from 'antd';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { authenticatedRequest } from '../../../api';
import { confirm_payment, download_resource, get_single_payment, reject_payment } from '../../../components/apis';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import moment from 'moment';
import DownloadFile from '../../../components/DownloadFile';
import ChangePaymentPlanStatus from '../../../components/modals/ChangePaymentPlanStatus';
import WarningModal from '../../../components/modals/success/WarningModal';

const ViewPayment = () => {
    const history = useHistory();
    const { id } = useParams();
    const [rejecting, setRejecting] = useState<boolean>(false);
    const [confirming, setConfirming] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [confirmPaymentModalVisible, setConfirmPaymentModalVisible] = useState<boolean>(false);
    const [paymentRes, setPaymentRes] = useState<boolean>(false);
    const [payment, setPayment] = useState<any>();
    const [type, setType] = useState<'Approved' | 'Rejected'>();

    const fetchPayment = () => {
        authenticatedRequest()
            .get(get_single_payment(id))
            .then((res) => {
                setPayment(res?.data?.payment?.[0]);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRejectPayment = (values) => {
        setRejecting(true);
        authenticatedRequest()
            .post(reject_payment(id), {
                comment: values.comment,
            })
            .then(() => {
                setRejecting(false);
                setVisible(false);
                setPaymentRes(true);
            })
            .catch((error) => {
                setRejecting(false);
                setVisible(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onConfirmPayment = () => {
        setConfirming(true);
        authenticatedRequest()
            .get(confirm_payment(id))
            .then(() => {
                setConfirming(false);
                setPaymentRes(true);
            })
            .catch((error) => {
                setConfirming(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onCloseChangeStatus = (success: boolean) => {
        setVisible(false);
        if (success) {
            fetchPayment();
        }
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.PAYMENTS);
    };
    useEffect(() => {
        fetchPayment();
    }, []);

    return (
        <AuthenticatedLayout title={`Client | ${payment?.name}`} titleTwo={payment?.product_name}>
            <Card
                title="Payment Details:"
                action={
                    <>
                        {payment?.is_approved !== 1 ? (
                            <>
                                <SuperiorHomesCancelButton
                                    style={{ marginRight: 20 }}
                                    buttonName="Decline"
                                    loading={rejecting}
                                    onClick={() => {
                                        setType('Rejected');
                                        setVisible(true);
                                    }}
                                />

                                <SuperiorHomesBlueButton
                                    style={{ marginRight: 20 }}
                                    buttonName="Confirm"
                                    loading={confirming}
                                    onClick={() => {
                                        setType('Approved');
                                        setConfirmPaymentModalVisible(true);
                                    }}
                                />
                            </>
                        ) : null}
                    </>
                }
            >
                <SuccessModal
                    visible={paymentRes}
                    title={`Payment ${type == 'Approved' ? 'Confirmed' : 'Declined'}`}
                    description={`Payment ${type == 'Approved' ? 'confirmed' : 'declined'} successfully`}
                    onClose={onComplete}
                    onComplete={onComplete}
                />

                <WarningModal
                    visible={confirmPaymentModalVisible}
                    title="Confirm Payment"
                    description="Are you sure you want confirm this payment?"
                    onClose={() => setConfirmPaymentModalVisible(false)}
                    onComplete={() => onConfirmPayment()}
                    onCompleteLoading={confirming}
                />

                <ChangePaymentPlanStatus
                    type="Payment"
                    status={type}
                    visible={visible}
                    handleCancel={(success) => onCloseChangeStatus(success)}
                    onComplete={(values) => onRejectPayment(values)}
                    onCompleteLoading={confirming}
                />

                <Row gutter={16} style={{ padding: '20px 10px' }} className="row-with-gray-background">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flexDirection: 'row' }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Product Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.product_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Payment Amount:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">
                                    KES {numeral(payment?.payment_amount).format('0,0.00')}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Payment Type:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.payment_type_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Transaction Type:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.transaction_type_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <DownloadFile
                                url={download_resource(payment?.resource_id)}
                                fileName={payment?.resource_name}
                                readOnly={true}
                            />
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Development Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.development_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">House Type:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.house_type_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">House Number:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.house_number}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Created By:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{payment?.created_by_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Created At:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">
                                    {moment(payment?.created_at).format('DD MMM YYYY HH:mm')}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_VIEW'],
})(ViewPayment);
