import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import AuthenticatedLayout from '../../../../layouts/AuthenticatedLayout';
import { Card } from '../../../../components/Cards';
import { Col, Divider, notification, Row, Tag } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { authenticatedRequest } from '../../../../api';
import {
    delete_payment,
    get_client_payments_per_product,
    get_single_client,
    get_single_product,
} from '../../../../components/apis';
import PaymentPlans from '../../../../components/PaymentPlans/List';
import withAuthorization from '../../../../components/Authorization/withAuthorization';
import { SuperiorHomesTable } from '../../../../components/Table';
import { SuperiorHomesBlueButton } from '../../../../components/Buttons';
import { replaceUrlParams } from '../../../../components/Functions';
import { ROUTES } from '../../../../Router/urls';
import moment from 'moment';
import { RiDownloadCloud2Fill, RiDeleteBin5Line } from 'react-icons/ri';
import WarningModal from '../../../../components/modals/success/WarningModal';

const ClientViewProduct = () => {
    const history = useHistory();
    const { id, productId, clientProductId } = useParams();
    const [client, setClient] = useState<any>();
    const [payments, setPayments] = useState<any>([]);
    const [total, setTotal] = useState<number>(0);
    const [product, setProduct] = useState<{
        id: string;
        product_name: string;
        product_price: string;
        product_description: string;
        development_name: string;
        house_type_name: string;
        created_by_name: string;
        created_at: string;
    }>();
    const [showDeletePayment, setShowDeletePayment] = useState<boolean>(false);
    const [isDeletingPayment, setIsDeletingPayment] = useState<boolean>(false);
    const [selectedPaymentId, setSelectedPaymentId] = useState<string | null>(null);
    const fetchClientProduct = () => {
        authenticatedRequest()
            .get(get_single_product(productId))
            .then((res) => {
                setProduct(res?.data?.product?.[0]);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClientPaymentsPerProduct = () => {
        authenticatedRequest()
            .get(get_client_payments_per_product(clientProductId))
            .then((res) => {
                setPayments(res?.data?.payments?.data);
                setTotal(res?.data?.payments?.total);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClient = () => {
        authenticatedRequest()
            .get(get_single_client(id))
            .then((res) => {
                setClient(res.data.client[0]);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const addPayment = () => {
        history.push(
            replaceUrlParams(ROUTES.CREATE_PAYMENT, [
                { param: ':clientId', value: id },
                { param: ':productId', value: productId },
                { param: ':clientProductId', value: clientProductId },
            ]),
        );
    };

    const columns = [
        {
            title: 'Amount',
            dataIndex: 'payment_amount',
            render: (value) => numeral(value).format('0,0.00'),
        },
        {
            title: 'Date',
            dataIndex: 'payment_date',
            render: (value) => moment(value).format('DD MMM YYYY'),
        },
        {
            title: 'Payment Type',
            dataIndex: 'payment_type_name',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by_name',
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transaction_type_name',
        },
        {
            title: 'Status',
            dataIndex: 'is_approved',
            // eslint-disable-next-line react/display-name
            render: (value) => {
                if (value === 1) {
                    return <Tag color="success">Confirmed</Tag>;
                }

                return <Tag color="warning">Awaiting Confirmation</Tag>;
            },
        },
        {
            title: 'Approved By',
            dataIndex: 'is_approved_by',
        },
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) =>
                !record.is_approved ? (
                    <>
                        <RiDownloadCloud2Fill
                            color="#2F80ED"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                downloadClientDocument(record);
                            }}
                        />
                        <Divider type="vertical" />
                        <RiDeleteBin5Line
                            color="red"
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedPaymentId(record?.id);
                                setShowDeletePayment(true);
                            }}
                        />
                    </>
                ) : null,
        },
    ];

    const downloadClientDocument = (record) => {
        console.log(record);
    };

    const deletePayment = () => {
        setIsDeletingPayment(true);
        authenticatedRequest()
            .get(delete_payment(selectedPaymentId))
            .then(() => {
                setIsDeletingPayment(false);
            })
            .catch((error) => {
                setIsDeletingPayment(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchClientProduct();
        fetchClientPaymentsPerProduct();
        fetchClient();
    }, []);

    return (
        <AuthenticatedLayout title={`Client | ${client?.name}`} titleTwo={product?.product_name}>
            <WarningModal
                visible={showDeletePayment}
                title="Delete Payment"
                description="Are you sure you want to delete this payment"
                onClose={() => setShowDeletePayment(false)}
                onComplete={() => {
                    deletePayment();
                }}
                onCompleteLoading={isDeletingPayment}
            />
            <Card title="Product Details:">
                <Row gutter={16} style={{ padding: '20px 10px' }} className="row-with-gray-background">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flexDirection: 'row' }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Product Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{product?.product_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Product Price:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{numeral(product?.product_price).format('0,0.00')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Product Description:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{product?.product_description}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Development Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{product?.development_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">House Type:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{product?.house_type_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Created By:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{product?.created_by_name}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <div style={{ marginTop: 20 }}>
                <Card
                    title="Payments"
                    action={<SuperiorHomesBlueButton onClick={addPayment} buttonName="Add Payment" />}
                >
                    <SuperiorHomesTable columns={columns} data={payments} total={total} />
                </Card>
            </div>
            <div style={{ marginTop: 20 }}>
                <PaymentPlans clientProductId={clientProductId} />
            </div>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['CLIENT_PRODUCT_DETAIL'],
})(ClientViewProduct);
