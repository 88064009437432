import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_roles } from '../../apis';
import style from './styles';
import styled from 'styled-components';
const SelectWrapper = styled.div`
    ${style}
`;

interface Props {
    isFormList: boolean;
    mode?: 'multiple' | 'tags';
    field?: any;
    name: any;
    fieldKey?: any;
    rules: any;
}

const SelectRole = ({ isFormList, mode, field, fieldKey, name, rules }: Props) => {
    const [roles, setRoles] = useState<any>([]);
    const [value, setValues] = React.useState<any>([]);

    const fetchRoles = () => {
        authenticatedRequest()
            .get(list_roles)
            .then((res) => {
                const roles = res.data.roles.map((role) => {
                    return {
                        value: role.role.id,
                        label: role.role.name,
                    };
                });
                setRoles(roles);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    if (isFormList) {
        return (
            <Form.Item {...field} fieldKey={fieldKey} name={name} rules={rules}>
                <Select allowClear options={roles} />
            </Form.Item>
        );
    }

    if (!isFormList && (mode == 'multiple' || mode === 'tags')) {
        const selectProps = {
            allowClear: true,
            mode: 'multiple' as const,
            style: { width: '100%' },
            value,
            options: roles,
            onChange: (newValue: string[]) => {
                setValues(newValue);
            },
            maxTagCount: 'responsive' as const,
        };
        return (
            <SelectWrapper>
                <Form.Item name={name} rules={rules}>
                    <Select {...selectProps} />
                </Form.Item>
            </SelectWrapper>
        );
    }

    return (
        <Form.Item name={name} rules={rules}>
            <Select allowClear options={roles} />
        </Form.Item>
    );
};

export default SelectRole;
