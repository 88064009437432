import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, notification, Row, Tag } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Card } from '../../../components/Cards';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { authenticatedRequest } from '../../../api';
import {
    approve_or_reject_payment_plan,
    get_single_client_payment_plan,
    list_payment_plan_statuses,
} from '../../../components/apis';
import { SuperiorHomesTable } from '../../../components/Table';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import ChangePaymentPlanStatus from '../../../components/modals/ChangePaymentPlanStatus';

const columns = [
    {
        title: 'Description',
        dataIndex: 'payment_description',
    },
    {
        title: 'Amount',
        dataIndex: 'payment_amount',
        render: (val) => numeral(val).format('0,0.00'),
    },
    {
        title: 'Payment Date',
        dataIndex: 'payment_date',
        render: (val) => moment(val).format('DD, dddd MMM YYYY'),
    },
];

const ViewPaymentPlan = () => {
    const { id } = useParams();
    const [paymentPlanStatuses, setPaymentPlanStatuses] = useState<any>([]);
    const [paymentPlanDetails, setPaymentPlanDetails] = useState<any>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [type, setType] = useState<'Approved' | 'Rejected'>();
    const [paymentPlan, setPaymentPlan] = useState<{
        payment_plan_name: string;
        name: string;
        created_by_name: string;
        approval_by_name: string;
        approval_by: string;
        created_at: string;
        payment_plan_approval_status_name: string;
    }>();

    const fetchPaymentPlan = () => {
        authenticatedRequest()
            .get(get_single_client_payment_plan(id))
            .then((res) => {
                setPaymentPlan(res?.data?.payment_plan?.[0]);
                setPaymentPlanDetails(res?.data?.payment_plan_details);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = (values) => {
        values['payment_plan_approval_status_id'] = getPaymentStatusId(type);
        setLoading(true);
        authenticatedRequest()
            .post(approve_or_reject_payment_plan(id), values)
            .then((res) => {
                fetchPaymentPlan();
                setLoading(false);
                setVisible(false);
            })
            .catch((error) => {
                setLoading(false);
                setVisible(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };
    const fetchPaymentPlanStatuses = () => {
        authenticatedRequest()
            .get(list_payment_plan_statuses)
            .then((res) => {
                setPaymentPlanStatuses(res?.data?.payment_plan_approval_statuses);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onChangeStatus = (type: 'Approved' | 'Rejected') => {
        setType(type);
        setVisible(true);
    };

    const getPaymentStatusId = (type: 'Approved' | 'Rejected' | undefined) => {
        const index = paymentPlanStatuses.findIndex((item: any) => item.payment_plan_approval_status_name === type);

        if (index !== -1) {
            return paymentPlanStatuses[index]?.id;
        }
        return null;
    };

    const onCloseChangeStatus = (success: boolean) => {
        setVisible(false);
        if (success) {
            fetchPaymentPlan();
        }
    };

    useEffect(() => {
        fetchPaymentPlan();
        fetchPaymentPlanStatuses();
    }, []);

    return (
        <AuthenticatedLayout title="Payment Plan" titleTwo={paymentPlan?.payment_plan_name}>
            {type !== undefined ? (
                <ChangePaymentPlanStatus
                    type="Payment Plan"
                    status={type}
                    visible={visible}
                    handleCancel={(success) => onCloseChangeStatus(success)}
                    onComplete={(values) => onComplete(values)}
                    onCompleteLoading={loading}
                />
            ) : null}
            <Card
                title="Payment Plan:"
                action={
                    paymentPlan?.payment_plan_approval_status_name.toLowerCase() !== 'draft' ? (
                        <>
                            <SuperiorHomesBlueButton
                                style={{ marginRight: 20 }}
                                buttonName="Approve"
                                onClick={() => onChangeStatus('Approved')}
                            />

                            <SuperiorHomesCancelButton buttonName="Reject" onClick={() => onChangeStatus('Rejected')} />
                        </>
                    ) : null
                }
            >
                <Row gutter={16} style={{ padding: '20px 10px' }} className="row-with-gray-background">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flexDirection: 'row' }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{paymentPlan?.payment_plan_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Client:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{paymentPlan?.name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Created By:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{paymentPlan?.created_by_name}</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Approved By:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{paymentPlan?.approval_by_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Status:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">
                                    {paymentPlan?.payment_plan_approval_status_name.toLowerCase() === 'approved' ? (
                                        <Tag color="success">Approved</Tag>
                                    ) : paymentPlan?.payment_plan_approval_status_name.toLowerCase() ===
                                      'awaiting approval' ? (
                                        <Tag color="warning">Awaiting Approval</Tag>
                                    ) : paymentPlan?.payment_plan_approval_status_name.toLowerCase() === 'pending' ? (
                                        <Tag color="default">Draft</Tag>
                                    ) : (
                                        <Tag color="error">Rejected</Tag>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Created At:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">
                                    {moment(paymentPlan?.created_at).format('DD MMM YYYY')}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <div style={{ marginTop: 20 }}>
                <Card title="Details">
                    <SuperiorHomesTable columns={columns} data={paymentPlanDetails} />
                </Card>
            </div>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_PLAN_DETAIL'],
})(ViewPaymentPlan);
