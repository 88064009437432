import React from 'react';
import Logo from '../../assets/images/logo.svg';

const Unauthorized = () => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '95vh',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}
            >
                <img style={{ width: '10%' }} src={Logo} alt="Logo" />
                <p>Unauthorized!</p>
            </div>
        </div>
    );
};

export default Unauthorized;
