import axios from 'axios';
import InMemoryJWTManager from '../components/InMemoryJWT';

const authenticatedRequest = (headers = {}, baseUrl = process.env.REACT_APP_API_BASE_URL) => {
    const options = {
        baseURL: baseUrl,
        // withCredentials: true,
        headers: {},
    };

    options.headers = { ...options.headers, ...headers };

    if (!InMemoryJWTManager.getToken()) {
        InMemoryJWTManager.getRefreshedToken();
    }

    const axiosRequest = axios.create(options);
    axiosRequest.interceptors.request.use(
        async (config) => {
            await InMemoryJWTManager.waitForRefreshToken().then(() => {
                return InMemoryJWTManager.getToken() ? Promise.resolve() : Promise.reject();
            });
            config.headers = {
                ...config.headers,
                ...{
                    Authorization: `Bearer ${InMemoryJWTManager.getToken()}`,
                },
            };
            return config;
        },
        (error) => {
            console.log(error);
            Promise.reject(error);
        },
    );

    return axiosRequest;
};

const unAuthenticatedRequest = (headers = {}) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const options = {
        baseURL: baseUrl,
        headers: {
            ...headers,
        },
    };
    return axios.create(options);
};

export { authenticatedRequest, unAuthenticatedRequest };
