import { RoutesType } from './routes';

import Dashboard from '../pages/Dashboard';
import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ListClients from '../pages/Clients/List';
import CreateClient from '../pages/Clients/Create';
import ListSources from '../pages/Sources/List';
import CreateSource from '../pages/Sources/Create';
import ListDepartments from '../pages/Departments/List';
import CreateDepartment from '../pages/Departments/Create';
import ListUsers from '../pages/Users/List';
import CreateUser from '../pages/Users/Create';
import ListProspects from '../pages/Prospects/List';
import CreateProspect from '../pages/Prospects/Create';
import ActivateAccount from '../pages/ActivateAccount';
import ResetPassword from '../pages/ResetPassword';
import Profile from '../pages/Profile';
import ViewClient from '../pages/Clients/View';
import ViewProspect from '../pages/Prospects/View';
import ListProjects from '../pages/Projects/List';
import CreateProject from '../pages/Projects/Create';
import ListHouseTypes from '../pages/HouseTypes/List';
import CreateHouseType from '../pages/HouseTypes/Create';
import ListProducts from '../pages/Products/List';
import CreateProduct from '../pages/Products/Create';
import ListRoles from '../pages/Roles/List';
import CreateRole from '../pages/Roles/Create';
import ListReports from '../pages/Reports/List';
import ViewReport from '../pages/Reports/View';
import Unauthorized from '../components/ErrorPages/Unauthorized';
import ListEmailTemplates from '../pages/EmailTemplates/List';
import CreateEmailTemplate from '../pages/EmailTemplates/Create';
import ViewUser from '../pages/Users/View';
import ListResourceTypes from '../pages/ResourceTypes/List';
import CreateResourceType from '../pages/ResourceTypes/Create';
import ListResources from '../pages/Resources/List';
import CreateResource from '../pages/Resources/Create';
import ListCrispEmail from '../pages/ListCrispEmail/List';
import CreateCrispEmail from '../pages/ListCrispEmail/Create';
import ClientViewProduct from '../pages/Clients/Product/View';
import ListPaymentPlans from '../pages/PaymentPlans/List';
import ViewPaymentPlan from '../pages/PaymentPlans/View';
import CreatePaymentPlan from '../pages/PaymentPlans/Create';
import ListPaymentTypes from '../pages/PaymentTypes/List';
import CreatePaymentType from '../pages/PaymentTypes/Create';
import CreatePayment from '../pages/Payments/Create';
import ListPayments from '../pages/Payments/List';
import ViewPayment from '../pages/Payments/View';
import ListLeads from '../pages/Leads/List';
import CreateLeads from '../pages/Leads/Create';
import ListUpcomingPayments from '../pages/UpcomingPayments/List';
import ListStatements from '../pages/Statements/List';
import ViewStatement from '../pages/Statements/View';
import ListReassignRequests from '../pages/ReassignRequests/List';

const COMPONENTS: { [key in RoutesType]: any } = {
    SIGN_IN: SignIn,
    FORGOT_PASSWORD: ForgotPassword,
    ACTIVATE_ACCOUNT: ActivateAccount,
    RESET_PASSWORD: ResetPassword,
    CLIENTS: ListClients,
    CREATE_CLIENT: CreateClient,
    VIEW_CLIENT: ViewClient,
    VIEW_CLIENT_PRODUCT: ClientViewProduct,
    PROSPECTS: ListProspects,
    VIEW_PROSPECT: ViewProspect,
    CREATE_PROSPECT: CreateProspect,
    SOURCES: ListSources,
    CREATE_SOURCE: CreateSource,
    EDIT_SOURCE: CreateSource,
    DEPARTMENTS: ListDepartments,
    CREATE_DEPARTMENT: CreateDepartment,
    EDIT_DEPARTMENT: CreateDepartment,
    DASHBOARD: Dashboard,
    USERS: ListUsers,
    CREATE_USER: CreateUser,
    EDIT_USER: CreateUser,
    VIEW_USER: ViewUser,
    PROFILE: Profile,
    PROJECTS: ListProjects,
    CREATE_PROJECT: CreateProject,
    EDIT_PROJECT: CreateProject,
    PRODUCTS: ListProducts,
    CREATE_PRODUCT: CreateProduct,
    EDIT_PRODUCT: CreateProduct,
    HOUSE_TYPES: ListHouseTypes,
    CREATE_HOUSE_TYPE: CreateHouseType,
    EDIT_HOUSE_TYPE: CreateHouseType,
    ROLES: ListRoles,
    CREATE_ROLE: CreateRole,
    EDIT_ROLE: CreateRole,
    UNAUTHORIZED: Unauthorized,
    REPORTS: ListReports,
    VIEW_REPORT: ViewReport,
    EMAIL_TEMPLATES: ListEmailTemplates,
    CREATE_EMAIL_TEMPLATE: CreateEmailTemplate,
    EDIT_EMAIL_TEMPLATE: CreateEmailTemplate,
    RESOURCE_TYPES: ListResourceTypes,
    CREATE_RESOURCE_TYPE: CreateResourceType,
    EDIT_RESOURCE_TYPE: CreateResourceType,
    RESOURCES: ListResources,
    CREATE_RESOURCE: CreateResource,
    EDIT_RESOURCE: CreateResource,
    CRISP_EMAILS: ListCrispEmail,
    CREATE_CRISP_EMAIL: CreateCrispEmail,
    EDIT_CRISP_EMAIL: CreateCrispEmail,
    PAYMENT_PLANS: ListPaymentPlans,
    VIEW_PAYMENT_PLAN: ViewPaymentPlan,
    CREATE_PAYMENT_PLAN: CreatePaymentPlan,
    EDIT_PAYMENT_PLAN: CreatePaymentPlan,
    DUPLICATE_PAYMENT_PLAN: CreatePaymentPlan,
    PAYMENT_TYPES: ListPaymentTypes,
    CREATE_PAYMENT_TYPE: CreatePaymentType,
    EDIT_PAYMENT_TYPE: CreatePaymentType,
    CREATE_PAYMENT: CreatePayment,
    PAYMENTS: ListPayments,
    VIEW_PAYMENT: ViewPayment,
    UPCOMING_PAYMENTS: ListUpcomingPayments,
    LIST_LEADS: ListLeads,
    CREATE_LEADS: CreateLeads,
    LIST_STATEMENTS: ListStatements,
    VIEW_STATEMENTS: ViewStatement,
    REASSIGN_REQUESTS: ListReassignRequests,
};

export { COMPONENTS };
