import React from 'react';
import { useSelector } from 'react-redux';
import { checkRoutePermission } from './checkRoutePermission';
// import { RoutePermission } from '../../Router/permissions';
// import { AuthenticatedRoutesType } from '../../Router/routes';
import { Redirect } from 'react-router-dom';
import { ROUTES } from '../../Router/urls';

// type AuthorizationType = RoutePermission & {
//     redirectTo?: AuthenticatedRoutesType;
// };

const withAuthorization = (authorization?, callback?: () => void) => {
    return function HOC(Component) {
        return function (props) {
            const user = useSelector<any>((state) => state.auth.user);
            const canAccess = checkRoutePermission(user, authorization.allowedPermissions);
            if (canAccess) return <Component {...props} />;

            if (callback) callback();

            if (authorization?.redirectTo) {
                return <Redirect to={ROUTES[authorization.redirectTo]} />;
            } else {
                return <Redirect to={ROUTES.UNAUTHORIZED} />;
            }
        };
    };
};

export default withAuthorization;
