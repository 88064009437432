import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, notification, Row, Select, DatePicker } from 'antd';
import { RiAdvertisementLine, RiCommunityLine, RiUser2Fill, RiUser6Line } from 'react-icons/ri';
import { Card, SummaryCard } from '../../components/Cards';
import { SuperiorHomesTable } from '../../components/Table';
import AuthenticatedLayout from '../../layouts/AuthenticatedLayout';
import ClientsChart from '../../components/Charts/Clients';
import ClientsPerSource from '../../components/Charts/ClientsPerSource';
import { authenticatedRequest } from '../../api';
import { dashboard, get_clients_conversion, get_clients_per_source } from '../../components/apis';
import { ROUTES } from '../../Router/urls';
import { FREQUENCY_TYPES } from '../../components/enums';
import moment from 'moment';

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'Client Name',
        dataIndex: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone',
    },
    // {
    //     title: 'Product',
    //     dataIndex: 'product',
    // },
];

interface dataProps {
    totalClients: number;
    totalProspects: number;
    totalSources: number;
    totalProducts: number;
    clients: Array<any>;
    prospects: Array<any>;
}

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}

const Dashboard = () => {
    const history = useHistory();
    const [clientConvertedFrequency, setClientConvertedFrequency] = useState<FREQUENCY_TYPES>(FREQUENCY_TYPES.DAILY);
    const [clientConvertedStartDate, setClientConvertedStartDate] = useState(
        moment().startOf('month').format('YYYY-MM-DD'),
    );
    const [clientConvertedEndDate, setClientConvertedEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState<boolean>(false);
    const [convertedClients, setConvertedClients] = useState([]);
    const [clientsPerSource, setClientsPerSource] = useState({ total: 0, data: [] });
    const [summaryData, setSummaryData] = useState<dataProps>({
        totalClients: 0,
        totalProspects: 0,
        totalProducts: 0,
        totalSources: 0,
        clients: [],
        prospects: [],
    });

    const getDashboardData = () => {
        setLoading(true);
        authenticatedRequest()
            .get(dashboard)
            .then((res) => {
                setLoading(false);
                const newSummaryData = {
                    totalClients: res.data.clients_count,
                    totalProspects: res.data.prospect_count,
                    totalProducts: res.data.products_count,
                    totalSources: res.data.sources_count,
                    clients: res.data.recent_client,
                    prospects: res.data.recent_prospect,
                };
                setSummaryData(newSummaryData);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchConvertedClients = () => {
        authenticatedRequest()
            .get(get_clients_conversion(clientConvertedFrequency, clientConvertedStartDate, clientConvertedEndDate))
            .then((res) => {
                const x = res.data.clients_count.map((clientCountData) => {
                    const key = Object.keys(clientCountData)[0];
                    return {
                        date: clientConvertedFrequency === FREQUENCY_TYPES.WEEKLY ? key : moment(key).format('DD MMM'),
                        value: clientCountData[key],
                    };
                });

                setConvertedClients(x);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClientsPerSource = () => {
        authenticatedRequest()
            .get(get_clients_per_source)
            .then((res) => {
                let total = 0;
                const clientPerSources = [] as any;
                res.data.client_per_source.forEach((clientPerSource) => {
                    if (clientPerSource.client_count > 0) {
                        total += clientPerSource.client_count;
                        clientPerSources.push({
                            name: clientPerSource.source_name,
                            value: clientPerSource.client_count,
                        });
                    }
                });
                setClientsPerSource({
                    total,
                    data: clientPerSources,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onClientsFrequencyChange = (value) => {
        setClientConvertedFrequency(value);
    };

    const onClientsDateChange = (value) => {
        setClientConvertedStartDate(value[0].format('YYYY-MM-DD'));
        setClientConvertedEndDate(value[1].format('YYYY-MM-DD'));
    };

    const onClientRowClick = (record) => {
        history.push(`${ROUTES.VIEW_CLIENT.split(':').shift()}${record.id}`);
    };

    const onProspectRowClick = (record) => {
        history.push(`${ROUTES.VIEW_PROSPECT.split(':').shift()}${record.id}`);
    };

    useEffect(() => {
        getDashboardData();
        fetchConvertedClients();
        fetchClientsPerSource();
    }, []);

    useEffect(() => {
        fetchConvertedClients();
    }, [clientConvertedStartDate, clientConvertedEndDate, clientConvertedFrequency]);

    return (
        <AuthenticatedLayout title="Dashboard">
            <Row gutter={[16, 16]}>
                <Col xs={20} sm={16} md={12} lg={6} xl={6}>
                    <SummaryCard
                        title="All Clients"
                        value={summaryData.totalClients}
                        iconColor="0, 90, 135"
                        icon={<RiUser2Fill size={24} color="rgba(0, 90, 135)" />}
                        loading={loading}
                    />
                </Col>

                <Col xs={20} sm={16} md={12} lg={6} xl={6}>
                    <SummaryCard
                        title="All Prospects"
                        value={summaryData.totalProspects}
                        iconColor="239, 126, 35"
                        icon={<RiUser6Line size={24} color="rgba(239, 126, 35)" />}
                        loading={loading}
                    />
                </Col>

                <Col xs={20} sm={16} md={12} lg={6} xl={6}>
                    <SummaryCard
                        title="Sources"
                        value={summaryData.totalSources}
                        iconColor="47, 128, 237"
                        icon={<RiAdvertisementLine size={24} color="rgba(47, 128, 237)" />}
                        loading={loading}
                    />
                </Col>

                <Col xs={20} sm={16} md={12} lg={6} xl={6}>
                    <SummaryCard
                        title="Products"
                        value={summaryData.totalProducts}
                        iconColor="87, 84, 255"
                        icon={<RiCommunityLine size={24} color="rgba(87, 84, 255)" />}
                        loading={loading}
                    />
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title="Recently Added Clients">
                                <SuperiorHomesTable
                                    columns={columns}
                                    data={summaryData.clients}
                                    loading={loading}
                                    onRowClick={(record) => onClientRowClick(record)}
                                />
                            </Card>
                        </Col>

                        <Col span={24}>
                            <Card title="Clients Per Source">
                                <ClientsPerSource data={clientsPerSource} />
                            </Card>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card
                                title="Clients"
                                action={
                                    <>
                                        <RangePicker
                                            style={{ marginRight: 10 }}
                                            disabledDate={disabledDate}
                                            onChange={(value) => onClientsDateChange(value)}
                                        />
                                        <Select
                                            onChange={(value) => onClientsFrequencyChange(value)}
                                            defaultValue="Daily"
                                            style={{ width: '40%' }}
                                            options={[
                                                { label: FREQUENCY_TYPES.DAILY, value: FREQUENCY_TYPES.DAILY },
                                                { label: FREQUENCY_TYPES.WEEKLY, value: FREQUENCY_TYPES.WEEKLY },
                                                { label: FREQUENCY_TYPES.MONTHLY, value: FREQUENCY_TYPES.MONTHLY },
                                            ]}
                                        />
                                    </>
                                }
                            >
                                <ClientsChart data={convertedClients} />
                            </Card>
                        </Col>

                        <Col span={24}>
                            <Card title="Recently Added Prospects">
                                <SuperiorHomesTable
                                    columns={columns}
                                    data={summaryData.prospects}
                                    loading={loading}
                                    onRowClick={(record) => onProspectRowClick(record)}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </AuthenticatedLayout>
    );
};

export default Dashboard;
