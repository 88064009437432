import React, { useState } from 'react';
import { Modal, Row, Col, Form, notification, Input } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import SelectProspectType from '../../Selects/ProspectType';
import FloatingLabel from '../../FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { post_prospect } from '../../apis';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import email from '../Email';
import PhoneNumber from '../../PhoneNumber';
import SelectSource from '../../Selects/Source';
import SelectProduct from '../../Selects/Product';
import SelectHasSiteVisit from '../../Selects/SiteVisit';

interface Props {
    visible: boolean;
    lead: any;
    onClose: (success: boolean) => void;
}

const ConvertLeadToProspect = ({ visible, onClose, lead }: Props) => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSource, setSelectedSource] = useState<any>({});

    const onFinish = (values) => {
        setLoading(true);
        values['temp_id'] = lead?.id;
        if (lead.name) {
            values['name'] = lead?.name;
        }

        if (lead.email) {
            values['email'] = lead?.email;
        }

        if (lead.phone) {
            values['phone'] = lead?.phone;
        }

        if (lead.source_name) {
            values['source_id'] = lead?.source_id;
        }

        if (lead.product_name) {
            values.products = JSON.stringify([{ product_id: lead?.product_id }]);
        } else {
            values['products'] = values.product_id.map((product) => {
                return {
                    product_id: product,
                };
            });
            values.products = JSON.stringify(values.products);
        }

        values['relationship_manager_id'] = auth?.user?.id;
        delete values.product_id;
        authenticatedRequest()
            .post(post_prospect, values)
            .then(() => {
                setLoading(false);
                onClose(true);
            })
            .catch(() => {
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    return (
        <Modal
            visible={visible}
            title="Convert Lead To Prospect"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form onFinish={onFinish} layout="vertical">
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <p className="view-label">Name:</p>
                            </Col>
                            <Col>
                                <p className="view-description">{lead?.name}</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {lead?.email ? (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <p className="view-label">Email:</p>
                                </Col>
                                <Col>
                                    <p className="view-description">{lead?.email}</p>
                                </Col>
                            </Row>
                        ) : (
                            <FloatingLabel label="Email" form={form} formItemName="email">
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: 'email',
                                            message: 'Please input prospect email',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </FloatingLabel>
                        )}
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {lead?.phone ? (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <p className="view-label">Phone:</p>
                                </Col>
                                <Col>
                                    <p className="view-description">{lead?.phone}</p>
                                </Col>
                            </Row>
                        ) : (
                            <PhoneNumber
                                form={form}
                                required={true}
                                message="Please input prospect phone number"
                                formItemName="phone"
                            />
                        )}
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {lead?.source_name ? (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <p className="view-label">Source:</p>
                                </Col>
                                <Col>
                                    <p className="view-description">{lead?.source_name}</p>
                                </Col>
                            </Row>
                        ) : (
                            <FloatingLabel label="Source" form={form} formItemName="source_id">
                                <SelectSource onChange={(source) => setSelectedSource(source)} />
                            </FloatingLabel>
                        )}
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {lead?.product_name ? (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <p className="view-label">Product:</p>
                                </Col>
                                <Col>
                                    <p className="view-description">{lead?.product_name}</p>
                                </Col>
                            </Row>
                        ) : (
                            <FloatingLabel label="Products" form={form} formItemName="product_id">
                                <SelectProduct
                                    isFormList={false}
                                    name="product_id"
                                    mode="multiple"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a product',
                                        },
                                    ]}
                                />
                            </FloatingLabel>
                        )}
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        {lead?.was_walk_in ? (
                            <Row gutter={16}>
                                <Col span={6}>
                                    <p className="view-label">Walk In:</p>
                                </Col>
                                <Col>
                                    <p className="view-description">{lead?.was_walk_in ? 'Yes' : 'No'}</p>
                                </Col>
                            </Row>
                        ) : (
                            <FloatingLabel label="Was a site visit" form={form} formItemName="has_site_visit">
                                <SelectHasSiteVisit formItemName="has_site_visit" />
                            </FloatingLabel>
                        )}
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FloatingLabel label="Status" form={form} formItemName="prospect_type_id" classLabel="label">
                            <SelectProspectType />
                        </FloatingLabel>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Upload" loading={loading} />
                        </Form.Item>
                    </Col>
                    {/*<Col span={24}>*/}
                    {/*    <div className="view-interaction">*/}
                    {/*        <span className="interaction">{lead.interaction_comment}</span>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
            </Form>
        </Modal>
    );
};

export default ConvertLeadToProspect;
