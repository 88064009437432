import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import style from './styles';
import { FormInstance } from 'antd';
const FloatingLabelWrapper = styled.div`
    ${style}
`;

interface Props {
    children: ReactNode;
    label: string;
    form?: FormInstance;
    formItemName?: string;
    value?: string;
    classLabel?: 'label' | 'label label-float';
    isFormList?: boolean;
    formListName?: string;
    formFieldIndex?: number;
}
const FloatingLabel = ({
    children,
    label,
    form,
    formItemName,
    value,
    classLabel = 'label',
    isFormList = false,
    formListName,
    formFieldIndex = 0,
}: Props) => {
    const [labelClass, setLabelClass] = useState<'label label-float' | 'label'>('label');

    const getLabelClass = (focus) => {
        if (isFormList && formListName !== undefined && formItemName !== undefined) {
            // get the form values
            const formValues = form?.getFieldsValue();
            // get the specific form name
            value = formValues[formListName]?.[formFieldIndex]?.[formItemName];
            // get the specific item in the object
        } else if (!isFormList && form !== undefined && formItemName !== undefined) {
            value = form.getFieldValue(formItemName);
        }
        focus || (value && value.length !== 0) ? setLabelClass('label label-float') : setLabelClass('label');
    };

    useEffect(() => {
        setLabelClass(classLabel);
    }, [classLabel]);

    return (
        <FloatingLabelWrapper>
            <div
                className="float-label"
                onBlur={() => {
                    getLabelClass(false);
                }}
                onFocus={() => {
                    getLabelClass(true);
                }}
            >
                {children}
                <label className={labelClass}>{label}</label>
            </div>
        </FloatingLabelWrapper>
    );
};

export default FloatingLabel;
