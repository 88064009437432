import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Col, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import SectionDivider from '../../../components/SectionDivider';
import FloatingLabel from '../../../components/FloatingLabel';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import { get_single_email_template, post_email_template, update_email_template } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { mapErrorToFields } from '../../../components/Functions';

const CreateEmailTemplate = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [ckeditorData, setCkeditorData] = useState<any>();
    const [form] = Form.useForm();
    const { id } = useParams();

    const onFinish = (values) => {
        setLoading(true);
        values['email_template_body'] = ckeditorData;
        if (id) {
            updateEmailTemplate(values);
        } else {
            createEmailTemplate(values);
        }
    };

    const createEmailTemplate = (values) => {
        authenticatedRequest()
            .post(post_email_template, values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    const updateEmailTemplate = (values) => {
        authenticatedRequest()
            .post(update_email_template(id), values)
            .then(() => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };
    const fetchEmailTemplate = () => {
        authenticatedRequest()
            .get(get_single_email_template(id))
            .then((res) => {
                const emailTemplate = res?.data?.email_template?.[0];
                setCkeditorData(emailTemplate.email_template_body);
                form.setFieldsValue({
                    email_template_name: emailTemplate.email_template_name,
                    email_template_subject: emailTemplate.email_template_subject,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(ROUTES.EMAIL_TEMPLATES);
    };

    useEffect(() => {
        if (id) {
            fetchEmailTemplate();
        }
    }, []);
    return (
        <AuthenticatedLayout title="Department" titleTwo="New Department">
            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={id ? 'Email Template updated' : 'Email Template Added'}
                            description={`Email Template ${form.getFieldValue('email_template_name')} was ${
                                id ? 'updated' : 'added'
                            } successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Email Template Details:" />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Name"
                                        form={form}
                                        formItemName="email_template_name"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="email_template_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input email template name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FloatingLabel
                                        label="Subject"
                                        form={form}
                                        formItemName="email_template_subject"
                                        classLabel={id ? 'label label-float' : 'label'}
                                    >
                                        <Form.Item
                                            name="email_template_subject"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input email template subject',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        name="email_template_body"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input email body',
                                            },
                                        ]}
                                    >
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={ckeditorData}
                                            onBlur={(event, editor) => {
                                                setCkeditorData(editor.getData());
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={id ? `Update Email Template` : `Save Email Template`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['DEPARTMENT_CREATE'],
})(CreateEmailTemplate);
