import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { LoadingOutlined } from '@ant-design/icons';
import { RiEyeLine, RiUser2Fill, RiDeleteBin5Line, RiRefreshLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { Divider, notification, Tag } from 'antd';
import { deactivate_user, list_users, resend_activation_link } from '../../../components/apis';
import moment from 'moment';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        render: (_, record) => `${record.first_name} ${record.last_name}`,
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Roles',
        dataIndex: 'role_name',
    },
    {
        title: 'Active',
        dataIndex: 'active',
        // eslint-disable-next-line react/display-name
        render: (val, record) => {
            if (record.user_is_active === 1) {
                return <Tag color="success">Active</Tag>;
            }

            if (record.user_is_active === 2) {
                return <Tag color="warning">Pending</Tag>;
            }

            return <Tag color="error">Inactive</Tag>;
        },
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
];

const ListUsers = () => {
    const history = useHistory();
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendingActivationLink, setSendingActivationLink] = useState<any>();

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <>
                        <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />
                        {record.user_is_active === 1 ? (
                            <>
                                <Divider type="vertical" />
                                <RiDeleteBin5Line
                                    color="#EB5757"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deActivateUser(record.id);
                                    }}
                                />
                            </>
                        ) : record.user_is_active === 2 ? (
                            <>
                                <Divider type="vertical" />
                                {sendingActivationLink == record.id ? (
                                    <LoadingOutlined style={{ color: '#005A87' }} spin />
                                ) : (
                                    <RiRefreshLine
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            resendActivationLink(record.id);
                                        }}
                                    />
                                )}
                            </>
                        ) : null}
                    </>
                );
            },
        },
    ];

    const goToCreateUser = () => {
        history.push(ROUTES.CREATE_USER);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
        fetchUsers(1, 10, value);
    };

    const deActivateUser = (id) => {
        setLoading(true);
        authenticatedRequest()
            .get(deactivate_user(id))
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'User deactivated successfully',
                });
                fetchUsers();
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const resendActivationLink = (id) => {
        setSendingActivationLink(id);
        authenticatedRequest()
            .get(resend_activation_link(id))
            .then(() => {
                setSendingActivationLink(null);
                notification.success({
                    message: 'Success',
                    description: 'Activation link sent to user successfully',
                });
                fetchUsers();
            })
            .catch((error) => {
                setSendingActivationLink(null);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchUsers = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_users(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setUsers(res.data.users?.data);
                setTotal(res.data.users?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.VIEW_USER.split(':').shift()}${record.id}`);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <AuthenticatedLayout title="Users">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['USER_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New User"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateUser}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Users (${users.length})`}
                action={
                    <TableSearch
                        placeholder="Search users"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchUsers(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchUsers(page, pageSize)}
                    columns={newColumns}
                    data={users}
                    total={total}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['USER_LIST'],
})(ListUsers);
