import { css } from 'styled-components';

const style = () => css`
    .ant-table-tbody > tr:nth-child(odd) {
        background: rgba(245, 246, 250, 0.5) !important;
    }
    .ant-table-thead > tr > th {
        border-bottom: 1px solid #ebebeb;
        background-color: #ffffff;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 1px solid #ebebeb;
    }

    .footer {
        display: flex;
        .select-wrapper {
            display: flex;
            flex: 1;
            align-items: center;
        }
        .pagination-wrapper {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;
            .footer-span {
                margin-right: 20px;
            }
        }
    }

    .ant-pagination-item {
        border: none;
        background: transparent;
    }

    .ant-pagination-item-active a {
        color: #333333;
    }
`;

export default style;
