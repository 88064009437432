import { css } from 'styled-components';

const style = () => css`
    .icon-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .content-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        .title {
            font-weight: 800;
            font-size: 34px;
            line-height: 53px;
            color: #333333;
            margin-bottom: 20px;
        }
        .description {
            margin-top: 20px;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #333333;
        }
    }
    .action-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

export default style;
