import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import {
    RiChat1Fill,
    RiMessage3Fill,
    RiDownloadCloud2Fill,
    RiEyeLine,
    RiDeleteBin5Line,
    RiCheckFill,
} from 'react-icons/ri';
import { SuperiorHomesTable } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesBlueButton, SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { Card } from '../../../components/Cards';
import { Row, Col, notification, Tag, Divider } from 'antd';
import { authenticatedRequest } from '../../../api';
import {
    complete_client_product,
    delete_client_product,
    download_resource,
    get_client_products,
    get_client_resources,
    get_single_client,
} from '../../../components/apis';
import ViewInteraction from '../../../components/modals/ViewInteraction';
import NewInteraction from '../../../components/modals/NewInteraction';
import NewClientDocument from '../../../components/modals/ClientDocuments';
import fileDownload from 'js-file-download';
import EmailClient from '../../../components/modals/Email';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import NewProduct from '../../../components/modals/NewProduct';
import EditClient from '../../../components/modals/EditClient';
import SuccessModal from '../../../components/modals/success';
import { replaceUrlParams } from '../../../components/Functions';
import { ROUTES } from '../../../Router/urls';
import WarningModal from '../../../components/modals/success/WarningModal';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import ReassignClientsOrProspects from '../../../components/modals/ReassignClientsOrProspects';

const columns = [
    {
        title: 'Date',
        dataIndex: 'interaction_date',
        render: (value) => moment(value).format('DD MMM YYYY'),
    },
    {
        title: 'Type',
        dataIndex: 'interaction_type_name',
    },
    {
        title: 'Comments',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiChat1Fill color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const documentColumns = [
    {
        title: 'Name',
        dataIndex: 'resource_title',
    },
    {
        title: 'Type',
        dataIndex: 'resource_type_name',
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        render: (value) => moment(value).format('DD MMM YYYY'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
];

const productColumns = [
    {
        title: 'Name',
        dataIndex: 'development_name',
    },
    {
        title: 'Product Name',
        dataIndex: 'product_name',
    },
    {
        title: 'House Type',
        dataIndex: 'house_type_name',
    },
    {
        title: 'Price',
        dataIndex: 'initial_product_price',
        render: (val) => numeral(val).format('0,0.00'),
    },
    {
        title: 'Amount Paid',
        dataIndex: 'amount_paid',
        render: (val) => numeral(val).format('0,0.00'),
    },
    {
        title: 'House No.',
        dataIndex: 'house_number',
    },
    {
        title: 'Status',
        dataIndex: 'is_paying',
        // eslint-disable-next-line react/display-name
        render: (_, record) => {
            if (!record.is_paying) {
                return <Tag color="success">Payment Complete</Tag>;
            }
            if (record.is_paying) {
                return <Tag color="blue">Paying</Tag>;
            }

            return <Tag color="warning">Not Paying</Tag>;
        },
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
];

const ViewClient = () => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const history = useHistory();
    const [client, setClient] = useState<any>();
    const [selectedProduct, setSelectedProduct] = useState<{ id: string } | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [clientReassignType, setClientReassignType] = useState<'client' | 'prospect'>('client');
    const [showDeleteProduct, setShowDeleteProduct] = useState<boolean>(false);
    const [clientReassignVisible, setClientReassignVisible] = useState<boolean>(false);
    const [isDeletingProduct, setIsDeletingProduct] = useState<boolean>(false);
    const [showCompleteProduct, setShowCompleteProduct] = useState<boolean>(false);
    const [isCompletingProduct, setIsCompletingProduct] = useState<boolean>(false);
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
    const [loadingClientProducts, setLoadingClientProducts] = useState<boolean>(false);
    const [viewInteraction, setViewInteraction] = useState<boolean>(false);
    const [viewNewInteraction, setViewNewInteraction] = useState<boolean>(false);
    const [viewEmailClient, setViewEmailClient] = useState<boolean>(false);
    const [viewNewProduct, setViewNewProduct] = useState<boolean>(false);
    const [newDocument, setNewDocument] = useState<boolean>(false);
    const [editClient, setEditClient] = useState<boolean>(false);
    const [viewSuccessModal, setViewSuccessModal] = useState<boolean>(false);
    const [successTitle, setSuccessTitle] = useState<string>('');
    const [successDescription, setSuccessDescription] = useState<string>('');
    const [interaction, setInteraction] = useState<any>({});
    const [interactions, setInteractions] = useState<Array<any>>([]);
    const [clientDocuments, setClientDocuments] = useState<Array<any>>([]);
    const [clientProducts, setClientProducts] = useState<Array<any>>([]);
    const [selectedClientProduct, setSelectedClientProduct] = useState<any>({});
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false);
    const { id } = useParams();

    const newDocumentColumns = [
        ...documentColumns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <RiDownloadCloud2Fill
                        color="#2F80ED"
                        style={{ cursor: 'pointer' }}
                        onClick={() => downloadClientDocument(record)}
                    />
                );
            },
        },
    ];

    const newProductColumns = [
        ...productColumns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <>
                        <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />
                        <AuthorizationWrapper allowedPermissions={['PAYMENT_VIEW']}>
                            {!record.is_paying_completed ? (
                                <>
                                    <Divider type="vertical" />
                                    <RiCheckFill
                                        color="green"
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowCompleteProduct(true);
                                            setSelectedProduct(record);
                                        }}
                                    />
                                </>
                            ) : null}
                        </AuthorizationWrapper>

                        {!record.is_paying ? (
                            <>
                                <Divider type="vertical" />
                                <RiDeleteBin5Line
                                    color="red"
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDeleteProduct(true);
                                        setSelectedProduct(record);
                                    }}
                                />
                            </>
                        ) : null}
                    </>
                );
            },
        },
    ];

    const fetchClient = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_single_client(id))
            .then((res) => {
                setLoading(false);
                setClient(res.data.client[0]);
                setInteractions(res.data.interactions);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClientResource = () => {
        setLoadingDocuments(true);
        authenticatedRequest()
            .get(get_client_resources(id))
            .then((res) => {
                setLoadingDocuments(false);
                setClientDocuments(res?.data?.resources?.data);
            })
            .catch((error) => {
                setLoadingDocuments(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClientProducts = () => {
        setLoadingClientProducts(true);
        authenticatedRequest()
            .get(get_client_products(id))
            .then((res) => {
                setLoadingClientProducts(false);
                setClientProducts(res?.data?.client_products?.products);
            })
            .catch((error) => {
                setLoadingClientProducts(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const downloadClientDocument = (record) => {
        authenticatedRequest()
            .get(download_resource(record?.id), {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, `${record.resource_title}.${record?.resource_extension_type}`);
            })
            .catch((error) => {
                setLoadingDocuments(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onCompleteClientProduct = () => {
        setIsCompletingProduct(true);
        authenticatedRequest()
            .get(complete_client_product(selectedProduct?.id))
            .then(() => {
                fetchClientProducts();
                setIsCompletingProduct(false);
                setShowCompleteProduct(false);
            })
            .catch((error) => {
                setIsCompletingProduct(false);
                setShowCompleteProduct(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onDeleteClientProduct = () => {
        setIsDeletingProduct(true);
        authenticatedRequest()
            .get(delete_client_product(selectedProduct?.id))
            .then(() => {
                fetchClientProducts();
                setIsDeletingProduct(false);
                setShowDeleteProduct(false);
            })
            .catch((error) => {
                setIsDeletingProduct(false);
                setShowDeleteProduct(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        setInteraction(record);
        setViewInteraction(true);
    };

    const onCloseViewNewInteraction = (success) => {
        setViewNewInteraction(false);

        if (success) {
            fetchClient();
        }
    };

    const onAddNewInteraction = () => {
        setViewNewInteraction(true);
    };

    const onCloseViewInteractions = () => {
        setViewInteraction(false);
    };

    const onCloseNewDocument = (success) => {
        setNewDocument(false);

        if (success) {
            fetchClientResource();
        }
    };

    const onAddNewDocument = () => {
        setNewDocument(true);
    };

    const onAddNewProduct = () => {
        setSelectedClientProduct({});
        setViewNewProduct(true);
    };

    const onCloseNewProduct = (success) => {
        setViewNewProduct(false);
        setSelectedClientProduct({});
        if (success) {
            fetchClientProducts();
        }
    };

    const onProductSelected = (record) => {
        history.push(
            replaceUrlParams(ROUTES.VIEW_CLIENT_PRODUCT, [
                { param: ':id', value: id },
                { param: ':productId', value: record?.product_id },
                { param: ':clientProductId', value: record?.id },
            ]),
        );
    };

    const onEdit = () => {
        setEditClient(true);
    };

    const onCloseSuccessModal = () => {
        setViewSuccessModal(false);
    };

    const onCloseEditClient = (success) => {
        setEditClient(false);

        if (success) {
            setSuccessTitle('Client Updated');
            setSuccessDescription('Client updated successfully');
            setViewSuccessModal(true);
        }
    };

    const onReassign = (type) => {
        setClientReassignVisible(true);
        setClientReassignType(type);
    };

    const onCloseReassign = (success) => {
        setClientReassignVisible(false);
        if (success) {
            setSuccessTitle('Client Reassigned!');
            setSuccessDescription('Client updated successfully');
            setViewSuccessModal(true);
            history.push(ROUTES.CLIENTS);
        }
    };

    useEffect(() => {
        fetchClient();
        fetchClientResource();
        fetchClientProducts();
    }, []);

    useEffect(() => {
        fetchClient();
        fetchClientResource();
        fetchClientProducts();
    }, [history.location.pathname]);

    return (
        <AuthenticatedLayout title="Client" titleTwo={client?.name}>
            <WarningModal
                visible={showDeleteProduct}
                title="Delete Product"
                description={`Are you sure you want to delete this product from client ${client?.name}?`}
                onClose={() => setShowDeleteProduct(false)}
                onComplete={onDeleteClientProduct}
                onCompleteLoading={isDeletingProduct}
            />

            <WarningModal
                visible={showCompleteProduct}
                title="Product Payment Complete"
                description={`Are you sure you want to mark this product as complete?`}
                onClose={() => setShowCompleteProduct(false)}
                onComplete={onCompleteClientProduct}
                onCompleteLoading={isCompletingProduct}
            />

            <ViewInteraction visible={viewInteraction} interaction={interaction} onClose={onCloseViewInteractions} />

            <NewInteraction
                visible={viewNewInteraction}
                clientId={client?.id}
                onClose={(success) => onCloseViewNewInteraction(success)}
            />

            <NewClientDocument
                visible={newDocument}
                clientId={client?.id}
                onClose={(success) => onCloseNewDocument(success)}
            />

            <EmailClient
                visible={viewEmailClient}
                client={client}
                userEmail={auth?.user?.email}
                onClose={() => setViewEmailClient(false)}
            />

            <NewProduct
                visible={viewNewProduct}
                clientId={client?.id}
                onClose={(success) => onCloseNewProduct(success)}
                product={selectedClientProduct}
            />

            <EditClient visible={editClient} client={client} onClose={(success) => onCloseEditClient(success)} />

            <SuccessModal
                visible={viewSuccessModal}
                title={successTitle}
                description={successDescription}
                onClose={onCloseSuccessModal}
                onComplete={() => {
                    setViewSuccessModal(false);
                    fetchClient();
                    fetchClientResource();
                    fetchClientProducts();
                }}
            />

            <ReassignClientsOrProspects
                type={clientReassignType}
                currentUser={{
                    id: client?.relationship_manager_id,
                    first_name: client?.relationship_manager_first_name,
                    last_name: client?.relationship_manager_last_name,
                }}
                visible={clientReassignVisible}
                clients={[client?.id]}
                onClose={(success) => onCloseReassign(success)}
            />

            <Card
                title="Client Details:"
                action={
                    <>
                        <AuthorizationWrapper allowedPermissions={['USER_CREATE']} disabled={true}>
                            <SuperiorHomesOrangeButton
                                buttonName="Reassign"
                                style={{ marginRight: 10 }}
                                onClick={() => onReassign('client')}
                            />
                        </AuthorizationWrapper>
                        <SuperiorHomesOrangeButton
                            icon={<RiMessage3Fill color="#ffffff" style={{ marginRight: 10 }} />}
                            buttonName="Message Client"
                            onClick={() => setViewEmailClient(true)}
                        />

                        <SuperiorHomesBlueButton
                            style={{ marginLeft: 10 }}
                            buttonName="Edit"
                            onClick={() => onEdit()}
                        />
                    </>
                }
            >
                <Row gutter={16} style={{ padding: '20px 10px' }} className="row-with-gray-background">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flexDirection: 'row' }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{client?.name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Email:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{client?.email}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Phone Number:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{client?.phone}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-label">Source:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{client?.source_name}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <p className="view-label">Second Purchaser Name:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                                <p className="view-description">{client?.alternate_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <p className="view-label">Second Purchaser Email:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{client?.alternate_email}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <p className="view-label">Second Purchaser Phone:</p>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <p className="view-description">{client?.alternate_phone}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <p className="view-label">Site Visit:</p>
                            </Col>
                            <Col>
                                <p className="view-description">{client?.was_site_visit === 1 ? 'Yes' : 'No'}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <div style={{ marginTop: 20 }}>
                <Card
                    title="Documents"
                    action={
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <SuperiorHomesBlueButton buttonName="Add Document" onClick={onAddNewDocument} />
                        </div>
                    }
                >
                    <SuperiorHomesTable
                        columns={newDocumentColumns}
                        data={clientDocuments}
                        loading={loadingDocuments}
                    />
                </Card>
            </div>

            <div style={{ marginTop: 20 }}>
                <Card
                    title="Products"
                    action={
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <SuperiorHomesBlueButton buttonName="Add Product" onClick={onAddNewProduct} />
                        </div>
                    }
                >
                    <SuperiorHomesTable
                        columns={newProductColumns}
                        data={clientProducts}
                        withPagination={true}
                        loading={loadingClientProducts}
                        onRowClick={(record) => onProductSelected(record)}
                    />
                </Card>
            </div>

            <div style={{ marginTop: 20 }}>
                <Card
                    title="Interactions"
                    action={
                        <SuperiorHomesBlueButton
                            buttonName="New Interaction"
                            style={{ marginRight: 10 }}
                            onClick={onAddNewInteraction}
                        />
                    }
                >
                    <SuperiorHomesTable
                        columns={columns}
                        data={interactions}
                        onRowClick={(record) => onRowClick(record)}
                        withPagination={true}
                        loading={loading}
                    />
                </Card>
            </div>
        </AuthenticatedLayout>
    );
};

export default ViewClient;
