import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiPencilLine, RiUser2Fill } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification } from 'antd';
import { list_house_types } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';

const columns = [
    {
        title: 'Name',
        dataIndex: 'house_type_name',
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm A'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const ListHouseTypes = () => {
    const history = useHistory();
    const [sources, setSources] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const goToCreateHouseType = () => {
        history.push(ROUTES.CREATE_HOUSE_TYPE);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchHouseTypes(1, 10, value);
    };

    const fetchHouseTypes = (page = 1, perPage = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_house_types(page, perPage, search))
            .then((res) => {
                setLoading(false);
                setSources(res.data.house_types?.data);
                setTotal(res.data.house_types?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_HOUSE_TYPE.split(':').shift()}${record.id}`);
    };

    useEffect(() => {
        fetchHouseTypes();
    }, []);

    return (
        <AuthenticatedLayout title="House Types">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['HOUSE_TYPE_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New House Type"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateHouseType}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`House types (${sources.length})`}
                action={
                    <TableSearch
                        placeholder="Search house types"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchHouseTypes(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchHouseTypes(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={sources}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['HOUSE_TYPE_LIST'],
})(ListHouseTypes);
