import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';
import Logo from '../../assets/images/logo.svg';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 50, color: '#005A87' }} spin />;
interface LoaderProps {
    logo?: boolean;
}
const Loader: FunctionComponent<LoaderProps> = ({ logo }: LoaderProps) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '95vh',
            }}
        >
            {logo ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <img style={{ width: '10%' }} src={Logo} alt="Logo" />
                    <Spin style={{ marginTop: 20 }} indicator={antIcon} />
                </div>
            ) : (
                <Spin indicator={antIcon} />
            )}
        </div>
    );
};
export default Loader;
