import { css } from 'styled-components';

const style = ({ iconColor }) => css`
    .summary-card-container {
        background: #ffffff;
        border: 1px solid #ebeded;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        min-height: 100px;
        padding: 10px 20px;
        .content-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            .summary-title {
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: #707070;
            }
            .summary-content {
                font-weight: 800;
                font-size: 34px;
                line-height: 53px;
                color: #404040;
            }
        }
        .icon-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .icon-border {
                background: rgba(${iconColor}, 0.2);
                border: 1px solid rgba(${iconColor}, 0.6);
                box-sizing: border-box;
                border-radius: 10px;
                height: 60px;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .card-container {
        background: #ffffff;
        border: 1px solid #ebeded;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        min-height: 200px;
        flex-direction: column;
        .card-header {
            padding: 0px 10px;
            background-color: transparent;
            height: 60px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #ebebeb;
            .title-container {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                .title {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 25px;
                    color: #404040;
                }
            }
            .action-container {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
        .card-footer {
            background: transparent;
        }
    }
`;

export default style;
