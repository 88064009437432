import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, Input, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import SectionDivider from '../../SectionDivider';
import FloatingLabel from '../../FloatingLabel';
import PhoneNumber from '../../PhoneNumber';
import SelectSource from '../../Selects/Source';
import SelectHasSiteVisit from '../../Selects/SiteVisit';
import { authenticatedRequest } from '../../../api';
import { update_prospect } from '../../apis';
import { mapErrorToFields } from '../../Functions';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import SelectProspectType from '../../Selects/ProspectType';
interface Props {
    visible: boolean;
    prospect: any;
    onClose: (success: boolean) => void;
}

const EditProspect = ({ visible, onClose, prospect }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        setLoading(true);
        values['relationship_manager_id'] = prospect.relationship_manager_id;
        authenticatedRequest()
            .post(update_prospect(prospect?.id), values)
            .then(() => {
                setLoading(false);
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    useEffect(() => {
        if (prospect && Object.keys(prospect).length) {
            form.setFieldsValue(prospect);
        }
    });

    return (
        <Modal
            visible={visible}
            title="Update Prospect"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <SectionDivider title="Prospect Details:" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Prospect Name"
                            form={form}
                            formItemName="name"
                            classLabel="label label-float"
                        >
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input prospect name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Email" form={form} formItemName="email" classLabel="label label-float">
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please input prospect email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <PhoneNumber
                            form={form}
                            required={true}
                            message="Please input prospect phone number"
                            formItemName="phone"
                        />
                    </Col>

                    <Col span={24}>
                        <SectionDivider title="Source Details:" />
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Source"
                            form={form}
                            formItemName="source_id"
                            classLabel="label label-float"
                        >
                            <SelectSource />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Was a site visit"
                            form={form}
                            formItemName="was_site_visit"
                            classLabel="label label-float"
                        >
                            <SelectHasSiteVisit formItemName="was_site_visit" />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Status"
                            form={form}
                            formItemName="prospect_type_id"
                            classLabel="label label-float"
                        >
                            <SelectProspectType />
                        </FloatingLabel>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Update Prospect" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EditProspect;
