import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SuperiorHomesBlueButton, SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { RiDeleteBin5Line, RiEyeLine, RiMessage3Fill } from 'react-icons/ri';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { authenticatedRequest } from '../../../api';
import { Col, DatePicker, Divider, notification, Row, Select, Tag } from 'antd';
import {
    admin_list_clients,
    admin_list_prospects,
    deactivate_client_or_prospect,
    deactivate_user,
    get_user,
    get_user_clients_conversion,
    get_user_clients_per_source,
    get_user_dashboard,
    resend_activation_link,
} from '../../../components/apis';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import { ROUTES } from '../../../Router/urls';
import ClientsPerSource from '../../../components/Charts/ClientsPerSource';
import { FREQUENCY_TYPES } from '../../../components/enums';
import ClientsChart from '../../../components/Charts/Clients';
import moment from 'moment';
import ReassignClientsOrProspects from '../../../components/modals/ReassignClientsOrProspects';
import SuccessModal from '../../../components/modals/success';
import { titleCase } from '../../../components/TitleCase';
import { LoadingOutlined } from '@ant-design/icons';
import { replaceUrlParams } from '../../../components/Functions';
import { reports } from '../../Reports/View/data';

const { RangePicker } = DatePicker;

const columns = [
    {
        title: 'Client Name',
        dataIndex: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone',
    },
];

interface dataProps {
    totalClients: number;
    totalProspects: number;
    totalSources: number;
    totalProducts: number;
    clients: Array<any>;
    prospects: Array<any>;
}

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}
const ViewUser = () => {
    const history = useHistory();
    const [user, setUser] = useState<any>();
    const [clients, setClients] = useState([]);
    const [clientTotal, setClientTotal] = useState(0);
    const [prospects, setProspects] = useState(0);
    const [prospectTotal, setProspectTotal] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [clientsLoading, setClientsLoading] = useState<boolean>(false);
    const [prospectsLoading, setProspectsLoading] = useState<boolean>(false);
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false);
    const [clientsPerSource, setClientsPerSource] = useState({ total: 0, data: [] });
    const [convertedClients, setConvertedClients] = useState([]);
    const [sendingActivationLink, setSendingActivationLink] = useState<boolean>(false);
    const [deactivatingUser, setDeactivatingUser] = useState<boolean>(false);
    const [clientConvertedFrequency, setClientConvertedFrequency] = useState<FREQUENCY_TYPES>(FREQUENCY_TYPES.DAILY);
    const [clientConvertedStartDate, setClientConvertedStartDate] = useState(
        moment().startOf('month').format('YYYY-MM-DD'),
    );
    const [clientConvertedEndDate, setClientConvertedEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [summaryData, setSummaryData] = useState<dataProps>({
        totalClients: 0,
        totalProspects: 0,
        totalProducts: 0,
        totalSources: 0,
        clients: [],
        prospects: [],
    });
    const [clientReassignButtonVisible, setClientReassignButtonVisible] = useState<boolean>(false);
    const [prospectReassignButtonVisible, setProspectReassignButtonVisible] = useState<boolean>(false);
    const [clientReassignVisible, setClientReassignVisible] = useState<boolean>(false);
    const [clientsOrProspectsToBeReassigned, setClientsOrProspectsToBeReassigned] = useState<Array<string>>([]);
    const [clientReassignType, setClientReassignType] = useState<'client' | 'prospect'>('client');
    const [deactivatingClientOrProspect, setDeactivatingClientOrProspect] = useState<any>();
    const { id } = useParams();

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <>
                        <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />
                        <Divider type="vertical" />
                        {deactivatingClientOrProspect == record.id ? (
                            <LoadingOutlined style={{ color: '#005A87' }} spin />
                        ) : (
                            <RiDeleteBin5Line
                                color="#EB5757"
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deActivateClientOrProspect(record.id);
                                }}
                            />
                        )}
                    </>
                );
            },
        },
    ];

    const getDashboardData = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_user_dashboard(id))
            .then((res) => {
                setLoading(false);
                const newSummaryData = {
                    totalClients: res.data.clients_count,
                    totalProspects: res.data.prospect_count,
                    totalProducts: res.data.products_count,
                    totalSources: res.data.sources_count,
                    clients: res.data.recent_client,
                    prospects: res.data.recent_prospect,
                };
                setSummaryData(newSummaryData);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchUserProspects = (page = 1, pageSize = 10) => {
        setProspectsLoading(true);
        authenticatedRequest()
            .get(admin_list_prospects(id, page, pageSize))
            .then((res) => {
                const newProspects = res.data.prospects?.data.map((prospect, index) => {
                    return {
                        key: prospect.id,
                        ...prospect,
                    };
                });
                setProspects(newProspects);
                setProspectTotal(res.data.prospects?.total);
                setProspectsLoading(false);
            })
            .catch((error) => {
                setProspectsLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const deActivateClientOrProspect = (id) => {
        setDeactivatingClientOrProspect(id);
        authenticatedRequest()
            .get(deactivate_client_or_prospect(id))
            .then(() => {
                setDeactivatingClientOrProspect(null);
                notification.success({
                    message: 'Client/Prospect deactivated',
                    description: 'Success',
                });
                fetchUserProspects();
                fetchUserClients();
            })
            .catch((error) => {
                setDeactivatingClientOrProspect(null);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const deActivateUser = (id) => {
        setDeactivatingUser(true);
        authenticatedRequest()
            .get(deactivate_user(id))
            .then(() => {
                setDeactivatingUser(false);
                notification.success({
                    message: 'Success',
                    description: 'User deactivated successfully',
                });
                fetchUser();
            })
            .catch((error) => {
                setDeactivatingUser(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const resendActivationLink = (id) => {
        setSendingActivationLink(true);
        authenticatedRequest()
            .get(resend_activation_link(id))
            .then(() => {
                setSendingActivationLink(false);
                notification.success({
                    message: 'Success',
                    description: 'Activation link sent to user successfully',
                });
                fetchUser();
            })
            .catch((error) => {
                setSendingActivationLink(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchUserClients = (page = 1, perPage = 10, search = null) => {
        setClientsLoading(true);
        authenticatedRequest()
            .get(admin_list_clients(id, page, perPage, search))
            .then((res) => {
                const newClients = res.data.clients?.data.map((client) => {
                    return {
                        key: client.id,
                        ...client,
                    };
                });
                setClients(newClients);
                setClientTotal(res.data.clients?.total);
                setClientsLoading(false);
            })
            .catch((error) => {
                setClientsLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchConvertedClients = () => {
        authenticatedRequest()
            .get(
                get_user_clients_conversion(
                    clientConvertedFrequency,
                    clientConvertedStartDate,
                    clientConvertedEndDate,
                    id,
                ),
            )
            .then((res) => {
                const x = res.data.clients_count.map((clientCountData) => {
                    const key = Object.keys(clientCountData)[0];
                    return {
                        date: clientConvertedFrequency === FREQUENCY_TYPES.WEEKLY ? key : moment(key).format('DD MMM'),
                        value: clientCountData[key],
                    };
                });

                setConvertedClients(x);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onClientRowClick = (record) => {
        history.push(`${ROUTES.VIEW_CLIENT.split(':').shift()}${record.id}`);
    };

    const onEditUser = () => {
        history.push(replaceUrlParams(ROUTES.EDIT_USER, [{ param: ':id', value: id }]));
    };

    const onClientsSearch = (value) => {
        fetchUserClients(1, 10, value);
    };
    const onClientsSearchChange = (value) => {
        fetchUserClients(1, 10, value);
    };

    const onClientsSelected = (type, records) => {
        setClientsOrProspectsToBeReassigned(records);
        if (type === 'client') {
            records.length ? setClientReassignButtonVisible(true) : setClientReassignButtonVisible(false);
            return;
        }

        if (type === 'prospect') {
            records.length ? setProspectReassignButtonVisible(true) : setProspectReassignButtonVisible(false);
            return;
        }
    };

    const onReassign = (type) => {
        setClientReassignVisible(true);
        setClientReassignType(type);
    };

    const onCloseReassign = (success) => {
        setClientReassignVisible(false);
        if (success) {
            setSuccessModalVisible(true);
        }
    };

    const fetchUser = () => {
        authenticatedRequest()
            .get(get_user(id))
            .then((res) => {
                setUser(res?.data?.user?.[0]);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchClientsPerSource = () => {
        authenticatedRequest()
            .get(get_user_clients_per_source(id))
            .then((res) => {
                let total = 0;
                const clientPerSources = [] as any;
                res.data.client_per_source.forEach((clientPerSource) => {
                    if (clientPerSource.client_count > 0) {
                        total += clientPerSource.client_count;
                        clientPerSources.push({
                            name: clientPerSource.source_name,
                            value: clientPerSource.client_count,
                        });
                    }
                });
                setClientsPerSource({
                    total,
                    data: clientPerSources,
                });
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onClientsFrequencyChange = (value) => {
        setClientConvertedFrequency(value);
    };

    const onClientsDateChange = (value) => {
        setClientConvertedStartDate(value[0].format('YYYY-MM-DD'));
        setClientConvertedEndDate(value[1].format('YYYY-MM-DD'));
    };

    const onProspectRowClick = (record) => {
        history.push(`${ROUTES.VIEW_PROSPECT.split(':').shift()}${record.id}`);
    };

    const onCloseSuccessModal = (success) => {
        setSuccessModalVisible(false);
        if (success) {
            fetchUserClients(1);
            fetchUserProspects(1);
        }
    };

    useEffect(() => {
        if (id !== null) {
            fetchUser();
            fetchUserProspects();
            fetchUserClients();
            fetchClientsPerSource();
            fetchConvertedClients();
        }
    }, []);

    return (
        <AuthenticatedLayout title="User" titleTwo={`${user?.first_name} ${user?.last_name}`}>
            <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'flex-end' }}>
                {user?.user_is_active === 1 ? (
                    <SuperiorHomesBlueButton
                        buttonName="Deactivate User"
                        style={{ marginRight: 10 }}
                        onClick={() => deActivateUser(id)}
                        loading={deactivatingUser}
                    />
                ) : user?.user_is_active === 2 ? (
                    <SuperiorHomesOrangeButton
                        icon={<RiMessage3Fill color="#ffffff" style={{ marginRight: 10 }} />}
                        buttonName="Resend Invite"
                        onClick={() => resendActivationLink(id)}
                        loading={sendingActivationLink}
                    />
                ) : null}
                <SuperiorHomesOrangeButton buttonName="Edit User" onClick={onEditUser} />
            </div>

            <ReassignClientsOrProspects
                type={clientReassignType}
                currentUser={user}
                visible={clientReassignVisible}
                clients={clientsOrProspectsToBeReassigned}
                onClose={(success) => onCloseReassign(success)}
            />

            <SuccessModal
                visible={successModalVisible}
                title={`${titleCase(clientReassignType)} reassigned`}
                description={`${titleCase(clientReassignType)} reassigned from ${user?.first_name} ${user?.last_name}`}
                onClose={() => onCloseSuccessModal(false)}
                onComplete={() => onCloseSuccessModal(true)}
            />

            <Card title="User Details:">
                <Row gutter={16} style={{ padding: '20px 10px' }} className="row-with-gray-background">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ flexDirection: 'row' }}>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Name:</p>
                            </Col>
                            <Col>
                                <p className="view-description">
                                    {user?.first_name} {user?.last_name}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Email:</p>
                            </Col>
                            <Col>
                                <p className="view-description">{user?.email}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Work Phone Number:</p>
                            </Col>
                            <Col>
                                <p className="view-description">{user?.work_phone}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Personal Phone Number:</p>
                            </Col>
                            <Col>
                                <p className="view-description">{user?.personal_phone}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Created By:</p>
                            </Col>
                            <Col>
                                <p className="view-description">{user?.created_by_name}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Status:</p>
                            </Col>
                            <Col>
                                {user?.user_is_active === 1 ? (
                                    <Tag color="success">Active</Tag>
                                ) : user?.user_is_active === 2 ? (
                                    <Tag color="warning">Pending</Tag>
                                ) : (
                                    <Tag color="error">Inactive</Tag>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <p className="view-label">Role:</p>
                            </Col>
                            <Col>
                                <p className="view-description">-</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>

            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Clients Per Source">
                        <ClientsPerSource data={clientsPerSource} />
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card
                        title="Clients"
                        action={
                            <>
                                <RangePicker
                                    style={{ marginRight: 10 }}
                                    disabledDate={disabledDate}
                                    onChange={(value) => onClientsDateChange(value)}
                                />
                                <Select
                                    onChange={(value) => onClientsFrequencyChange(value)}
                                    defaultValue="Daily"
                                    style={{ width: '40%' }}
                                    options={[
                                        { label: FREQUENCY_TYPES.DAILY, value: FREQUENCY_TYPES.DAILY },
                                        { label: FREQUENCY_TYPES.WEEKLY, value: FREQUENCY_TYPES.WEEKLY },
                                        { label: FREQUENCY_TYPES.MONTHLY, value: FREQUENCY_TYPES.MONTHLY },
                                    ]}
                                />
                            </>
                        }
                    >
                        <ClientsChart data={convertedClients} />
                    </Card>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card
                        title="Clients"
                        action={
                            <>
                                <TableSearch
                                    placeholder="Search clients"
                                    onSearch={(value) => onClientsSearch(value)}
                                    onChange={(value) => onClientsSearchChange(value)}
                                />
                                {clientReassignButtonVisible ? (
                                    <SuperiorHomesBlueButton
                                        buttonName="Reassign"
                                        onClick={() => onReassign('client')}
                                    />
                                ) : null}
                            </>
                        }
                    >
                        <SuperiorHomesTable
                            withPagination={true}
                            columns={newColumns}
                            data={clients}
                            total={clientTotal}
                            loading={clientsLoading}
                            onRowClick={(record) => onClientRowClick(record)}
                            onRecordSelected={(selectedRecords) => onClientsSelected('client', selectedRecords)}
                            onPageChange={(page, pageSize) => fetchUserClients(page, pageSize)}
                            onPageSizeChange={(page, pageSize) => fetchUserClients(page, pageSize)}
                        />
                    </Card>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card
                        title="Prospects"
                        action={
                            prospectReassignButtonVisible ? (
                                <SuperiorHomesBlueButton buttonName="Reassign" onClick={() => onReassign('prospect')} />
                            ) : null
                        }
                    >
                        <SuperiorHomesTable
                            withPagination={true}
                            columns={newColumns}
                            data={prospects}
                            total={prospectTotal}
                            loading={prospectsLoading}
                            onRowClick={(record) => onProspectRowClick(record)}
                            onRecordSelected={(selectedRecords) => onClientsSelected('prospect', selectedRecords)}
                            onPageChange={(page, pageSize) => fetchUserProspects(page, pageSize)}
                            onPageSizeChange={(page, pageSize) => fetchUserProspects(page, pageSize)}
                        />
                    </Card>
                </Col>
            </Row>
        </AuthenticatedLayout>
    );
};

export default ViewUser;
