import React from 'react';
import styled from 'styled-components';
import style from './styles';
const DividerWrapper = styled.div`
    ${style}
`;

const SuperiorHomesDivider = () => {
    return (
        <DividerWrapper>
            <div className="line" />
        </DividerWrapper>
    );
};

export default SuperiorHomesDivider;
