import React from 'react';
import { Modal, Row, Col } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import moment from 'moment';

interface Props {
    visible: boolean;
    interaction: any;
    onClose: () => void;
}
const ViewInteraction = ({ visible, onClose, interaction }: Props) => {
    return (
        <Modal
            visible={visible}
            title="Interaction History"
            onCancel={onClose}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={16}>
                        <Col span={4}>
                            <p className="view-label">Date:</p>
                        </Col>
                        <Col>
                            <p className="view-description">
                                {moment(interaction.interaction_date).format('DD MMM YYYY')}
                            </p>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row gutter={16}>
                        <Col span={4}>
                            <p className="view-label">Type:</p>
                        </Col>
                        <Col>
                            <p className="view-description">{interaction.interaction_type_name}</p>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <div className="view-interaction">
                        <span className="interaction">{interaction.interaction_comment}</span>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default ViewInteraction;
