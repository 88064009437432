import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_email_templates } from '../../apis';

interface Props {
    onChange: (template) => void;
}
const SelectEmailTemplate = ({ onChange }: Props) => {
    const [emailTemplates, setEmailTemplates] = useState<any>([]);

    const fetchEmailTemplates = () => {
        authenticatedRequest()
            .get(list_email_templates(1, 100))
            .then((res) => {
                const emailTemplates = res.data.email_templates?.data.map((emailTemplate) => {
                    return {
                        value: emailTemplate.id.toString(),
                        label: emailTemplate.email_template_name,
                        subject: emailTemplate.email_template_subject,
                        body: emailTemplate.email_template_body,
                    };
                });
                setEmailTemplates(emailTemplates);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchEmailTemplates();
    }, []);

    return (
        <Form.Item
            name="email_template_id"
            rules={[
                {
                    required: true,
                    message: 'Please select email template',
                },
            ]}
        >
            <Select
                allowClear
                showSearch
                optionFilterProp="label"
                options={emailTemplates}
                onSelect={(_, option) => onChange(option)}
            />
        </Form.Item>
    );
};

export default SelectEmailTemplate;
