import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiCommunityLine, RiPencilLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { list_projects } from '../../../components/apis';
import { notification } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';

const columns = [
    {
        title: 'Name',
        dataIndex: 'development_name',
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Created On',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const ListProjects = () => {
    const history = useHistory();
    const [projects, setProjects] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const goToCreateProject = () => {
        history.push(ROUTES.CREATE_PROJECT);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchProjects(1, 10, value);
    };

    const fetchProjects = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_projects(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setProjects(res.data.developments?.data);
                setTotal(res.data.developments?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.EDIT_PROJECT.split(':').shift()}${record.id}`);
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <AuthenticatedLayout title="Projects">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['DEVELOPMENT_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Project"
                        icon={<RiCommunityLine color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateProject}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Projects ${projects.length}`}
                action={
                    <TableSearch
                        placeholder="Search projects"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchProjects(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchProjects(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={projects}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => onRowClick(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['DEVELOPMENT_LIST'],
})(ListProjects);
