import { css } from 'styled-components';

const style = () => css`
    /* Force update ant style */
    .ant-input {
        padding: 20px 12px 4px 11px;
    }

    .ant-select .ant-select-selector {
        padding: 20px 10px 4px 11px;
    }

    .ant-picker-input {
        padding: 20px 10px 4px 0px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 20px 10px 4px 11px;
        height: 45px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
        top: 16px;
    }

    .float-label {
        position: relative;
        margin-bottom: 12px;
    }

    .label {
        position: absolute;
        pointer-events: none;
        left: 12px;
        top: 15px;
        transition: 0.2s ease all;
    }

    .label-float {
        top: 6px;
        font-size: 10px;
    }
`;

export default style;
