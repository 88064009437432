import { css } from 'styled-components';

const style = () => css`
    .ant-select-selection-search-input {
        height: 45px;
    }
    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom: 1px solid #f3f4f8;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        right: 0px;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 12px 0px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        //background-color: #f4f8f9;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 16px 0px;
        margin: 0px 15px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: #39426a;
        font-size: 14px;
        border-radius: 0px;
        margin: 0px 15px;
    }

    .ant-collapse.ant-collapse-icon-position-right.ant-collapse-ghost.accordion {
        padding: 0px 0px !important;
    }
    .button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

export default style;
