import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import style from './styles';
const CardWrapper = styled.div`
    ${style}
`;

interface SummaryCardProps {
    title: string;
    value: string | number;
    iconColor: string;
    icon: any;
    loading?: boolean;
}
const SummaryCard = ({ title, value, iconColor, icon, loading = false }: SummaryCardProps) => {
    return (
        <CardWrapper iconColor={iconColor}>
            <div className="summary-card-container">
                <div className="content-container">
                    {loading ? <Skeleton count={1} /> : <span className="summary-content">{value}</span>}
                    <p className="summary-title">{title}</p>
                </div>
                <div className="icon-container">
                    <div className="icon-border">{icon}</div>
                </div>
            </div>
        </CardWrapper>
    );
};

interface CardProps {
    title?: string;
    children: ReactNode;
    footer?: ReactNode;
    action?: ReactNode;
}
const Card = ({ title, children, footer, action }: CardProps) => {
    return (
        <CardWrapper>
            <div className="card-container">
                {title || action ? (
                    <div className="card-header">
                        <div className="title-container">
                            <span className="title">{title}</span>
                        </div>
                        <div className="action-container">{action}</div>
                    </div>
                ) : null}
                <div>{children}</div>
                {footer ? <div className="card-footer">{footer}</div> : null}
            </div>
        </CardWrapper>
    );
};
export { SummaryCard, Card };
