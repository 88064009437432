import React, { useEffect, useState } from 'react';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { list_reassign_requests, update_reassignment } from '../../../components/apis';
import { Divider, notification, Tag } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import moment from 'moment';
import WarningModal from '../../../components/modals/success/WarningModal';

const columns = [
    {
        title: 'Client Name',
        dataIndex: 'client_name',
    },
    {
        title: 'Current RM',
        dataIndex: 'development_name',
        render: (_, record) =>
            `${record.client_relationship_manager_first_name} ${record.client_relationship_manager_last_name}`,
    },
    {
        title: 'New RM',
        dataIndex: 'house_type_name',
        render: (_, record) =>
            `${record.reassignment_relationship_manager_first_name} ${record.reassignment_relationship_manager_last_name}`,
    },
    {
        title: 'Status',
        dataIndex: 'is_approved',
        // eslint-disable-next-line react/display-name
        render: (val: number) =>
            val === 0 ? (
                <Tag color="warning">Pending</Tag>
            ) : val === 1 ? (
                <Tag color="success">Reassigned</Tag>
            ) : (
                <Tag color="error">Declined</Tag>
            ),
    },
    {
        title: 'Created On',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
];

const ListReassignRequests = () => {
    const [reassignRequests, setReassignRequests] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [status, setStatus] = useState<'approving' | 'declining'>();

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchReassignRequests(1, 10, value);
    };

    const fetchReassignRequests = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_reassign_requests(page, pageSize))
            .then((res) => {
                setLoading(false);
                setReassignRequests(res.data.clients?.data);
                setTotal(res.data.clients?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            dataIndex: 'is_approved',
            // eslint-disable-next-line react/display-name
            render: (val: number, record) => {
                return val === 0 ? (
                    <div style={{ display: 'inline-flex', justifyContent: 'center' }}>
                        <RiCheckLine
                            color="#2F80ED"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setId(record?.id);
                                setStatus('approving');
                                setVisible(true);
                                setTitle('Reassign');
                                setDescription(
                                    `Are you sure you want to reassign ${record?.client_name} from ${record.client_relationship_manager_first_name} ${record.client_relationship_manager_last_name} to ${record.reassignment_relationship_manager_first_name} ${record.reassignment_relationship_manager_last_name}?`,
                                );
                            }}
                        />
                        <Divider type="vertical" />
                        <RiCloseLine
                            color="red"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setId(record?.id);
                                setStatus('declining');
                                setVisible(true);
                                setTitle('Reassign');
                                setDescription(
                                    `Are you sure you want to decline reassigning of ${record?.client_name} from ${record.client_relationship_manager_first_name} ${record.client_relationship_manager_last_name} to ${record.reassignment_relationship_manager_first_name} ${record.reassignment_relationship_manager_last_name}?`,
                                );
                            }}
                        />
                    </div>
                ) : null;
            },
        },
    ];

    const onComplete = () => {
        setIsUpdating(true);
        authenticatedRequest()
            .patch(update_reassignment(id as string), {
                is_approved: status === 'approving' ? 1 : 2,
            })
            .then(() => {
                setIsUpdating(false);
                fetchReassignRequests();
                setVisible(false);
                notification.success({
                    message: 'Success',
                    description: status === 'approving' ? 'Client reassigned successfully' : 'Client not reassigned',
                });
            })
            .catch(() => {
                setIsUpdating(false);
                setVisible(false);
                notification.error({
                    message: 'Error',
                    description: 'Unable to reassign client',
                });
            });
    };

    useEffect(() => {
        fetchReassignRequests();
    }, []);

    return (
        <AuthenticatedLayout title="Reassign Requests">
            <WarningModal
                visible={visible}
                title={title}
                description={description}
                onClose={() => setVisible(false)}
                onComplete={onComplete}
                onCompleteLoading={isUpdating}
                showCancelButton={true}
                completeButton={status === 'approving' ? 'Reassign' : 'Don`t Reassign'}
            />
            <Card
                title={`Reassign Requests ${reassignRequests.length}`}
                action={
                    <TableSearch
                        placeholder="Search products"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchReassignRequests(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchReassignRequests(page, pageSize)}
                    total={total}
                    columns={newColumns}
                    data={reassignRequests}
                    withPagination={true}
                    loading={loading}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['USER_LIST'],
})(ListReassignRequests);
