import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { RiEyeLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { approve_upcoming_payments, get_upcoming_payments } from '../../../components/apis';
import { notification, Tag } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import moment from 'moment';
import WarningModal from '../../../components/modals/success/WarningModal';

const columns = [
    {
        title: 'Client Names',
        dataIndex: 'name',
    },
    {
        title: 'Amount',
        dataIndex: 'payment_amount',
        render: (value) => numeral(value).format('0,0.00'),
    },
    {
        title: 'Payment Date',
        dataIndex: 'payment_date',
        render: (value) => moment(value).format('DD MMM YYYY'),
    },
    {
        title: 'Status',
        dataIndex: 'is_approved',
        // eslint-disable-next-line react/display-name
        render: (value) => {
            if (value === 1) {
                return <Tag color="success">Approved</Tag>;
            }

            return <Tag color="warning">Awaiting Approval</Tag>;
        },
    },
    {
        title: 'Approved By',
        dataIndex: 'approved_by_name',
    },
    {
        title: 'Created On',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
];

const ListUpcomingPayments = () => {
    const [upcomingPayments, setUpcomingPayments] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [showUpcomingPayment, setShowUpcomingPayment] = useState<boolean>(false);
    const [isApprovingPayment, setIsApprovePayment] = useState<boolean>(false);
    const [currentId, setCurrentId] = useState<string | null>(null);
    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchUpcomingPayments(1, 10, value);
    };

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: () => {
                return (
                    <>
                        <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />
                    </>
                );
            },
        },
    ];

    const approveUpcomingPayment = (record) => {
        setCurrentId(record?.id);
        setShowUpcomingPayment(true);
    };

    const fetchUpcomingPayments = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(get_upcoming_payments(page, pageSize))
            .then((res) => {
                setLoading(false);
                setUpcomingPayments(res.data.upcomingPayments?.data);
                setTotal(res.data.upcomingPayments?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const approvePayment = () => {
        setIsApprovePayment(true);
        authenticatedRequest()
            .get(approve_upcoming_payments(currentId))
            .then(() => {
                setShowUpcomingPayment(false);
                setIsApprovePayment(false);
                setCurrentId(null);
                fetchUpcomingPayments();
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchUpcomingPayments();
    }, []);

    return (
        <AuthenticatedLayout title="Upcoming Payments">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }} />
            <WarningModal
                visible={showUpcomingPayment}
                title="Approve Payment"
                description="Are you sure you want to approve this payment?"
                onClose={() => {
                    setCurrentId(null);
                    setShowUpcomingPayment(false);
                }}
                onComplete={() => approvePayment()}
                onCompleteLoading={isApprovingPayment}
            />
            <Card
                title={`Upcoming Payments ${total}`}
                action={
                    <TableSearch
                        placeholder="Search upcoming payments"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchUpcomingPayments(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchUpcomingPayments(page, pageSize)}
                    total={total}
                    columns={newColumns}
                    data={upcomingPayments}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => approveUpcomingPayment(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_LIST'],
})(ListUpcomingPayments);
