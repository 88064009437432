import { checkPermittedRoles, RolesType } from './AuthorizationWrapper';

export const checkRoutePermission = (user: any, allowedPermissions) => {
    if (!allowedPermissions) return true;
    if (allowedPermissions.length === 0) return true;

    const userPermissions: RolesType | RolesType[] = user.permissions || [];

    const _allowedPermissions: RolesType[] = Array.isArray(allowedPermissions)
        ? allowedPermissions
        : [allowedPermissions];

    return checkPermittedRoles(userPermissions, _allowedPermissions);
};
