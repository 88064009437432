import React, { useEffect, useState } from 'react';
import { Col, Form, Input, notification, Row } from 'antd';
import Avatar from 'react-avatar';
import FloatingLabel from '../../../../components/FloatingLabel';
import PhoneNumber from '../../../../components/PhoneNumber';
import { SuperiorHomesBlueButton } from '../../../../components/Buttons';
import { Card } from '../../../../components/Cards';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducers';
import { authenticatedRequest } from '../../../../api';
import { update_user } from '../../../../components/apis';
import { signActions } from '../../../SignIn/store/actions';
import SuccessModal from '../../../../components/modals/success';

const UserDetails = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const auth: any = useSelector((state: RootState) => state.auth);
    const [visible, setVisible] = useState<boolean>(false);
    const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);

    const fetchUser = () => {
        const data = {
            first_name: auth?.user?.firstName,
            last_name: auth?.user?.lastName,
            email: auth?.user?.email,
            personal_phone: auth?.user?.personalPhone,
            work_phone: auth?.user?.workPhone,
        };
        form.setFieldsValue(data);
    };

    const onFinish = (values) => {
        values.department_id = auth?.user?.departmentId;
        setIsUpdatingUser(true);
        authenticatedRequest()
            .post(update_user, values)
            .then(() => {
                setIsUpdatingUser(false);
                const user = {
                    firstName: values.first_name,
                    lastName: values.last_name,
                    email: auth?.user?.email,
                    personalPhone: values.personal_phone,
                    workPhone: values.work_phone,
                    departmentId: values.department_id,
                    permissions: auth?.user?.permissions,
                };
                dispatch(signActions.onUserSignInSuccess(user));
                setVisible(true);
            })
            .catch((error) => {
                setIsUpdatingUser(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <Card title="User">
            <SuccessModal
                visible={visible}
                onClose={onComplete}
                onComplete={onComplete}
                title="User Updated"
                description={`User ${form.getFieldValue('first_name')} ${form.getFieldValue(
                    'last_name',
                )} updated successfully`}
            />
            <Row gutter={[16, 16]} style={{ padding: 20 }}>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar size="150" name={`${auth?.user?.firstName} ${auth?.user?.lastName}`} />
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                    <Form onFinish={onFinish} form={form}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <FloatingLabel
                                    label="First Name"
                                    form={form}
                                    formItemName="first_name"
                                    classLabel="label label-float"
                                >
                                    <Form.Item
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input users first name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </FloatingLabel>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <FloatingLabel
                                    label="Last Name"
                                    form={form}
                                    formItemName="last_name"
                                    classLabel="label label-float"
                                >
                                    <Form.Item
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input users last name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </FloatingLabel>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <FloatingLabel
                                    label="Email"
                                    form={form}
                                    formItemName="email"
                                    classLabel="label label-float"
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input users last name',
                                            },
                                        ]}
                                    >
                                        <Input readOnly />
                                    </Form.Item>
                                </FloatingLabel>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <PhoneNumber
                                    form={form}
                                    formLabelName="Work Phone Number"
                                    required={true}
                                    message="Please input users work phone number"
                                    formItemName="work_phone"
                                />
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <PhoneNumber
                                    form={form}
                                    formLabelName="Personal Phone Number"
                                    required={true}
                                    message="'Please input users personal phone number'"
                                    formItemName="personal_phone"
                                />
                            </Col>

                            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Form.Item>
                                    <SuperiorHomesBlueButton
                                        htmlType="submit"
                                        buttonName="Update User"
                                        loading={isUpdatingUser}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Card>
    );
};

export default UserDetails;
