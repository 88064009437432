import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiEyeLine, RiUser2Fill } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { list_projects, list_prospect_types, list_prospects, list_sources } from '../../../components/apis';
import { notification, Tag } from 'antd';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import { useSelector } from 'react-redux';
import Filter from '../../../components/modals/Filter';
const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone',
    },
    {
        title: 'Source',
        dataIndex: 'source_name',
    },
    {
        title: 'Status',
        dataIndex: 'prospect_type_name',
        // eslint-disable-next-line react/display-name
        render: (value) => {
            return (
                <Tag
                    color={
                        value.toLowerCase() === 'hot'
                            ? 'error'
                            : value.toLowerCase() === 'warm'
                            ? 'warning'
                            : 'processing'
                    }
                >
                    {value}
                </Tag>
            );
        },
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const filterOptions = [
    {
        name: 'Status',
        dataIndex: 'status',
        type: 'autocomplete',
        api: () => list_prospect_types,
        searchResultItem: 'prospect_types',
        valueProp: 'prospect_type_name',
        idProp: 'id',
    },
    {
        name: 'Project',
        dataIndex: 'project',
        type: 'autocomplete',
        api: (value) => list_projects(1, 10, value),
        searchResultItem: 'developments.data',
        valueProp: 'development_name',
        idProp: 'id',
    },
    {
        name: 'Source',
        dataIndex: 'source',
        type: 'autocomplete',
        api: (value) => list_sources(1, 10, value),
        searchResultItem: 'sources.data',
        valueProp: 'source_name',
        idProp: 'id',
    },
    {
        name: 'Contact Date',
        dataIndex: 'contactDate',
        type: 'dateRange',
    },
];

const ListProspects = () => {
    const userId = useSelector<any>((state) => state.auth.user?.id);
    const history = useHistory();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [prospects, setProspects] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({});

    const goToCreateProspect = () => {
        history.push(ROUTES.CREATE_PROSPECT);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
        fetchProspects(1, 10, value);
    };

    const fetchProspects = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_prospects(userId, page, pageSize, search), {
                params: { ...filterData },
            })
            .then((res) => {
                setLoading(false);
                setProspects(res.data.prospects?.data);
                setTotal(res.data.prospects?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.VIEW_PROSPECT.split(':').shift()}${record.id}`);
    };

    useEffect(() => {
        fetchProspects();
    }, []);

    useEffect(() => {
        fetchProspects();
    }, [filterData]);

    return (
        <AuthenticatedLayout title="Prospects">
            <Filter
                visible={showFilters}
                filterOptions={filterOptions}
                onClose={() => setShowFilters(false)}
                onApply={(filterData) => setFilterData(filterData)}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['PROSPECT_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Prospect"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreateProspect}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Prospects (${prospects.length})`}
                action={
                    <TableSearch
                        placeholder="Search prospects"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                        onFilter={() => setShowFilters(true)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchProspects(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchProspects(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={prospects}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => onRowClick(record)}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PROSPECT_LIST'],
})(ListProspects);
