import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Row, Col, Form, Input, DatePicker, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import FloatingLabel from '../../FloatingLabel';
import SelectInteractionType from '../../Selects/InteractionType';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { post_interaction, post_schedule_interaction } from '../../apis';
import SectionDivider from '../../SectionDivider';

interface Props {
    visible: boolean;
    clientId: string;
    onClose: (success: boolean) => void;
}

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().add(1, 'day').startOf('day');
}

function disabledPreviousDate(current) {
    // Can not select days before today and today
    return current && current < moment().add(1, 'day').startOf('day');
}

const NewInteraction = ({ visible, clientId, onClose }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        setLoading(true);
        values['client_id'] = clientId;

        const interactionValues = {
            client_id: clientId,
            interaction_comment: values.interaction_comment,
            interaction_type_id: values.interaction_type_id,
            interaction_date: values.interaction_date,
        };

        authenticatedRequest()
            .post(post_interaction, interactionValues)
            .then(() => {
                setLoading(false);
                form.resetFields();
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });

        if (values.next_interaction_type_id && values.next_interaction_date) {
            const nextInteractionValues = {
                client_id: clientId,
                interaction_type_id: values.next_interaction_type_id,
                interaction_schedule_date: moment(values.next_interaction_date).format('YYYY-MM-DD'),
                interaction_schedule_time: moment(values.next_interaction_date).format('HH:mm'),
            };

            authenticatedRequest()
                .post(post_schedule_interaction, nextInteractionValues)
                .then(() => {
                    setLoading(false);
                    // form.resetFields();
                    // onClose(true);
                })
                .catch((error) => {
                    setLoading(false);
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                    });
                });
        }
    };

    const cancel = () => {
        form.resetFields();
        onClose(false);
    };

    return (
        <Modal
            visible={visible}
            title="New Interaction"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Interaction Type" form={form} formItemName="interaction_type_id">
                            <SelectInteractionType
                                formItemName="interaction_type_id"
                                required={true}
                                message="'Please select an interaction type',"
                            />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Date" form={form} formItemName="interaction_date">
                            <Form.Item
                                name="interaction_date"
                                rules={[{ required: true, type: 'date', message: 'Please select interaction date' }]}
                            >
                                <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} placeholder={''} />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col span={24}>
                        <FloatingLabel label="Comment" form={form} formItemName="interaction_comment">
                            <Form.Item
                                name="interaction_comment"
                                rules={[{ required: true, message: 'Please input interaction' }]}
                            >
                                <Input.TextArea rows={5} />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col span={24}>
                        <SectionDivider title="Schedule Next Interaction" />
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Interaction Type" form={form} formItemName="next_interaction_type_id">
                            <SelectInteractionType
                                formItemName="next_interaction_type_id"
                                required={false}
                                message="'Please select an interaction type',"
                            />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Date" form={form} formItemName="next_interaction_date">
                            <Form.Item
                                name="next_interaction_date"
                                rules={[
                                    { required: false, type: 'date', message: 'Please select next interaction date' },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabledDate={disabledPreviousDate}
                                    placeholder={''}
                                    showTime
                                    showSecond={false}
                                    showNow={false}
                                />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" onClick={() => cancel()} />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton
                                htmlType="submit"
                                buttonName="Save Interaction"
                                loading={loading}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default NewInteraction;
