import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_departments } from '../../apis';

const SelectDepartment = () => {
    const [departments, setDepartments] = useState<any>([]);

    const fetchDepartments = () => {
        authenticatedRequest()
            .get(list_departments(1, 100))
            .then((res) => {
                const departments = res.data.departments?.data.map((department) => {
                    return {
                        value: department.id,
                        label: department.department_name,
                    };
                });
                setDepartments(departments);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    return (
        <Form.Item
            name="department_id"
            rules={[
                {
                    required: true,
                    message: 'Please select users department',
                },
            ]}
        >
            <Select allowClear showSearch optionFilterProp="label" options={departments} />
        </Form.Item>
    );
};

export default SelectDepartment;
