import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Input, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { RiEyeLine, RiEyeCloseLine, RiMailOpenLine, RiLockUnlockLine } from 'react-icons/ri';
import style from './styles';
import logo from '../../assets/images/logo.svg';
import { SuperiorHomesBlueButton, SuperiorHomesLinkButton } from '../../components/Buttons';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import { ROUTES } from '../../Router/urls';
import { unAuthenticatedRequest } from '../../api';
import InMemoryJWTManager from '../../components/InMemoryJWT';
import { useDispatch } from 'react-redux';
import { signActions } from './store/actions';
import { sign_in } from '../../components/apis';
import { formatPermissions } from '../../components/Functions';

const SignInWrapper = styled.div`
    ${style}
`;
const SignIn = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const onFinish = (values) => {
        setLoading(true);
        unAuthenticatedRequest()
            .post(sign_in, values)
            .then((res) => {
                setLoading(false);
                // save the user information to redux
                const data = res.data[0];
                const permissions = formatPermissions(data.permissions);
                const user = {
                    id: data.id,
                    firstName: data.first_name,
                    lastName: data.last_name,
                    email: data.email,
                    personalPhone: data.personal_phone,
                    workPhone: data.work_phone,
                    departmentId: data.department_id,
                    permissions: permissions,
                };
                dispatch(signActions.onUserSignInSuccess(user));
                // add the access token to in memory JWT
                InMemoryJWTManager.setRefreshToken(data.refresh_token);
                InMemoryJWTManager.setToken(data.access_token, parseInt(data.expires_in) * 1000);
                // go to dashboard
                history.push(ROUTES.DASHBOARD);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const goToForgotPassword = () => {
        history.push(ROUTES.FORGOT_PASSWORD);
    };

    return (
        <UnauthenticatedLayout>
            <SignInWrapper>
                <div className="logo-container">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="salutations-container">
                    <p className="salutation">Welcome Back!</p>
                    <p className="description">Please fill in the following information to proceed.</p>
                    <div className="line" />
                </div>
                <Form onFinish={onFinish} form={form} style={{ width: '60%' }} layout="vertical" requiredMark={false}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please input your email',
                                    },
                                ]}
                            >
                                <Input placeholder="Enter Email" prefix={<RiMailOpenLine color="#C4C4C4" />} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Enter Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <SuperiorHomesBlueButton buttonName="Log In" htmlType="submit" block loading={loading} />
                        </Col>

                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <SuperiorHomesLinkButton buttonName="Forgot Password?" onClick={goToForgotPassword} />
                        </Col>
                    </Row>
                </Form>
            </SignInWrapper>
        </UnauthenticatedLayout>
    );
};

export default SignIn;
