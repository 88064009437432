import React, { useEffect, useState } from 'react';
import { Card } from '../../Cards';
import { SuperiorHomesTable } from '../../Table';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { RiEyeLine, RiFileCopy2Line, RiPencilLine } from 'react-icons/ri';
import { SuperiorHomesBlueButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { get_client_payment_plans, send_payment_plan_for_approval } from '../../apis';
import { Divider, notification, Tag, Typography } from 'antd';
import { ROUTES } from '../../../Router/urls';
import { replaceUrlParams } from '../../Functions';

interface Props {
    clientProductId: string;
}

const PaymentPlans = ({ clientProductId }: Props) => {
    const history = useHistory();
    const [paymentPlans, setPaymentPlans] = useState<any>([]);
    const [isAnyPaymentPlanApproved, setIsAnyPaymentPlanApproved] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchClientPaymentPlans = (page = 1, pageSize = 10) => {
        setLoading(true);
        authenticatedRequest()
            .get(get_client_payment_plans(clientProductId, pageSize, page))
            .then((res) => {
                setLoading(false);
                setIsAnyPaymentPlanApproved(res?.data?.is_any_approved);
                setPaymentPlans(res?.data?.payment_plans?.data);
                setTotal(res.data.payment_plans?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const sendForApproval = (id) => {
        authenticatedRequest()
            .get(send_payment_plan_for_approval(id))
            .then((res) => {
                fetchClientPaymentPlans();
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        if (clientProductId) {
            fetchClientPaymentPlans();
        }
    }, [clientProductId]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'payment_plan_name',
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Status',
            dataIndex: 'payment_plan_approval_status_name',
            // eslint-disable-next-line react/display-name
            render: (val) => {
                if (val.toLowerCase() === 'approved') {
                    return <Tag color="success">Approved</Tag>;
                }

                if (val.toLowerCase() === 'awaiting approval') {
                    return <Tag color="warning">Awaiting Approval</Tag>;
                }

                if (val.toLowerCase() === 'pending') {
                    return <Tag color="default">Draft</Tag>;
                }

                return <Tag color="error">Rejected</Tag>;
            },
        },
        {
            title: 'Approved By',
            dataIndex: 'approval_by_name',
        },
        {
            title: 'Created By',
            dataIndex: 'created_by_name',
        },
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                if (record.payment_plan_approval_status_name.toLowerCase() === 'pending') {
                    return (
                        <>
                            {!isAnyPaymentPlanApproved ? (
                                <>
                                    <Typography.Link onClick={() => sendForApproval(record?.id)}>
                                        Send for approval
                                    </Typography.Link>
                                    <Divider type="vertical" />
                                </>
                            ) : null}

                            <RiPencilLine
                                color="#2F80ED"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(
                                        replaceUrlParams(ROUTES.EDIT_PAYMENT_PLAN, [
                                            { param: ':clientProductId', value: clientProductId },
                                            { param: ':paymentPlanId', value: record?.id },
                                        ]),
                                    );
                                }}
                            />

                            <Divider type="vertical" />

                            <RiFileCopy2Line
                                color="#2F80ED"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(
                                        replaceUrlParams(ROUTES.DUPLICATE_PAYMENT_PLAN, [
                                            { param: ':clientProductId', value: clientProductId },
                                            { param: ':paymentPlanId', value: record?.id },
                                        ]),
                                    );
                                }}
                            />
                        </>
                    );
                }
                return (
                    <>
                        <RiEyeLine
                            color="#2F80ED"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                history.push(
                                    replaceUrlParams(ROUTES.VIEW_PAYMENT_PLAN, [{ param: ':id', value: record?.id }]),
                                )
                            }
                        />
                    </>
                );
            },
        },
    ];

    return (
        <Card
            title={`Payment Plans`}
            action={
                !isAnyPaymentPlanApproved ? (
                    <SuperiorHomesBlueButton
                        buttonName="Add Payment Plan"
                        onClick={() =>
                            history.push(
                                replaceUrlParams(ROUTES.CREATE_PAYMENT_PLAN, [
                                    { param: ':clientProductId', value: clientProductId },
                                ]),
                            )
                        }
                    />
                ) : null
            }
        >
            <SuperiorHomesTable
                onPageChange={(page, pageSize) => fetchClientPaymentPlans(page, pageSize)}
                onPageSizeChange={(page, pageSize) => fetchClientPaymentPlans(page, pageSize)}
                columns={columns}
                data={paymentPlans}
                loading={loading}
                withPagination={true}
                total={total}
            />
        </Card>
    );
};

export default PaymentPlans;
