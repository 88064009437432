import React, { useCallback, useState } from 'react';
import { Dropdown, AutoComplete, Tag } from 'antd';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';
import { RiArrowDownSLine } from 'react-icons/ri';
import style from './styles';
import { RootState } from '../../store/reducers/rootReducers';
import { useSelector } from 'react-redux';
import LogoutComponent from './components/logout';
import { authenticatedRequest } from '../../api';
import { search_client_prospects } from '../apis';
import { ROUTES } from '../../Router/urls';
import { replaceUrlParams } from '../Functions';
import { checkRoutePermission } from '../Authorization/checkRoutePermission';

const TopBarWrapper = styled.div`
    ${style}
`;

interface Props {
    title: string;
    titleTwo?: string;
}

const TopBar = ({ title, titleTwo }: Props) => {
    const history = useHistory();
    const auth: any = useSelector((state: RootState) => state.auth);
    const [onSearchFocus, setOnSearchFocus] = useState<boolean>(false);
    const [showUserDropdown, setShowUserDropDown] = useState<boolean>(false);
    const [values, setValues] = useState<any>([]);

    const onSearch = (value) => {
        authenticatedRequest()
            .get(search_client_prospects(value))
            .then((res) => {
                const clients = res?.data?.clients?.data.map((client) => {
                    return {
                        value: client?.name,
                        label: (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>{client.name}</span>
                                {client.is_prospect ? (
                                    <Tag color="#EF7E23">Prospect</Tag>
                                ) : (
                                    <Tag color="#005a87">Client</Tag>
                                )}
                            </div>
                        ),
                        link: client.is_prospect
                            ? replaceUrlParams(ROUTES.VIEW_PROSPECT, [{ param: ':id', value: client.id }])
                            : replaceUrlParams(ROUTES.VIEW_CLIENT, [{ param: ':id', value: client.id }]),
                    };
                });

                setValues(clients);
            })
            .catch((error) => console.log(error));
    };

    const debouncedChangeHandler = useCallback(debounce(onSearch, 600), []);

    return (
        <TopBarWrapper searchFocus={onSearchFocus}>
            <div className="top-bar-container">
                <div className="title-container">
                    <span className="title">{title}</span>
                    {titleTwo ? (
                        <>
                            &nbsp;|&nbsp;<span className="title-two">{titleTwo}</span>
                        </>
                    ) : null}
                </div>
                <div className="action-container">
                    <div className="search-container">
                        <AutoComplete
                            placeholder="Search prospects and clients"
                            className="search-input"
                            onSearch={(value) => debouncedChangeHandler(value)}
                            onSelect={(e, option) => history.push(option.link)}
                            onFocus={() => setOnSearchFocus(true)}
                            options={values}
                        />
                    </div>
                    <div className="user-container">
                        <Dropdown
                            overlay={<LogoutComponent user={auth?.user} showDropDown={showUserDropdown} />}
                            onVisibleChange={(visible) => setShowUserDropDown(visible)}
                            trigger={['click']}
                        >
                            <div className="dropdown">
                                <Avatar
                                    size="30"
                                    name={`${auth?.user?.firstName} ${auth?.user?.lastName}`}
                                    round="15px"
                                    style={{ marginRight: 10 }}
                                />
                                <RiArrowDownSLine size={18} />
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </TopBarWrapper>
    );
};

export default TopBar;
