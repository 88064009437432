import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { RiEyeFill, RiUser2Fill } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification, Tag } from 'antd';
import { generate_statements, list_statements } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { ROUTES } from '../../../Router/urls';
import { replaceUrlParams } from '../../../components/Functions';
import Filter from '../../../components/modals/Filter';
import moment from 'moment';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';

const columns = [
    {
        title: 'Client',
        dataIndex: 'name',
    },
    {
        title: 'Product',
        dataIndex: 'product_name',
    },
    {
        title: 'House Type',
        dataIndex: 'house_type_name',
    },
    {
        title: 'Status',
        dataIndex: 'upcoming_status',
        // eslint-disable-next-line react/display-name
        render: (val, data) => {
            return (
                <Tag
                    color={
                        val.toLowerCase() === 'approved'
                            ? 'success'
                            : val.toLowerCase() === 'rejected'
                            ? 'error'
                            : val.toLowerCase() === 'sent for approval'
                            ? 'processing'
                            : 'warning'
                    }
                >
                    {val.toLowerCase() === 'approved'
                        ? 'Approved'
                        : val.toLowerCase() === 'rejected'
                        ? 'Rejected'
                        : val.toLowerCase() === 'sent for approval'
                        ? 'Sent for approval'
                        : 'Pending'}
                </Tag>
            );
        },
    },
    {
        title: 'Will be sent on',
        dataIndex: 'to_be_sent_on',
        render: (val, data) => {
            if (data.is_approved === 1) {
                return moment(val).format('DD MMM YYYY HH:mm');
            }
            return '-';
        },
    },
    {
        title: 'Relationship Manager',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiEyeFill color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const filterOptions = [
    {
        name: 'Status',
        dataIndex: 'status',
        type: 'single',
        options: [
            {
                label: 'Pending',
                value: 'Pending',
            },
        ],
    },
    {
        name: 'Month',
        dataIndex: 'month',
        type: 'dateRange',
    },
];

const ListStatements = () => {
    const history = useHistory();
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<any>({});
    const [statements, setStatements] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [isGeneratingStatements, setIsGeneratingStatements] = useState<boolean>(false);

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        fetchStatements(1, 10, value);
    };

    const onRowClick = (record) => {
        history.push(
            replaceUrlParams(ROUTES.VIEW_STATEMENTS, [
                { param: ':id', value: record.client_product_id },
                { param: ':upcomingId', value: record.id },
            ]),
        );
    };

    const generateStatements = () => {
        setIsGeneratingStatements(true);
        authenticatedRequest()
            .get(generate_statements)
            .then(() => {
                setIsGeneratingStatements(false);
                fetchStatements();
            })
            .catch((error) => {
                setIsGeneratingStatements(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };
    const fetchStatements = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_statements(page, pageSize, search), {
                params: { ...filterData },
            })
            .then((res) => {
                setLoading(false);
                setStatements(res.data.upcomingPayments?.data);
                setTotal(res.data.upcomingPayments?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchStatements();
    }, []);

    useEffect(() => {
        fetchStatements();
    }, [filterData]);

    return (
        <AuthenticatedLayout title="Statements">
            <Filter
                visible={showFilters}
                filterOptions={filterOptions}
                onClose={() => setShowFilters(false)}
                onApply={(filterData) => setFilterData(filterData)}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['CLIENT_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="Generate Statements"
                        loading={isGeneratingStatements}
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={generateStatements}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Statements (${statements?.length})`}
                action={
                    <TableSearch
                        placeholder="Search statements"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                        onFilter={() => setShowFilters(true)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchStatements(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchStatements(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={statements}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['STATEMENT_LIST'],
})(ListStatements);
