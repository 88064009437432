export const UnAuthenticatedRoutes = ['SIGN_IN', 'FORGOT_PASSWORD', 'ACTIVATE_ACCOUNT', 'RESET_PASSWORD'] as const;

export const AuthenticatedRoutes = [
    'DASHBOARD',
    'CLIENTS',
    'CREATE_CLIENT',
    'VIEW_CLIENT',
    'VIEW_CLIENT_PRODUCT',
    'SOURCES',
    'CREATE_SOURCE',
    'EDIT_SOURCE',
    'DEPARTMENTS',
    'CREATE_DEPARTMENT',
    'EDIT_DEPARTMENT',
    'USERS',
    'CREATE_USER',
    'EDIT_USER',
    'VIEW_USER',
    'PROSPECTS',
    'VIEW_PROSPECT',
    'CREATE_PROSPECT',
    'PROFILE',
    'PROJECTS',
    'CREATE_PROJECT',
    'EDIT_PROJECT',
    'PRODUCTS',
    'CREATE_PRODUCT',
    'EDIT_PRODUCT',
    'HOUSE_TYPES',
    'CREATE_HOUSE_TYPE',
    'EDIT_HOUSE_TYPE',
    'ROLES',
    'CREATE_ROLE',
    'EDIT_ROLE',
    'UNAUTHORIZED',
    'REPORTS',
    'VIEW_REPORT',
    'EMAIL_TEMPLATES',
    'CREATE_EMAIL_TEMPLATE',
    'EDIT_EMAIL_TEMPLATE',
    'RESOURCE_TYPES',
    'CREATE_RESOURCE_TYPE',
    'EDIT_RESOURCE_TYPE',
    'RESOURCES',
    'CREATE_RESOURCE',
    'EDIT_RESOURCE',
    'CRISP_EMAILS',
    'CREATE_CRISP_EMAIL',
    'EDIT_CRISP_EMAIL',
    'PAYMENT_PLANS',
    'VIEW_PAYMENT_PLAN',
    'CREATE_PAYMENT_PLAN',
    'EDIT_PAYMENT_PLAN',
    'DUPLICATE_PAYMENT_PLAN',
    'PAYMENT_TYPES',
    'CREATE_PAYMENT_TYPE',
    'EDIT_PAYMENT_TYPE',
    'CREATE_PAYMENT',
    'PAYMENTS',
    'VIEW_PAYMENT',
    'UPCOMING_PAYMENTS',
    'LIST_LEADS',
    'CREATE_LEADS',
    'LIST_STATEMENTS',
    'VIEW_STATEMENTS',
    'REASSIGN_REQUESTS',
] as const;

export type AuthenticatedRoutesType = typeof AuthenticatedRoutes[number];
export type UnAuthenticatedRoutesType = typeof UnAuthenticatedRoutes[number];

export type RoutesType = UnAuthenticatedRoutesType | AuthenticatedRoutesType;
