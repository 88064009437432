import React from 'react';
import { Modal } from 'antd';
import { RiCheckDoubleLine } from 'react-icons/ri';
import styled from 'styled-components';
import style from './styles';
import SuperiorHomesDivider from '../../Divider';
import { SuperiorHomesBlueButton } from '../../Buttons';
const ConfirmationWrapper = styled.div`
    ${style}
`;

interface Props {
    visible: boolean;
    title: string;
    description: string;
    onClose: () => void;
    onComplete: () => void;
}

const SuccessModal = ({ visible, onClose, title, description, onComplete }: Props) => {
    return (
        <Modal visible={visible} onCancel={onClose} width="30%" footer={null} closeIcon={<div />}>
            <ConfirmationWrapper>
                <div className="icon-container">
                    <RiCheckDoubleLine color="#5CB85C" size="50" />
                </div>
                <div className="content-container">
                    <p className="title">{title}</p>
                    <SuperiorHomesDivider />
                    <p className="description">{description}</p>
                </div>
                <div className="action-container">
                    <SuperiorHomesBlueButton buttonName="Complete" onClick={onComplete} />
                </div>
            </ConfirmationWrapper>
        </Modal>
    );
};

export default SuccessModal;
