import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// const data = [
//     {
//         name: '1 May',
//         pv: 5,
//     },
//     {
//         name: '2 May',
//         pv: 6,
//     },
//     {
//         name: '3 May',
//         pv: 3,
//     },
//     {
//         name: '4 May',
//         pv: 1,
//     },
//     {
//         name: '5 May',
//         pv: 5,
//     },
//     {
//         name: '6 May',
//         pv: 3,
//     },
//     {
//         name: '7 May',
//         pv: 4,
//     },
// ];

interface Props {
    data: Array<{
        date: string;
        value: string;
    }>;
}
const ClientsChart = ({ data }: Props) => {
    return (
        <div style={{ height: '450px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={730} height={250} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="#005A87" stopOpacity={0.6} />
                            <stop offset="90%" stopColor="#005A87" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date" />
                    <YAxis axisLine={false} />
                    <CartesianGrid vertical={false} strokeDasharray="3 3" />
                    <Tooltip />
                    <Area type="monotone" dataKey="value" stroke="#005A87" fillOpacity={2} fill="url(#colorPv)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ClientsChart;
