import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { authenticatedRequest } from '../../../api';
import {
    approve_monthly_statement,
    get_single_statement,
    reject_monthly_statement,
    send_monthly_statement_for_approval,
    send_statement_to_client,
} from '../../../components/apis';
import { notification, Table } from 'antd';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { Card } from '../../../components/Cards';
import moment from 'moment';
import numeral from 'numeral';
import {
    SuperiorHomesBlueButton,
    SuperiorHomesCancelButton,
    SuperiorHomesOrangeButton,
} from '../../../components/Buttons';
import { replaceUrlParams } from '../../../components/Functions';
import { ROUTES } from '../../../Router/urls';
import WarningModal from '../../../components/modals/success/WarningModal';
import Success from '../../../components/modals/success';
import ChangePaymentPlanStatus from '../../../components/modals/ChangePaymentPlanStatus';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import withAuthorization from '../../../components/Authorization/withAuthorization';

const columns = [
    {
        title: 'Unit',
        dataIndex: 'unit',
    },
    {
        title: 'No. of units',
        dataIndex: 'no_of_units',
    },
    {
        title: 'Unit Number',
        dataIndex: 'unit_number',
    },
    {
        title: 'Price',
        dataIndex: 'price',
    },
    {
        title: 'Interest',
        dataIndex: 'interest',
    },
    {
        title: 'Paid',
        dataIndex: 'paid',
    },
    {
        title: 'Next Payment Date',
        dataIndex: 'next_payment_date',
    },
    {
        title: 'Next Payment Amount',
        dataIndex: 'next_payment_amount',
    },
];
const ViewStatement = () => {
    const history = useHistory();
    const { id, upcomingId } = useParams();
    const [sendStatementModal, setSendStatementModal] = useState<boolean>(false);
    const [approveStatementModal, setApproveStatementModal] = useState<boolean>(false);
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false);
    const [rejectSuccessModalVisible, setRejectSuccessModalVisible] = useState<boolean>(false);
    const [approvedModalVisible, setApprovedModalVisible] = useState<boolean>(false);
    const [sendStatementModalToClient, setSendStatementModalToClient] = useState<boolean>(false);
    const [statementSentToClientModalVisible, setStatementSentToClientVisible] = useState<boolean>(false);
    const [isSendingStatementToClient, setIsSendingStatementToClient] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSendingStatement, setIsSendingStatement] = useState<boolean>(false);
    const [payments, setPayments] = useState<any>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [rejecting, setRejecting] = useState<boolean>(false);
    const [clientAndProduct, setClientAndProduct] = useState({
        client: '',
        clientProductId: '',
        product: '',
        title: '',
        canSendStatement: false,
        status: '',
    });

    const addPaymentPlan = (clientId, productId, clientProductId) => {
        history.push(
            replaceUrlParams(ROUTES.VIEW_CLIENT_PRODUCT, [
                { param: ':id', value: clientId },
                { param: ':productId', value: productId },
                { param: ':clientProductId', value: clientProductId },
            ]),
        );
    };

    const goBack = () => {
        history.push(ROUTES.LIST_STATEMENTS);
    };

    const sendStatementToClient = () => {
        setIsSendingStatementToClient(true);
        authenticatedRequest()
            .get(send_statement_to_client(clientAndProduct.clientProductId))
            .then(() => {
                setIsSendingStatementToClient(false);
                setSendStatementModalToClient(false);
                setStatementSentToClientVisible(true);
            })
            .catch((error) => {
                setIsSendingStatementToClient(false);
                setSendStatementModalToClient(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const sendStatementForApproval = () => {
        setIsSendingStatement(false);
        authenticatedRequest()
            .get(send_monthly_statement_for_approval(upcomingId))
            .then(() => {
                setIsSendingStatement(false);
                setSendStatementModal(false);
                setSuccessModalVisible(true);
            })
            .catch((error) => {
                setSendStatementModal(false);
                setIsSendingStatement(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const approveStatement = () => {
        setIsSendingStatement(false);
        authenticatedRequest()
            .get(approve_monthly_statement(upcomingId))
            .then(() => {
                setIsSendingStatement(false);
                setApproveStatementModal(false);
                setApprovedModalVisible(true);
            })
            .catch((error) => {
                setApproveStatementModal(false);
                setIsSendingStatement(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchStatement = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_single_statement(id, upcomingId))
            .then((res) => {
                const newPayment = {
                    unit: res?.data?.payment.unit,
                    no_of_units: 1,
                    unit_number: res?.data?.payment.unit_number,
                    price: numeral(res?.data?.payment.price).format('0,0.00'),
                    interest: numeral(res?.data?.payment.interest).format('0,0.00'),
                    paid: numeral(res?.data?.payment.total_paid).format('0,0.00'),
                    next_payment_date:
                        res?.data?.payment?.next_payment_date != '-' ? (
                            moment(res?.data?.payment?.next_payment_date).format('DD MMM YYYY')
                        ) : (
                            <SuperiorHomesBlueButton
                                buttonName="Add Payment Plan"
                                onClick={() =>
                                    addPaymentPlan(
                                        res?.data?.payment?.client_id,
                                        res?.data?.payment?.product_id,
                                        res?.data?.payment?.client_product_id,
                                    )
                                }
                            />
                        ),
                    next_payment_amount: numeral(res?.data?.payment?.next_payment_amount).format('0,0.00'),
                };

                console.log(res?.data);
                setClientAndProduct({
                    client: res?.data?.payment?.client_name,
                    clientProductId: res?.data?.payment?.client_product_id,
                    product: res?.data?.payment?.product,
                    title: res?.data?.payment?.title,
                    canSendStatement: res?.data?.payment?.next_payment_date != '-',
                    status: res?.data?.payment?.upcoming_status,
                });
                setPayments([newPayment]);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRejectStatement = (values) => {
        setRejecting(true);
        authenticatedRequest()
            .post(reject_monthly_statement(upcomingId), {
                comment: values.comment,
            })
            .then(() => {
                setRejecting(false);
                setVisible(false);
            })
            .catch((error) => {
                setRejecting(false);
                setVisible(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onCloseChangeStatus = (success: boolean) => {
        setVisible(false);
        if (success) {
            setRejectSuccessModalVisible(true);
        }
    };
    useEffect(() => {
        fetchStatement();
    }, []);

    return (
        <AuthenticatedLayout title="Statement">
            <WarningModal
                visible={sendStatementModalToClient}
                title="Send Statement to Client"
                description={`Are you sure you want to send this statement to the client?`}
                onClose={() => setSendStatementModal(false)}
                onComplete={sendStatementToClient}
                onCompleteLoading={isSendingStatementToClient}
            />
            <WarningModal
                visible={sendStatementModal}
                title="Send Statement"
                description={`Are you sure you want to send this statement to for approval?`}
                onClose={() => setSendStatementModal(false)}
                onComplete={sendStatementForApproval}
                onCompleteLoading={isSendingStatement}
            />

            <WarningModal
                visible={approveStatementModal}
                title="Approve Statement"
                description={`Are you sure you want to approve this statement?`}
                onClose={() => setApproveStatementModal(false)}
                onComplete={approveStatement}
                onCompleteLoading={isSendingStatement}
            />

            <Success
                visible={approvedModalVisible}
                title="Statement approved."
                description={`The statement will be sent at the end of the month`}
                onClose={() => {
                    setApprovedModalVisible(false);
                    goBack();
                }}
                onComplete={() => {
                    setApprovedModalVisible(false);
                    goBack();
                }}
            />

            <Success
                visible={statementSentToClientModalVisible}
                title="Statement Sent"
                description={`Statement has been sent to the client`}
                onClose={() => {
                    setStatementSentToClientVisible(false);
                    goBack();
                }}
                onComplete={() => {
                    setStatementSentToClientVisible(false);
                    goBack();
                }}
            />

            <Success
                visible={successModalVisible}
                title="Sent successfully"
                description={`Statement sent for approval`}
                onClose={() => {
                    setSuccessModalVisible(false);
                    goBack();
                }}
                onComplete={() => {
                    setSuccessModalVisible(false);
                    goBack();
                }}
            />

            <Success
                visible={rejectSuccessModalVisible}
                title="Rejected successfully"
                description={`Statement rejected. It will not be sent until comments are addressed`}
                onClose={() => {
                    setRejectSuccessModalVisible(false);
                    goBack();
                }}
                onComplete={() => {
                    setRejectSuccessModalVisible(false);
                    goBack();
                }}
            />

            <ChangePaymentPlanStatus
                type="Statement"
                status={clientAndProduct.status}
                visible={visible}
                handleCancel={(success) => onCloseChangeStatus(success)}
                onComplete={(values) => onRejectStatement(values)}
                onCompleteLoading={rejecting}
            />
            <Card
                title={`Subject: ${clientAndProduct.title}`}
                action={
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        {clientAndProduct?.canSendStatement ? (
                            clientAndProduct?.status?.toLowerCase() === 'pending' ? (
                                <AuthorizationWrapper allowedPermissions={['STATEMENT_SEND_APPROVAL']}>
                                    <SuperiorHomesBlueButton
                                        buttonName="Send For Approval"
                                        onClick={() => setSendStatementModal(true)}
                                    />
                                </AuthorizationWrapper>
                            ) : clientAndProduct?.status?.toLowerCase() === 'approved' ? (
                                <AuthorizationWrapper allowedPermissions={['STATEMENT_APPROVE']}>
                                    <SuperiorHomesOrangeButton
                                        buttonName="Send"
                                        onClick={() => setSendStatementModalToClient(true)}
                                        style={{ marginRight: 10 }}
                                    />
                                </AuthorizationWrapper>
                            ) : (
                                <AuthorizationWrapper allowedPermissions={['STATEMENT_APPROVE']}>
                                    <SuperiorHomesOrangeButton
                                        buttonName="Approve"
                                        onClick={() => setApproveStatementModal(true)}
                                        style={{ marginRight: 10 }}
                                    />

                                    <SuperiorHomesCancelButton buttonName="Reject" onClick={() => setVisible(true)} />
                                </AuthorizationWrapper>
                            )
                        ) : null}
                    </div>
                }
            >
                <div style={{ padding: 20 }}>
                    <p>Dear {clientAndProduct?.client}</p>
                    <p style={{ fontWeight: 'bold', textDecoration: 'underline' }}>RE: STATEMENT OF ACCOUNT</p>
                    <p>
                        Superior Homes Kenya takes this opportunity to thank you for investing in our Project{' '}
                        <strong>{clientAndProduct?.product}</strong>.
                    </p>
                    <p>
                        Kindly see below your investment statement showing the account status as at{' '}
                        <strong>{moment().format('Do MMMM YYYY')}</strong>.
                    </p>

                    <div style={{ marginBottom: 20 }}>
                        <Table columns={columns} dataSource={payments} pagination={false} loading={loading} />
                    </div>
                    <p>Thank you for investing with us.</p>
                    <p>Yours Sincerely,</p>
                    <p>Superior Homes Kenya</p>
                </div>
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['STATEMENT_LIST'],
})(ViewStatement);
