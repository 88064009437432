import { RoutesType } from './routes';

export const ROUTES: { [key in RoutesType]: string } = {
    SIGN_IN: '/sign-in',
    FORGOT_PASSWORD: '/forgot-password',
    ACTIVATE_ACCOUNT: '/activate-account/:id',
    RESET_PASSWORD: '/reset-password/:id',
    CLIENTS: '/clients',
    CREATE_CLIENT: '/client/create',
    VIEW_CLIENT: '/client/view/:id',
    VIEW_CLIENT_PRODUCT: '/client/view/:id/product/:productId/clientProduct/:clientProductId',
    PROSPECTS: '/prospects',
    CREATE_PROSPECT: '/prospect/create',
    VIEW_PROSPECT: '/prospect/view/:id',
    SOURCES: '/sources',
    CREATE_SOURCE: '/source/create',
    EDIT_SOURCE: '/source/edit/:id',
    DEPARTMENTS: '/departments',
    CREATE_DEPARTMENT: '/department/create',
    EDIT_DEPARTMENT: '/department/create/:id',
    DASHBOARD: '/dashboard',
    USERS: '/users',
    CREATE_USER: '/user/create',
    EDIT_USER: '/user/create/:id',
    VIEW_USER: '/user/view/:id',
    PROFILE: '/profile',
    PROJECTS: '/projects',
    CREATE_PROJECT: '/project/create',
    EDIT_PROJECT: '/project/edit/:id',
    PRODUCTS: '/products',
    CREATE_PRODUCT: '/product/create',
    EDIT_PRODUCT: '/product/edit/:id',
    HOUSE_TYPES: '/house-types',
    CREATE_HOUSE_TYPE: '/house-type/create',
    EDIT_HOUSE_TYPE: '/house-type/edit/:id',
    ROLES: '/roles',
    CREATE_ROLE: '/role/create',
    EDIT_ROLE: '/role/edit/:id',
    UNAUTHORIZED: '/unauthorized',
    REPORTS: '/reports',
    VIEW_REPORT: '/view-report/:id',
    EMAIL_TEMPLATES: '/email-templates',
    CREATE_EMAIL_TEMPLATE: '/email/template/create',
    EDIT_EMAIL_TEMPLATE: '/email/template/edit/:id',
    RESOURCE_TYPES: '/resource-types',
    CREATE_RESOURCE_TYPE: '/resource-type/create',
    EDIT_RESOURCE_TYPE: '/resource-type/edit/:id',
    RESOURCES: '/resources',
    CREATE_RESOURCE: '/resource/create',
    EDIT_RESOURCE: '/resource/create/:id',
    CRISP_EMAILS: '/crisp-emails',
    CREATE_CRISP_EMAIL: '/crisp-email/create',
    EDIT_CRISP_EMAIL: '/crisp-email/edit/:id',
    PAYMENT_PLANS: '/payment-plans',
    VIEW_PAYMENT_PLAN: '/payment-plan/:id',
    CREATE_PAYMENT_PLAN: '/payment-plan/create/:clientProductId',
    EDIT_PAYMENT_PLAN: '/payment-plan/edit/:clientProductId/paymentPlan/:paymentPlanId',
    DUPLICATE_PAYMENT_PLAN: '/payment-plan/duplicate/:clientProductId/paymentPlan/:paymentPlanId',
    PAYMENT_TYPES: '/payment-types',
    CREATE_PAYMENT_TYPE: '/payment-types/create',
    EDIT_PAYMENT_TYPE: '/payment-type/edit/:id',
    CREATE_PAYMENT: '/payment/create/:clientId/clientProduct/product/:productId/clientProductId/:clientProductId',
    PAYMENTS: '/payments',
    VIEW_PAYMENT: '/payment/:id',
    UPCOMING_PAYMENTS: '/upcoming-payments',
    LIST_LEADS: '/leads',
    CREATE_LEADS: '/create/leads',
    LIST_STATEMENTS: '/statements',
    VIEW_STATEMENTS: '/statement/:id/upcoming/:upcomingId',
    REASSIGN_REQUESTS: '/reassign-requests',
};
