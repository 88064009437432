import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Form, Input, notification } from 'antd';
import { RiCloseLine } from 'react-icons/ri';
import SectionDivider from '../../SectionDivider';
import FloatingLabel from '../../FloatingLabel';
import PhoneNumber from '../../PhoneNumber';
import SelectSource from '../../Selects/Source';
import SelectHasSiteVisit from '../../Selects/SiteVisit';
import { authenticatedRequest } from '../../../api';
import { update_client } from '../../apis';
import { mapErrorToFields } from '../../Functions';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
interface Props {
    visible: boolean;
    client: any;
    onClose: (success: boolean) => void;
}

const EditClient = ({ visible, onClose, client }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values) => {
        setLoading(true);
        if (!values?.alternate_phone) delete values.alternate_phone;
        if (!values?.alternate_email) delete values.alternate_email;
        values['relationship_manager_id'] = client.relationship_manager_id;
        authenticatedRequest()
            .post(update_client(client.id), values)
            .then(() => {
                setLoading(false);
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: 'Sorry! Your form contains errors. Fix them and re-submit.',
                });
            });
    };

    useEffect(() => {
        if (client && Object.keys(client).length) {
            form.setFieldsValue(client);
        }
    }, [client]);

    return (
        <Modal
            visible={visible}
            title="Update Client"
            onCancel={() => onClose(false)}
            width="40%"
            footer={null}
            closeIcon={<RiCloseLine color="#D95350" />}
        >
            <Form onFinish={onFinish} form={form} layout="horizontal" style={{ width: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <SectionDivider title="Client Details:" />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Client Name"
                            form={form}
                            formItemName="name"
                            classLabel="label label-float"
                        >
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input clients name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel label="Email" form={form} formItemName="email" classLabel="label label-float">
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please input clients email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <PhoneNumber
                            form={form}
                            required={true}
                            message="Please input clients phone number"
                            formItemName="phone"
                        />
                    </Col>

                    <Col span={24}>
                        <SectionDivider title="Alternate Contact Details:" />
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Name"
                            form={form}
                            formItemName="alternate_name"
                            classLabel="label label-float"
                        >
                            <Form.Item
                                name="alternate_name"
                                dependencies={['alternate_email']}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input alternate name',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                value &&
                                                !getFieldValue('alternate_email') &&
                                                !getFieldValue('alternate_phone')
                                            ) {
                                                return Promise.reject(
                                                    new Error('Please input alternate email or alternate phone'),
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Email"
                            form={form}
                            formItemName="alternate_email"
                            classLabel="label label-float"
                        >
                            <Form.Item
                                name="alternate_email"
                                dependencies={['alternate_name']}
                                rules={[
                                    {
                                        required: false,
                                        type: 'email',
                                        message: 'Input should be an email',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && !getFieldValue('alternate_name')) {
                                                return Promise.reject(new Error('Please input alternate name'));
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </FloatingLabel>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <PhoneNumber
                            form={form}
                            required={false}
                            message="Please input next of alternate contact's phone number"
                            formItemName="alternate_phone"
                        />
                    </Col>

                    <Col span={24}>
                        <SectionDivider title="Source & Product Details:" />
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Source"
                            form={form}
                            formItemName="source_id"
                            classLabel="label label-float"
                        >
                            <SelectSource />
                        </FloatingLabel>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FloatingLabel
                            label="Was a site visit"
                            form={form}
                            formItemName="was_site_visit"
                            classLabel="label label-float"
                        >
                            <SelectHasSiteVisit formItemName="was_site_visit" />
                        </FloatingLabel>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Update Client" loading={loading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default EditClient;
