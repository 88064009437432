import React, { useState } from 'react';
import { Col, DatePicker, Form, Input, notification, Row } from 'antd';
import { Card } from '../../../components/Cards';
import { useParams, useHistory } from 'react-router-dom';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import SectionDivider from '../../../components/SectionDivider';
import { useForm } from 'antd/es/form/Form';
import FloatingLabel from '../../../components/FloatingLabel';
import FormUploads from '../../../components/FormUploads';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../../components/Buttons';
import { authenticatedRequest } from '../../../api';
import { mapErrorToFields, replaceUrlParams } from '../../../components/Functions';
import { post_payment } from '../../../components/apis';
import SuccessModal from '../../../components/modals/success';
import { ROUTES } from '../../../Router/urls';
import SelectPaymentType from '../../../components/Selects/PaymentType';
import SelectResourceType from '../../../components/Selects/ResourceType';

const CreatePayment = () => {
    const id = null;
    const history = useHistory();
    const { clientId, productId, clientProductId } = useParams();
    const [form] = useForm();
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<any>({});

    const onFinish = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('client_id', clientId);
        formData.append('client_product_id', clientProductId);
        formData.append('payment_amount', values.payment_amount);
        formData.append('payment_date', values.payment_date);
        formData.append('resource_title', values.resource_title);
        formData.append('payment_type_id', values.payment_type_id);
        formData.append('resource_type_id', values.resource_type_id);
        formData.append('resource_file', file);
        authenticatedRequest()
            .post(post_payment, formData)
            .then((res) => {
                setLoading(false);
                setVisible(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                const formErrors = mapErrorToFields(resp?.data?.error);
                form.setFields(formErrors);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onComplete = () => {
        setVisible(false);
        history.push(
            replaceUrlParams(ROUTES.VIEW_CLIENT_PRODUCT, [
                { param: ':id', value: clientId },
                { param: ':productId', value: productId },
                { param: ':clientProductId', value: clientProductId },
            ]),
        );
    };

    return (
        <AuthenticatedLayout title="Payment" titleTwo="New Payment">
            <Col xs={24} sm={24} md={14} lg={24} xl={14}>
                <Card>
                    <div style={{ padding: 20 }}>
                        <SuccessModal
                            visible={visible}
                            onClose={onComplete}
                            onComplete={onComplete}
                            title={id ? 'Payment updated' : 'New Payment Added'}
                            description={`Payment was ${id ? 'updated' : 'added'} successfully`}
                        />
                        <Form onFinish={onFinish} form={form} layout="vertical" style={{ width: '100%' }}>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <SectionDivider title="Payment Details:" />
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Amount Paid"
                                        form={form}
                                        formItemName="payment_amount"
                                        classLabel={'label'}
                                    >
                                        <Form.Item
                                            name="payment_amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input payment amount paid',
                                                },
                                            ]}
                                        >
                                            <Input type="number" />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Payment Date"
                                        form={form}
                                        formItemName="payment_date"
                                        isFormList={false}
                                        classLabel={'label'}
                                    >
                                        <Form.Item
                                            name={'payment_date'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select payment date',
                                                },
                                            ]}
                                        >
                                            <DatePicker style={{ width: '100%' }} placeholder="" />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel label="Payment Type" form={form} formItemName="payment_type_id">
                                        <SelectPaymentType />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Resource Title"
                                        form={form}
                                        formItemName="resource_title"
                                        classLabel={'label'}
                                    >
                                        <Form.Item
                                            name="resource_title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input resource title',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <FloatingLabel
                                        label="Resource Type"
                                        form={form}
                                        formItemName="resource_type_id"
                                        classLabel="label"
                                    >
                                        <SelectResourceType formItemName={'resource_type_id'} />
                                    </FloatingLabel>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <FormUploads
                                        uploadedFile={uploadedFile}
                                        formItemName="resource_file"
                                        label="File"
                                        manualUpload={true}
                                        setFile={setFile}
                                        isFormEnabled
                                    />
                                </Col>

                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Form.Item style={{ marginRight: 20 }}>
                                        <SuperiorHomesCancelButton buttonName="Cancel" />
                                    </Form.Item>
                                    <Form.Item>
                                        <SuperiorHomesBlueButton
                                            htmlType="submit"
                                            buttonName={`Save Payment`}
                                            loading={loading}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </AuthenticatedLayout>
    );
};

export default CreatePayment;
