import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_sources } from '../../apis';

interface Props {
    onChange?: (source) => void;
}

const SelectSource = ({ onChange }: Props) => {
    const [sources, setSources] = useState<any>([]);

    const onSourceChange = (sourceId) => {
        const selectedSourceIndex = sources.findIndex((source) => source.value === sourceId);
        if (selectedSourceIndex !== -1 && onChange) {
            onChange(sources[selectedSourceIndex]);
        }
    };

    const fetchSources = () => {
        authenticatedRequest()
            .get(list_sources(1, 100))
            .then((res) => {
                const sources = res.data.sources?.data.map((source) => {
                    return {
                        value: source.id,
                        label: source.source_name,
                        hasSiteVisit: source.has_site_visits,
                    };
                });
                setSources(sources);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchSources();
    }, []);

    return (
        <Form.Item
            name="source_id"
            rules={[
                {
                    required: true,
                    message: 'Please select a source',
                },
            ]}
        >
            <Select
                allowClear
                showSearch={true}
                options={sources}
                onChange={(source) => onSourceChange(source)}
                optionFilterProp="label"
            />
        </Form.Item>
    );
};

export default SelectSource;
