import { css } from 'styled-components';

const style = () => css`
    .line {
        width: 49px;
        height: 2px;
        top: 340px;
        background: #005a87;
        border-radius: 30px;
    }
`;

export default style;
