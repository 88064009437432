import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_projects } from '../../apis';

const SelectProject = () => {
    const [projects, setProjects] = useState<any>([]);

    const fetchProjects = () => {
        authenticatedRequest()
            .get(list_projects(1))
            .then((res) => {
                const projects = res.data.developments?.data.map((project) => {
                    return {
                        value: project.id.toString(),
                        label: project.development_name,
                    };
                });
                setProjects(projects);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <Form.Item
            name="development_id"
            rules={[
                {
                    required: true,
                    message: 'Please select a project',
                },
            ]}
        >
            <Select allowClear options={projects} />
        </Form.Item>
    );
};

export default SelectProject;
