import React, { useState } from 'react';
import download from '../../assets/images/cloud-download.svg';
import styled from 'styled-components';
import fileDownload from 'js-file-download';
import style from './styles';
import { authenticatedRequest } from '../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { download_resource } from '../apis';

const DownloadFileWrapper = styled.div`
    ${style}
`;

interface Props {
    url: string;
    fileName: string;
    width?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    err?: string;
    height?: any;
    onClick?: () => void;
    readOnly?: boolean;
}

const antIcon = <LoadingOutlined style={{ color: '#7C60FF' }} spin />;

const DownloadFile = ({ width, validateStatus, err, onClick, readOnly, fileName, url }: Props) => {
    const [downloading, setDownloading] = useState(false);

    const downloadFile = () => {
        setDownloading(true);
        return authenticatedRequest()
            .get(url, {
                responseType: 'blob',
            })
            .then((res) => {
                setDownloading(false);
                fileDownload(res.data, fileName);
            })
            .catch((error) => {
                setDownloading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <DownloadFileWrapper width={width || '365px'} style={{ maxWidth: '100%' }} validateStatus={validateStatus}>
            <>
                <div className="download-documents" onClick={downloadFile}>
                    <span>{fileName}</span>
                    {downloading ? antIcon : <img src={download} className="cloud" alt={'download'} />}
                </div>
                {err && <div className="err-messsage">{err}</div>}
                {!readOnly && (
                    <Button type="link" style={{ paddingLeft: 0 }} onClick={onClick}>
                        Change
                    </Button>
                )}
            </>
        </DownloadFileWrapper>
    );
};

export default DownloadFile;
