import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Form, Input, notification } from 'antd';
import { RiEyeCloseLine, RiEyeLine, RiLockUnlockLine } from 'react-icons/ri';
import style from './styles';
import logo from '../../assets/images/logo.svg';
import { SuperiorHomesBlueButton } from '../../components/Buttons';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import { unAuthenticatedRequest } from '../../api';
import { post_reset_password } from '../../components/apis';
import { ROUTES } from '../../Router/urls';

const ResetPasswordWrapper = styled.div`
    ${style}
`;

const ResetPassword = () => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const { id } = useParams();

    const onFinish = (values) => {
        setLoading(true);
        values['forgot_password_token'] = id;
        unAuthenticatedRequest()
            .post(post_reset_password, values)
            .then(() => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'Password reset successfully. Redirecting you to sign in',
                });

                history.push(ROUTES.SIGN_IN);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
        console.log('values', values);
    };

    return (
        <UnauthenticatedLayout>
            <ResetPasswordWrapper>
                <div className="logo-container">
                    <img src={logo} alt="Logo" />
                </div>
                <div className="salutations-container">
                    <p className="salutation">Reset Password!</p>
                    <p className="description">Please fill in your password and confirm it to reset your password</p>
                    <div className="line" />
                </div>
                <Form onFinish={onFinish} form={form} style={{ width: '60%' }} layout="vertical" requiredMark={false}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password',
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Enter Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error('The two passwords that you entered do not match!'),
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    prefix={<RiLockUnlockLine color="#C4C4C4" />}
                                    placeholder="Enter Password"
                                    iconRender={(passwordVisible) =>
                                        passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <SuperiorHomesBlueButton buttonName="Submit" htmlType="submit" block loading={loading} />
                        </Col>
                    </Row>
                </Form>
            </ResetPasswordWrapper>
        </UnauthenticatedLayout>
    );
};

export default ResetPassword;
