import React, { useEffect, useState } from 'react';
import { notification, Select, Form } from 'antd';
import { authenticatedRequest } from '../../../api';
import { list_products } from '../../apis';
import style from './styles';
import styled from 'styled-components';
const SelectWrapper = styled.div`
    ${style}
`;

interface Props {
    isFormList: boolean;
    mode?: 'multiple' | 'tags';
    field?: any;
    name: any;
    fieldKey?: any;
    rules: any;
}

const SelectProduct = ({ isFormList, mode, field, fieldKey, name, rules }: Props) => {
    const [products, setProducts] = useState<any>([]);
    const [value, setValues] = React.useState<any>([]);

    const fetchProducts = () => {
        authenticatedRequest()
            .get(list_products(1, 100))
            .then((res) => {
                const products = [] as any;
                res.data.products?.data.forEach((product) => {
                    const developmentIndex = products.findIndex(
                        (development) => development.label === product.development_name,
                    );
                    if (developmentIndex !== -1) {
                        products[developmentIndex].options.push({
                            value: product.id.toString(),
                            label: product.product_name,
                        });
                    } else {
                        products.push({
                            label: product.development_name,
                            options: [
                                {
                                    value: product.id.toString(),
                                    label: product.product_name,
                                },
                            ],
                        });
                    }
                });
                setProducts(products);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    if (isFormList) {
        return (
            <Form.Item {...field} fieldKey={fieldKey} name={name} rules={rules}>
                <Select allowClear options={products} showSearch optionFilterProp="label" />
            </Form.Item>
        );
    }

    if (!isFormList && (mode == 'multiple' || mode === 'tags')) {
        const selectProps = {
            allowClear: true,
            showSearch: true,
            optionFilterProp: 'label',
            mode: 'multiple' as const,
            style: { width: '100%' },
            value,
            options: products,
            onChange: (newValue: string[]) => {
                setValues(newValue);
            },
            maxTagCount: 'responsive' as const,
        };
        return (
            <SelectWrapper>
                <Form.Item name={name} rules={rules}>
                    <Select {...selectProps} />
                </Form.Item>
            </SelectWrapper>
        );
    }

    return (
        <Form.Item name={name} rules={rules}>
            <Select allowClear options={products} showSearch optionFilterProp="label" />
        </Form.Item>
    );
};

export default SelectProduct;
