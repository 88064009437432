import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { reports } from './data';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { RiDownloadCloud2Line } from 'react-icons/ri';
import { SuperiorHomesBlueButton } from '../../../components/Buttons';
import { titleCase } from '../../../components/TitleCase';
import moment from 'moment';
import { DatePicker, notification, Select } from 'antd';
import { authenticatedRequest } from '../../../api';
import { replaceUrlParams } from '../../../components/Functions';
import { list_sources } from '../../../components/apis';

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
}

const ViewReport = () => {
    const { id } = useParams();
    const [columns, setColumns] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [sources, setSources] = useState<any>([]);
    const [generatingReport, setGeneratingReport] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [selectedSourceId, setSelectedSourceId] = useState(null);
    const [startDate, setStartDate] = useState<string>(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));
    const [reportName, setReportName] = useState<string>('');
    const [report, setReport] = useState<any>({});

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
    };

    const onRowClick = (record) => {
        console.log('record', record);
    };

    const onDateChange = (value) => {
        setStartDate(value[0].format('YYYY-MM-DD'));
        setEndDate(value[1].format('YYYY-MM-DD'));
        fetchData(value[0].format('YYYY-MM-DD'), value[1].format('YYYY-MM-DD'), 1, 10, selectedSourceId);
    };

    const fetchData = (
        startDate = moment().startOf('month').format('YYYY-MM-DD'),
        endDate = moment().format('YYYY-MM-DD'),
        page = 1,
        perPage = 10,
        sourceId = null,
    ) => {
        const reportData = getReportData(startDate, endDate, perPage, page, sourceId);
        setLoading(true);
        authenticatedRequest()
            .get(reportData.api)
            .then((res) => {
                setLoading(false);
                if (res?.data?.[reportData.responseObjectName] !== undefined) {
                    const data = res?.data?.[reportData.responseObjectName];
                    setData(data?.data);
                    setTotal(data?.total);
                }
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchSources = () => {
        authenticatedRequest()
            .get(list_sources(1, 100))
            .then((res) => {
                const sources = [
                    {
                        value: 'all',
                        label: 'All',
                    },
                ];
                res.data.sources?.data.forEach((source) => {
                    sources.push({
                        value: source.id,
                        label: source.source_name,
                    });
                });
                setSources(sources);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const getReportData = (
        startDate,
        endDate,
        perPage: number | null = null,
        page: number | null = null,
        sourceId: string | null,
    ) => {
        const foundReportIndex = reports.findIndex((report) => report.name === id);
        const items = [
            { param: ':startDate', value: startDate },
            { param: ':endDate', value: endDate },
        ];

        if (page) {
            items.push({ param: ':page', value: page });
        }

        if (perPage) {
            items.push({ param: ':perPage', value: perPage });
        }

        let api = replaceUrlParams(reports[foundReportIndex].api, items);

        if (sourceId) {
            api = `${api}&source_id=${sourceId}`;
        }

        return {
            api,
            responseObjectName: reports[foundReportIndex].objectName,
        };
    };

    const onSourceChange = (sourceId) => {
        if (sourceId.toLowerCase() === 'all') {
            sourceId = null;
        }
        setSelectedSourceId(sourceId);
        fetchData(startDate, endDate, 1, 10, sourceId);
    };

    const generateReport = () => {
        setGeneratingReport(true);
        const foundReportIndex = reports.findIndex((report) => report.name === id);
        const fileName = `${reports[foundReportIndex].name}-${startDate}-${endDate}.csv`;
        let api = replaceUrlParams(reports[foundReportIndex].exportApi, [
            { param: ':startDate', value: startDate },
            { param: ':endDate', value: endDate },
        ]);

        if (selectedSourceId) {
            api = `${api}?source_id=${selectedSourceId}`;
        }

        authenticatedRequest()
            .get(api, {
                responseType: 'blob',
            })
            .then((res) => {
                setGeneratingReport(false);
                fileDownload(res.data, fileName);
            })
            .catch((error) => {
                setGeneratingReport(false);
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        if (id) {
            const foundReportIndex = reports.findIndex((report) => report.name === id);
            if (foundReportIndex !== -1) {
                setReport(reports[foundReportIndex]);
                setColumns(reports[foundReportIndex].columns);
                setReportName(titleCase(reports[foundReportIndex].name.split('-').join(' ')));
                if (reports[foundReportIndex].filterSources) {
                    fetchSources();
                }
                fetchData();
            }
        }
    }, []);

    return (
        <AuthenticatedLayout title={reportName}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <SuperiorHomesBlueButton
                    buttonName="Download"
                    icon={<RiDownloadCloud2Line color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                    onClick={generateReport}
                    loading={generatingReport}
                />
            </div>
            <Card
                title={`${reportName} (${total})`}
                action={
                    <>
                        {report?.filterSources ? (
                            <Select
                                showSearch={true}
                                defaultValue="all"
                                options={sources}
                                onChange={(source) => onSourceChange(source)}
                                optionFilterProp="label"
                                style={{ marginRight: 10, width: 200 }}
                            />
                        ) : null}
                        <RangePicker
                            style={{ marginRight: 10 }}
                            disabledDate={disabledDate}
                            defaultValue={[moment().startOf('month'), moment()]}
                            onChange={(value) => onDateChange(value)}
                        />

                        <TableSearch
                            placeholder={`Search ${reportName}`}
                            onSearch={(value) => onSearch(value)}
                            onChange={(value) => onSearchChange(value)}
                        />
                    </>
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchData(startDate, endDate, page, pageSize, selectedSourceId)}
                    onPageSizeChange={(page, pageSize) =>
                        fetchData(startDate, endDate, page, pageSize, selectedSourceId)
                    }
                    columns={columns}
                    data={data}
                    total={total}
                    withPagination={true}
                    loading={loading}
                    onRowClick={(record) => onRowClick(record)}
                    scrollable={true}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default ViewReport;
