import React, { useState } from 'react';
import { Col, Form, Input, Modal, notification, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { SuperiorHomesBlueButton, SuperiorHomesCancelButton } from '../../Buttons';
import { authenticatedRequest } from '../../../api';
import { approve_or_reject_payment_plan } from '../../apis';

interface Props {
    onCompleteLoading: boolean;
    type: 'Payment Plan' | 'Payment' | 'Statement';
    status: 'Approved' | 'Rejected' | undefined | string;
    visible: boolean;
    handleCancel: (success: boolean) => void;
    onComplete: (values: any) => void;
}
const ChangePaymentPlanStatus = ({ visible, status, type, onCompleteLoading, handleCancel, onComplete }: Props) => {
    const [form] = useForm();

    const onFinish = (values) => {
        onComplete(values);
        form.resetFields();
    };
    return (
        <Modal
            title={`${status === 'Approved' ? 'Approve' : 'Reject'} ${type}`}
            onCancel={() => handleCancel(false)}
            closable={true}
            footer={null}
            visible={visible}
            width="40%"
        >
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Comment"
                            name="comment"
                            rules={[{ required: true, message: 'Please input a comment' }]}
                        >
                            <Input.TextArea rows={5} />
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item style={{ marginRight: 20 }}>
                            <SuperiorHomesCancelButton buttonName="Cancel" />
                        </Form.Item>
                        <Form.Item>
                            <SuperiorHomesBlueButton htmlType="submit" buttonName="Send" loading={onCompleteLoading} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ChangePaymentPlanStatus;
