import React from 'react';
import styled from 'styled-components';
import style from './styles';
const SectionDividerWrapper = styled.div`
    ${style}
`;

interface Props {
    title: string;
}
const SectionDivider = ({ title }: Props) => {
    return (
        <SectionDividerWrapper>
            <div className="divider-container">
                <span className="title">{title}</span>
            </div>
        </SectionDividerWrapper>
    );
};

export default SectionDivider;
