import { css } from 'styled-components';

const style = () => css`
    .ant-input-group-addon:first-child {
        border-top-left-radius: 5px;
        border-right: 0;
        border-bottom-left-radius: 5px;
        background: transparent;
    }
`;

export default style;
