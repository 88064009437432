import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { SuperiorHomesOrangeButton } from '../../../components/Buttons';
import { ROUTES } from '../../../Router/urls';
import { RiUser2Fill, RiPencilLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification } from 'antd';
import { list_payment_types } from '../../../components/apis';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import AuthorizationWrapper from '../../../components/Authorization/AuthorizationWrapper';
import moment from 'moment';
import { replaceUrlParams } from '../../../components/Functions';

const columns = [
    {
        title: 'Name',
        dataIndex: 'payment_type_name',
    },
    {
        title: 'Transaction Type',
        dataIndex: 'transaction_type_name',
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY HH:mm'),
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Actions',
        // eslint-disable-next-line react/display-name
        render: () => {
            return <RiPencilLine color="#2F80ED" style={{ cursor: 'pointer' }} />;
        },
    },
];

const ListPaymentTypes = () => {
    const history = useHistory();
    const [sources, setSources] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const goToCreatePaymentType = () => {
        history.push(ROUTES.CREATE_PAYMENT_TYPE);
    };

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
        fetchPaymentTypes(1, 10, value);
    };

    const fetchPaymentTypes = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_payment_types(page, pageSize, search))
            .then((res) => {
                setLoading(false);
                setSources(res?.data?.payment_types?.data);
                setTotal(res?.data?.payment_types?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(replaceUrlParams(ROUTES.EDIT_PAYMENT_TYPE, [{ param: ':id', value: record?.id }]));
    };

    useEffect(() => {
        fetchPaymentTypes();
    }, []);

    return (
        <AuthenticatedLayout title="Payment Types">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <AuthorizationWrapper allowedPermissions={['PAYMENT_TYPE_CREATE']} disabled={true}>
                    <SuperiorHomesOrangeButton
                        buttonName="New Payment Type"
                        icon={<RiUser2Fill color="#ffffff" size="18" style={{ marginRight: 10 }} />}
                        onClick={goToCreatePaymentType}
                    />
                </AuthorizationWrapper>
            </div>
            <Card
                title={`Payment Types (${total})`}
                action={
                    <TableSearch
                        placeholder="Search sources"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchPaymentTypes(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchPaymentTypes(page, pageSize)}
                    total={total}
                    columns={columns}
                    data={sources}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_TYPE_LIST'],
})(ListPaymentTypes);
