import { css } from 'styled-components';

const style = ({ showDropDown }) => css`
    .logout-container {
        .drop-arrow-up {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #ffffff;
            position: absolute;
            top: -8px;
            right: 10px;
        }
        flex: 1;
        flex-direction: column;
        display: ${showDropDown ? 'flex' : 'none'};
        border-radius: 5px;
        position: absolute;
        background-color: #ffffff;
        right: -11px;
        min-width: 200px;
        padding: 10px 0;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
        z-index: 10;

        .role {
            padding: 0px 20px;
            font-weight: normal;
            font-size: 11px;
            line-height: 17px;
            color: #404040;
        }

        .user {
            padding: 0px 20px;
            font-weight: 500;
            font-size: 15px;
            margin: 0;
            color: #404040;
        }

        .profile {
            padding: 0px 20px;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #404040;
            align-items: flex-start;
            flex: 1;
            display: flex;
            margin-bottom: 5px;
        }

        .sign-out {
            padding: 0px 20px;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #404040;
            align-items: flex-start;
            flex: 1;
            display: flex;
        }
    }
`;

export { style };
