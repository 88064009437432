import React, { useEffect, useState } from 'react';
import InMemoryJWTManager from '../components/InMemoryJWT';
import Loader from '../components/Loader';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthenticatedRoutes, UnAuthenticatedRoutes } from './routes';
import { ROUTES } from './urls';
import { COMPONENTS } from './components';

const RequireAuth = ({ children }: any) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const x = InMemoryJWTManager.checkForLogout();
        if (x) {
            setAuthenticated(false);
            setLoading(false);
        } else {
            const checkAuth = async () => {
                setLoading(true);
                if (!InMemoryJWTManager.getToken()) {
                    await InMemoryJWTManager.getRefreshedToken()
                        .then((res) => {
                            setAuthenticated(res);
                            setLoading(false);
                        })
                        .catch(() => {
                            setAuthenticated(false);
                            setLoading(false);
                        });
                } else {
                    setAuthenticated(true);
                    setLoading(false);
                }
            };

            checkAuth();
        }
    }, []);

    if (!loading && authenticated) {
        return children;
    }

    if (!loading && !authenticated) {
        return <Redirect to="/sign-in" />;
    }

    return <Loader logo={true} />;
};

const AppRouter = () => (
    <Switch>
        <Route exact path="/" component={COMPONENTS.SIGN_IN} />

        {UnAuthenticatedRoutes.map((route) => (
            <Route exact path={ROUTES[route]} component={COMPONENTS[route]} key={route} />
        ))}

        <RequireAuth>
            {AuthenticatedRoutes.map((route) => (
                <Route exact path={ROUTES[route]} component={COMPONENTS[route]} key={route} />
            ))}
        </RequireAuth>
    </Switch>
);

export default AppRouter;
