import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../../components/Cards';
import { SuperiorHomesTable, TableSearch } from '../../../components/Table';
import AuthenticatedLayout from '../../../layouts/AuthenticatedLayout';
import { RiEyeLine } from 'react-icons/ri';
import { authenticatedRequest } from '../../../api';
import { notification, Tag } from 'antd';
import { list_payment_plans } from '../../../components/apis';
import moment from 'moment';
import withAuthorization from '../../../components/Authorization/withAuthorization';
import { replaceUrlParams } from '../../../components/Functions';
import { ROUTES } from '../../../Router/urls';

const columns = [
    {
        title: 'Name',
        dataIndex: 'payment_plan_name',
    },
    {
        title: 'Client',
        dataIndex: 'name',
    },
    {
        title: 'Created By',
        dataIndex: 'created_by_name',
    },
    {
        title: 'Approved By',
        dataIndex: 'approval_by_name',
    },
    {
        title: 'Status',
        dataIndex: 'payment_plan_approval_status_name',
        // eslint-disable-next-line react/display-name
        render: (val) => {
            if (val.toLowerCase() === 'approved') {
                return <Tag color="success">Approved</Tag>;
            }

            if (val.toLowerCase() === 'awaiting approval') {
                return <Tag color="warning">Awaiting Approval</Tag>;
            }

            if (val.toLowerCase() === 'pending') {
                return <Tag color="default">Draft</Tag>;
            }

            return <Tag color="error">Rejected</Tag>;
        },
    },
    {
        title: 'Date Created',
        dataIndex: 'created_at',
        render: (val) => moment(val).format('DD MMM YYYY'),
    },
];

const ListPaymentPlans = () => {
    const history = useHistory();
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const newColumns = [
        ...columns,
        {
            title: 'Actions',
            // eslint-disable-next-line react/display-name
            render: () => {
                return (
                    <>
                        <RiEyeLine color="#2F80ED" style={{ cursor: 'pointer' }} />
                    </>
                );
            },
        },
    ];

    const onSearch = (value) => {
        console.log('onSearch', value);
    };

    const onSearchChange = (value) => {
        console.log('onSearchChange', value);
        // fetchPaymentPlans(1, 10, value);
    };

    const fetchPaymentPlans = (page = 1, pageSize = 10, search = '') => {
        setLoading(true);
        authenticatedRequest()
            .get(list_payment_plans(page, pageSize))
            .then((res) => {
                setLoading(false);
                setPaymentPlans(res?.data?.payment_plans?.data);
                setTotal(res?.data?.payment_plans?.total);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.error || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onRowClick = (record) => {
        history.push(replaceUrlParams(ROUTES.VIEW_PAYMENT_PLAN, [{ param: ':id', value: record?.id }]));
    };
    useEffect(() => {
        fetchPaymentPlans();
    }, []);

    return (
        <AuthenticatedLayout title="Payment Plans">
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}></div>
            <Card
                title={`Payment Plans (${total})`}
                action={
                    <TableSearch
                        placeholder="Search payment plans"
                        onSearch={(value) => onSearch(value)}
                        onChange={(value) => onSearchChange(value)}
                    />
                }
            >
                <SuperiorHomesTable
                    onPageChange={(page, pageSize) => fetchPaymentPlans(page, pageSize)}
                    onPageSizeChange={(page, pageSize) => fetchPaymentPlans(page, pageSize)}
                    columns={newColumns}
                    data={paymentPlans}
                    total={total}
                    withPagination={true}
                    loading={loading}
                    onRowClick={onRowClick}
                />
            </Card>
        </AuthenticatedLayout>
    );
};

export default withAuthorization({
    allowedPermissions: ['PAYMENT_PLAN_LIST'],
})(ListPaymentPlans);
